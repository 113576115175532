import React from "react";
import ReactDOM from "react-dom"; // Import ReactDOM from react-dom

import Toast from "./toast";

const toastTypes = {
  SUCCESS: "success",
  ERROR: "error",
};

const showToast = (message, type, duration) => {
  const toast = document.createElement("div");
  document.body.appendChild(toast);

  return new Promise((resolve) => {
    const handleRemove = () => {
      ReactDOM.unmountComponentAtNode(toast);
      document.body.removeChild(toast);
      resolve();
    };

    ReactDOM.render(<Toast message={message} type={type} duration={duration} />, toast);

    setTimeout(handleRemove, duration);
  });
};

export const successNotify = (message, duration = 3000) => {
  return showToast(message, toastTypes.SUCCESS, duration);
};

export const errorNotify = (message, duration = 3000) => {
  return showToast(message, toastTypes.ERROR, duration);
};
