import React, { useMemo, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
import BarChart from "../../../../components/ui/charts/BarChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import Datatable from "../../../../components/ui/tables/Datatable";

import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import { API_URL, COKE_COMPANY_ID } from "../../../../constants/constants";
import { useLocation } from "react-router-dom";

import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import Modal from "../../../../components/ui/Modal";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";


export default function ThumbnailYoutubeVideoOrganic({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  let location = useLocation();

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Youtube Thumbnail"
  let pagePrimaryTab = "Organic"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData?.company?.id ?? '';

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // DEVICE OS TRAFFIC
  const [deviceTraffic, setDeviceTraffic] = useState([]);
  const [deviceTrafficLabel, setDeviceTrafficLabel] = useState([]);

  const [isDeviceTrafficLoading, setDeviceTrafficIsLoading] = useState(false);
  const [isDeviceTrafficLoaded, setDeviceTrafficIsLoaded] = useState(false);
  const [deviceTrafficerror, setDeviceTrafficError] = useState(null);

  // SUBSCRIBER GAINED / LOST ANALYSIS
  const [subscriberGainedLostAnalysisData, setSubscriberGainedLostAnalysisData] = useState([]);
  const [subscriberGainedLostAnalysisDataLabel, setSubscriberGainedLostAnalysisDataLabel] = useState([]);
  const [isSubscriberGainedLostAnalysisLoading, setSubscriberGainedLostAnalysisIsLoading] = useState(false);
  const [isSubscriberGainedLostAnalysisLoaded, setSubscriberGainedLostAnalysisIsLoaded] = useState(false);
  const [subscriberGainedLostAnalysisError, setSubscriberGainedLostAnalysisError] = useState(null);

  // VIDEO ENGAGEMENT TREND
  const [videoEngagementTrendData, setVideoEngagementTrendData] = useState([]);
  const [videoEngagementTrendDataLabel, setVideoEngagementTrendDataLabel] = useState([]);
  const [isVideoEngagementTrendLoading, setVideoEngagementTrendIsLoading] = useState(false);
  const [isVideoEngagementTrendLoaded, setVideoEngagementTrendIsLoaded] = useState(false);
  const [videoEngagementTrendError, setVideoEngagementTrendError] = useState(null);

  // VIRAL GRADE TREND ANALYSIS
  const [viralGradeTrendAnalysisData, setViralGradeTrendAnalysisData] = useState([]);
  const [viralGradeTrendAnalysisLabel, setViralGradeTrendAnalysisDataLabel] = useState([]);
  const [isViralGradeTrendAnalysisLoading, setViralGradeTrendAnalysisIsLoading] = useState(false);
  const [isViralGradeTrendAnalysisLoaded, setViralGradeTrendAnalysisIsLoaded] = useState(false);
  const [viralGradeTrendAnalysisError, setViralGradeTrendAnalysisError] = useState(null);

  // AUDIENCE ACTIVITY TREND
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] = useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] = useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] = useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] = useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] = useState(null);

  // TRAFFIC ANALYSIS
  const [trafficAnalysis, setTrafficAnalysis] = useState([]);
  // const [trafficAnalysisLabels, setTrafficAnalysisLabels] = useState([]);
  const [isTrafficAnalysisLoading, setTrafficAnalysisIsLoading] = useState(false);
  const [isTrafficAnalysisLoaded, setTrafficAnalysisIsLoaded] = useState(false);
  const [trafficAnalysiserror, setTrafficAnalysisError] = useState(null);

  // VIDEO RETENTION Analysis
  const [videoRetentionAnalysis, setVideoRetentionAnalysis] = useState([]);
  const [videoRetentionAnalysisLabel, setVideoRetentionAnalysisLabel] = useState([]);
  const [isVideoRetentionAnalysisLoading, setVideoRetentionAnalysisIsLoading] = useState(false);
  const [isVideoRetentionAnalysisLoaded, setVideoRetentionAnalysisIsLoaded] = useState(false);
  const [videoRetentionAnalysiserror, setVideoRetentionAnalysisError] = useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] = useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] = useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "No of Views", field: "no_of_views" },
    { name: "Change", field: "percentage_growth" },
  ];

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // VIEW TREND ANALYSIS
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] = useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] = useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // VIEW TREND ANALYSIS - FIRST DROPDOWN
  const viewTrendFirstOptions = [
    {
      label: "Overall Views",
      value: "overall_views",
    },
    {
      label: "Red Views",
      value: "red_views",
    },
  ];

  const [viewTrendFirstDropdownValue, setViewTrendFirstDropdownValue] = useState(viewTrendFirstOptions[0]);
  const [viewTrendFirstAllData, setViewTrendFirstAllData] = useState([]);

  const viewTrendFirstDropdownHandler = (e) => {
    if (e === null) {
      setViewTrendFirstDropdownValue("");
    } else {
      setViewTrendFirstDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Video", "Youtube Thumbail", "Organic", null, "View Trend Analysis", e.label)
  };

  const fetchViewTrendFirstAllDropdownHandler = async () => {
    if (viewTrendFirstDropdownValue.value === "overall_views") {

      setViewTrendAnalysis(viewTrendFirstAllData?.data?.overall_views ?? []);
      setViewTrendAnalysisLabel(viewTrendFirstAllData?.label?.overall_views ?? []);

    } else if (viewTrendFirstDropdownValue.value === "red_views") {

      setViewTrendAnalysis(viewTrendFirstAllData?.data?.red_views ?? []);
      setViewTrendAnalysisLabel(viewTrendFirstAllData?.label?.red_views ?? []);

    } else {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchViewTrendFirstAllDropdownHandler();
  }, [viewTrendFirstDropdownValue]);

  // VIDEO RETENTION ANALYSIS
  const [videoRetentionDropdownAllData, setVideoRetentionDropdownAllData] = useState([]);
  const [videoRetentionDropdownAllLabel, setVideoRetentionDropdownAllLabel] = useState([]);


  let cokeVideoRetentionDropdownData = [
    {
      label: "Overall Retention",
      value: "overall_retention",
    }
  ]

  let defaultVideoRetentionDropdownData = [
    {
      label: "Overall Retention",
      value: "overall_retention",
    },
    {
      label: "Relative Retention",
      value: "relative_retenation",
    },
  ];

  const videoRetentionOptions = companyID === COKE_COMPANY_ID ? cokeVideoRetentionDropdownData : defaultVideoRetentionDropdownData;

  const [videoRetentionDropdownValue, setVideoRetentionDropdownValue] = useState(videoRetentionOptions[0]);

  const videoRetentionDropdownHandler = (e) => {
    if (e === null) {
      setVideoRetentionDropdownValue("");
    } else {
      setVideoRetentionDropdownValue(e);
    }
  };

  const fetchVideoRetentionDropdownHandler = async () => {
    if (videoRetentionDropdownValue.value === "overall_retention") {

      setVideoRetentionAnalysis(videoRetentionDropdownAllData?.overall_retention ?? []);
      setVideoRetentionAnalysisLabel(videoRetentionDropdownAllLabel?.overall_retention ?? []);

    } else if (videoRetentionDropdownValue.value === "relative_retenation") {

      setVideoRetentionAnalysis(videoRetentionDropdownAllData?.relative_retenation ?? []);
      setVideoRetentionAnalysisLabel(videoRetentionDropdownAllLabel?.relative_retenation ?? []);

    } else {
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchVideoRetentionDropdownHandler();
  }, [videoRetentionDropdownValue]);

  // TRAFFIC ANALYSIS : FIRST DROPDOWN
  const trafficAnalysisFirstOptions = [
    {
      label: "Source Type",
      value: "source_types",
    },
    {
      label: "PlayBack Location",
      value: "playback_location",
    },
  ];

  const [trafficAnalysisFirstDropdownValue, setTrafficAnalysisFirstAllDropdownValue] = useState(trafficAnalysisFirstOptions[0]);

  const trafficAnalysisFirstDropdownHandler = (e) => {
    if (e === null) {
      setTrafficAnalysisFirstAllDropdownValue("");
    } else {
      setTrafficAnalysisFirstAllDropdownValue(e);
    }
  };

  const trafficAnalysisSecondOptions = [
    {
      label: "No of Views",
      value: "no_of_views",
    },
    {
      label: "Average View Duration",
      value: "average_view_duration",
    },
  ];

  const [trafficAnalysisSecondDropdownValue, setTrafficAnalysisSecondAllDropdownValue] = useState(trafficAnalysisSecondOptions[0]);

  const trafficAnalysisSecondDropdownHandler = (e) => {
    if (e === null) {
      setTrafficAnalysisSecondAllDropdownValue("");
    } else {
      setTrafficAnalysisSecondAllDropdownValue(e);
    }
  };

  const [trafficAnalysisAllData, settrafficAnalysisAllData] = useState([]);
  const [trafficAnalysisAllDataLabels, settrafficAnalysisAllDataLabels] = useState([]);

  const fetchTrafficAnalysisFirstAllDropdownHandler = () => {
    if (trafficAnalysisFirstDropdownValue.value === "source_types" && trafficAnalysisSecondDropdownValue.value === "no_of_views") {

      setTrafficAnalysis(trafficAnalysisAllData?.source_types?.data?.no_of_views ?? []);

    } else if (trafficAnalysisFirstDropdownValue.value === "source_types" && trafficAnalysisSecondDropdownValue.value === "average_view_duration") {

      setTrafficAnalysis(trafficAnalysisAllData?.source_types?.data?.average_view_duration ?? []);

    } else if (trafficAnalysisFirstDropdownValue.value === "source_types" && trafficAnalysisSecondDropdownValue.value === "viewer_percentage") {

      setTrafficAnalysis(trafficAnalysisAllData?.source_types?.data?.viewer_percentage ?? []);

    } else if (trafficAnalysisFirstDropdownValue.value === "playback_location" && trafficAnalysisSecondDropdownValue.value === "no_of_views") {

      setTrafficAnalysis(trafficAnalysisAllData?.playback_location?.data?.no_of_views ?? []);

    } else if (
      trafficAnalysisFirstDropdownValue.value === "playback_location" &&
      trafficAnalysisSecondDropdownValue.value === "average_view_duration"
    ) {

      setTrafficAnalysis(trafficAnalysisAllData?.playback_location?.data?.average_view_duration ?? []);

    } else if (
      trafficAnalysisFirstDropdownValue.value === "playback_location" &&
      trafficAnalysisSecondDropdownValue.value === "viewer_percentage"
    ) {
      setTrafficAnalysis(trafficAnalysisAllData?.playback_location?.data?.viewer_percentage ?? []);

    } else {
      setTrafficAnalysis([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchTrafficAnalysisFirstAllDropdownHandler();
  }, [trafficAnalysisFirstDropdownValue, trafficAnalysisSecondDropdownValue]);

  // DEVICE / OS DROPDOWN
  const [deviceDropdownAllData, setDeviceDropdownAllData] = useState([]);

  const deviceOptions = [
    {
      label: "No. of Views",
      value: "no_of_views",
    },
    {
      label: "Average Duration",
      value: "average_view_duration",
    },
  ];

  const [deviceDropdownValue, setDeviceDropdownValue] = useState(deviceOptions[0]);

  const deviceDropdownHandler = (e) => {
    if (e === null) {
      setDeviceDropdownValue("");
    } else {
      setDeviceDropdownValue(e);
    }
  };

  const fetchDeviceDropdownHandler = async () => {
    if (deviceDropdownValue.value === "no_of_views") {

      setDeviceTraffic(deviceDropdownAllData?.number_of_views?.data ?? []);
      setDeviceTrafficLabel(deviceDropdownAllData?.number_of_views?.label ?? []);

    } else if (deviceDropdownValue.value === "average_view_duration") {

      setDeviceTraffic(deviceDropdownAllData?.average_view_duration?.data ?? []);
      setDeviceTrafficLabel(deviceDropdownAllData?.average_view_duration?.label ?? []);

    } else {
      setDeviceTraffic([]);
      setDeviceTrafficLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchDeviceDropdownHandler();
  }, [deviceDropdownValue]);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", null, "Organic", badge.hashtag)

  };

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceActivityTrendHandler();
    fetchTrafficAnalysisHandler();
    companyID !== COKE_COMPANY_ID && fetchCommentSentimentHandler();
    companyID === COKE_COMPANY_ID && fetchSubscriberGainedLostAnalysisHandler();
    companyID === COKE_COMPANY_ID && fetchVideoEngagementTrendHandler()
    companyID === COKE_COMPANY_ID && fetchViralGradeTrendAnalysisHandler()
    fetchDevicetrafficHandler();
    fetchAudienceDistributionhHandler();
    fetchCountryHandler();
    fetchViewTrendAnalysisHandler();
    fetchVideoRetentionAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/hashtags/", config);

      setHashtagList(res?.data?.data ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (!isHashtagListLoading && isHashtagListLoaded) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper><NoDataAvailableLoader chartType="hashChartType" /></Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="hashChartType"
          error={hashtagListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader = ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARD
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/metric-card/", config);

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="youtube"
                    section="overall single"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/activity-trend/",
        config
      );

      setAudienceActivityTrend(res?.data?.data ?? []);
      setAudienceActivityTrendXaxisLabel(res?.data?.xaxis_labels ?? []);
      setAudienceActivityTrendYaxisLabel(res?.data?.yaxis_labels ?? []);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityTrendError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = (
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRAFFIC ANALYSIS
  const fetchTrafficAnalysisHandler = async () => {
    setTrafficAnalysisIsLoading(true);
    setTrafficAnalysisIsLoaded(false);
    setTrafficAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/traffic-chart/",
        config
      );

      settrafficAnalysisAllData(res?.data ?? []);
      settrafficAnalysisAllDataLabels(res?.data ?? []);
      setTrafficAnalysis(res?.data?.source_types?.data?.no_of_views ?? []);

      setTrafficAnalysisIsLoading(false);
      setTrafficAnalysisIsLoaded(true);
      setTrafficAnalysisError(null);
    } catch (error) {
      setTrafficAnalysis([]);
      settrafficAnalysisAllData([]);
      setTrafficAnalysisIsLoading(false);
      setTrafficAnalysisIsLoaded(false);
      setTrafficAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let trafficAnalysisSection = (
    <Wrapper>
      <Loader loaderType="treeChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTrafficAnalysisLoaded && !isTrafficAnalysisLoading) {
    if (NoDataAvailableChecker(trafficAnalysis)) {
      trafficAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="treeChartType" />
        </Wrapper>
      );
    } else {
      trafficAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="treeChartType">
            <SimpleTreeMap
              chartData={trafficAnalysis}
              chartId="treemapchart"
              chartClass="section_card_chart"
              chartLabels={[
                {
                  name: "name",
                  value: "value",
                  toolTipvalue1: "value",
                  toolTipvalue1Label:
                    trafficAnalysisFirstDropdownValue.label +
                    " & " +
                    trafficAnalysisSecondDropdownValue.label,
                },
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trafficAnalysiserror) {
    trafficAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="treeChartType"
          error={trafficAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTrafficAnalysisLoading) {
    trafficAnalysisSection = (
      <Wrapper>
        <Loader loaderType="treeChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT
  const fetchCommentSentimentHandler = async () => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/comment-sentiment/",
        config
      );

      setCommentSentiment(res?.data?.data ?? []);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let commentSentimentSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  let commentSentimentTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
      commentSentimentTable = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        <Wrapper>
          <ErrorBoundary>
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {commentSentiment && commentSentiment.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      )

      // commentSentiment.map((item, i) => {
      //   return (
      //     <Wrapper key={i}>
      //       <ErrorBoundary chartType="tableChartType">
      //         <tr>
      //           <td>{item.label}</td>
      //           <td className="bold">
      //             <NumberFormatter number={item.value} />
      //           </td>
      //         </tr>
      //       </ErrorBoundary>
      //     </Wrapper>
      //   );
      // });
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
    commentSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );

    commentSentimentTable = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRAFFIC OS HANDLER
  const fetchDevicetrafficHandler = async () => {
    setDeviceTrafficIsLoading(true);
    setDeviceTrafficIsLoaded(false);
    setDeviceTrafficError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/traffic-os/", config);
      setDeviceDropdownAllData(res?.data ?? {});
      setDeviceTraffic(res?.data?.number_of_views?.data ?? []);
      setDeviceTrafficLabel(res?.data?.number_of_views?.label ?? []);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(true);
      setDeviceTrafficError(null);
    } catch (error) {
      setDeviceDropdownAllData([]);
      setDeviceTraffic([]);
      setDeviceTrafficLabel([]);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(false);
      setDeviceTrafficError(error.code);
    }
  };

  let deviceTrafficSection = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceTrafficLoaded && !isDeviceTrafficLoading) {
    if (NoDataAvailableChecker(deviceTraffic) || NoDataAvailableChecker(deviceTrafficLabel)) {
      deviceTrafficSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      deviceTrafficSection = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={deviceTraffic}
              chartId="deviceTrafficSection"
              chartLabels={deviceTrafficLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceTrafficerror) {
    deviceTrafficSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={deviceTrafficerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceTrafficLoading) {
    deviceTrafficSection = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // SUBSCRIBER GAINED / LOST ANALYSIS
  const fetchSubscriberGainedLostAnalysisHandler = async () => {
    setSubscriberGainedLostAnalysisIsLoading(true);
    setSubscriberGainedLostAnalysisIsLoaded(false);
    setSubscriberGainedLostAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/subscriber-gain-lost/", config);

      setSubscriberGainedLostAnalysisData(res?.data?.data ?? []);
      setSubscriberGainedLostAnalysisDataLabel(res?.data?.dimension ?? []);
      setSubscriberGainedLostAnalysisIsLoading(false);
      setSubscriberGainedLostAnalysisIsLoaded(true);
      setSubscriberGainedLostAnalysisError(null);
    } catch (error) {
      setSubscriberGainedLostAnalysisData([]);
      setSubscriberGainedLostAnalysisDataLabel([]);
      setSubscriberGainedLostAnalysisIsLoading(false);
      setSubscriberGainedLostAnalysisIsLoaded(false);
      setSubscriberGainedLostAnalysisError(error.code);
    }
  };

  let SubscriberGainedLostAnalysisSection =
    <Wrapper>
      <Loader loaderType="multiLineChartLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isSubscriberGainedLostAnalysisLoaded && !isSubscriberGainedLostAnalysisLoading) {
    if (NoDataAvailableChecker(subscriberGainedLostAnalysisData) || NoDataAvailableChecker(subscriberGainedLostAnalysisDataLabel)) {
      SubscriberGainedLostAnalysisSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
    } else {
      SubscriberGainedLostAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <LineChart
              chartId="subscriber_gained_lost_analysis_chart"
              chartClass="section_card_chart"
              chartData={subscriberGainedLostAnalysisData}
              chartLabels={subscriberGainedLostAnalysisDataLabel}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (subscriberGainedLostAnalysisError) {
    SubscriberGainedLostAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={subscriberGainedLostAnalysisError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSubscriberGainedLostAnalysisLoading) {
    SubscriberGainedLostAnalysisSection =
      <Wrapper>
        <Loader loaderType="multiLineChartLoader" />
      </Wrapper>
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Engagement Trend

  let defaultVideoEngagementDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoEngagementDisabledvalues = []
  } else {
    defaultVideoEngagementDisabledvalues = []
  }

  const [isVideoEngagementOpen, setIsVideoEngagementOpen] = useState(false)
  const [videoEngagementDisabledvalues, setVideoEngagementDisabledvalues] = useState(defaultVideoEngagementDisabledvalues)

  const fetchVideoEngagementTrendHandler = async () => {
    setVideoEngagementTrendIsLoading(true);
    setVideoEngagementTrendIsLoaded(false);
    setVideoEngagementTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/engagement-trend-line-chart/", config);

      setVideoEngagementTrendData(res?.data?.data ?? []);
      setVideoEngagementTrendDataLabel(res?.data?.dimension ?? []);
      setVideoEngagementTrendIsLoading(false);
      setVideoEngagementTrendIsLoaded(true);
      setVideoEngagementTrendError(null);
    } catch (error) {
      setVideoEngagementTrendData([]);
      setVideoEngagementTrendDataLabel([]);
      setVideoEngagementTrendIsLoading(false);
      setVideoEngagementTrendIsLoaded(false);
      setVideoEngagementTrendError(error.code);
    }
  };

  let VideoEngagementTrendSection = <Wrapper><Loader loaderType="multiLineChartLoader" /></Wrapper>
  let VideoEngagementTrendSection2 = <Wrapper><Loader loaderType="multiLineChartLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (isVideoEngagementTrendLoaded && !isVideoEngagementTrendLoading) {
    if (NoDataAvailableChecker(videoEngagementTrendData) || NoDataAvailableChecker(videoEngagementTrendDataLabel)) {
      VideoEngagementTrendSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      VideoEngagementTrendSection2 = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      if (isMultiLineCluster) {
        VideoEngagementTrendSection = (
          <Wrapper>
            <ErrorBoundary chartType="pieChartType">
              <LineChart
                chartId="VideoEngagementTrendSection"
                chartClass="section_card_chart"
                chartData={videoEngagementTrendData}
                chartLabels={videoEngagementTrendDataLabel}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={videoEngagementDisabledvalues}
                setDisabledValues={setVideoEngagementDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        VideoEngagementTrendSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="pieChartType">
              <PartitionedLineChart
                chartId="VideoEngagementTrendSection2"
                chartClass="section_card_chart"
                chartData={videoEngagementTrendData}
                chartLabels={videoEngagementTrendDataLabel}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={videoEngagementDisabledvalues}
                setDisabledValues={setVideoEngagementDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        VideoEngagementTrendSection = (
          <Wrapper>
            <ErrorBoundary chartType="pieChartType">
              <LineChart
                chartId="VideoEngagementTrendSection"
                chartClass="section_card_chart"
                chartData={videoEngagementTrendData}
                chartLabels={videoEngagementTrendDataLabel}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoEngagementTrendError) {
    VideoEngagementTrendSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={videoEngagementTrendError} />
      </Wrapper>
    );
    VideoEngagementTrendSection2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={videoEngagementTrendError} />
      </Wrapper>
    );

  }

  // IF DATA LOADING
  if (isVideoEngagementTrendLoading) {
    VideoEngagementTrendSection = <Wrapper><Loader loaderType="multiLineChartLoader" /></Wrapper>
    VideoEngagementTrendSection2 = <Wrapper><Loader loaderType="multiLineChartLoader" /></Wrapper>

  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIRAL GRADE TREND ANALYSIS
  const fetchViralGradeTrendAnalysisHandler = async () => {
    setViralGradeTrendAnalysisIsLoading(true);
    setViralGradeTrendAnalysisIsLoaded(false);
    setViralGradeTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/trend-line-score/", config);

      setViralGradeTrendAnalysisData(res?.data?.data ?? []);
      setViralGradeTrendAnalysisDataLabel(res?.data?.dimension ?? []);
      setViralGradeTrendAnalysisIsLoading(false);
      setViralGradeTrendAnalysisIsLoaded(true);
      setViralGradeTrendAnalysisError(null);
    } catch (error) {
      setViralGradeTrendAnalysisData([]);
      setViralGradeTrendAnalysisDataLabel([]);
      setViralGradeTrendAnalysisIsLoading(false);
      setViralGradeTrendAnalysisIsLoaded(false);
      setViralGradeTrendAnalysisError(error.code);
    }
  };

  let ViralGradeTrendAnalysisSection =
    <Wrapper>
      <Loader loaderType="multiLineChartLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isViralGradeTrendAnalysisLoaded && !isViralGradeTrendAnalysisLoading) {
    if (NoDataAvailableChecker(viralGradeTrendAnalysisData) || NoDataAvailableChecker(viralGradeTrendAnalysisLabel)) {
      ViralGradeTrendAnalysisSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
    } else {
      ViralGradeTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <LineChart
              chartId="viral_grade_trend_analysis_chart"
              chartClass="section_card_chart"
              chartData={viralGradeTrendAnalysisData}
              chartLabels={viralGradeTrendAnalysisLabel}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viralGradeTrendAnalysisError) {
    ViralGradeTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={viralGradeTrendAnalysisError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViralGradeTrendAnalysisLoading) {
    ViralGradeTrendAnalysisSection =
      <Wrapper>
        <Loader loaderType="multiLineChartLoader" />
      </Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/country-chart/",
        config
      );

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution By Country
              <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="video"
                platform="youtube"
                section="overall single"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Audience Distribution By Country
                  <SectionDefinationsBadge
                    title={"Audience Distribution By Country"}
                    module="video"
                    platform="youtube"
                    section="overall single"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"Audience Distribution By Country"}
              tablePagePlatform={"youtube"}
              tablePageSubModule={"video"}
              tablePagePrimaryTab={"overall single"}
              tableID={"aud_distri"}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_dist"}
                  sectionName={"VideoYTCountry_Audience Distribution - Country"}
                  sectionData={
                    countryData === undefined || null ? [] : countryData
                  }
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Audience Distribution By Country
                <SectionDefinationsBadge
                  title={"Audience Distribution By Country"}
                  module="video"
                  platform="youtube"
                  section="overall single"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Audience Distribution By Country
                <SectionDefinationsBadge
                  title={"Audience Distribution By Country"}
                  module="video"
                  platform="youtube"
                  section="overall single"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionhHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/audience-chart/",
        config
      );

      setMaleCount(res?.data?.male_list?.data?.no_of_views ?? []);
      setMaleCountLabels(res?.data?.dimension?.no_of_views ?? []);
      setTotalMaleCount(res?.data?.male_count?.no_of_views ?? []);
      setFemaleCount(res?.data?.female_list?.data?.no_of_views ?? []);
      setFemaleCountLabels(res?.data?.dimension?.no_of_views ?? []);
      setTotalFemaleCount(res?.data?.female_count?.no_of_views ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper>
      <Loader loaderType="genderLoader" />
    </Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
      maleAudienceDistributionIcon = <Wrapper><NoDataAvailableLoader chartType="genderChartType" /> </Wrapper>
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <ErrorBoundary>
            <div className="section_icon flex column">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </div>
            <div className="p_20">
              <h3>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
      </Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper>
      <NoDataAvailableLoader chartType="barChartType" />
    </Wrapper>
  );
  let femaleAudienceDistributionIcon = (
    <Wrapper>
      <NoDataAvailableLoader chartType="genderChartType" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </div>
            <div className="p_20">
              <h3>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );

    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );

    femaleAudienceDistributionIcon = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW TREND ANALYSIS

  let defaultViewTrendAnalysisDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultViewTrendAnalysisDisabledvalues = []
  } else {
    defaultViewTrendAnalysisDisabledvalues = []
  }

  const [isViewTrendAnalysisOpen, setIsViewTrendAnalysisOpen] = useState(false)
  const [viewTrendAnalysisDisabledvalues, setViewTrendAnalysisDisabledvalues] = useState(defaultViewTrendAnalysisDisabledvalues)

  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/rate-analysis/",
        config
      );

      setViewTrendFirstAllData(res?.data ?? []);
      setViewTrendAnalysis(res?.data?.data?.overall_views ?? []);
      setViewTrendAnalysisLabel(res?.data?.label?.overall_views ?? []);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendFirstAllData([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  let viewTrendAnalysisSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
      viewTrendAnalysisSection2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        viewTrendAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={viewTrendAnalysis}
                chartId="viewTrendAnalysis"
                chartLabels={viewTrendAnalysisLabel}
                onBulletsClick={() => { }}
                showLabels
                showLegend
                disabledLegendArray={viewTrendAnalysisDisabledvalues}
                setDisabledValues={setViewTrendAnalysisDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        viewTrendAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={viewTrendAnalysis}
                chartId="viewTrendAnalysis_2"
                chartLabels={viewTrendAnalysisLabel}
                showLabels
                showLegend
                disabledLegendArray={viewTrendAnalysisDisabledvalues}
                setDisabledValues={setViewTrendAnalysisDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        viewTrendAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={viewTrendAnalysis}
                chartId="viewTrendAnalysis"
                chartLabels={viewTrendAnalysisLabel}
                onBulletsClick={() => { }}
                showLabels
                showLegend
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );

    viewTrendAnalysisSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    viewTrendAnalysisSection2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO RETENTION ANALYSIS
  const fetchVideoRetentionAnalysisHandler = async () => {
    setVideoRetentionAnalysisIsLoading(true);
    setVideoRetentionAnalysisIsLoaded(false);
    setVideoRetentionAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/retention-analysis/", config);

      setVideoRetentionAnalysis(res?.data?.data?.overall_retention ?? []);
      setVideoRetentionAnalysisLabel(res?.data?.label?.overall_retention ?? []);
      setVideoRetentionDropdownAllData(res?.data?.data ?? []);
      setVideoRetentionDropdownAllLabel(res?.data?.label ?? []);

      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(true);
      setVideoRetentionAnalysisError(null);
    } catch (error) {
      setVideoRetentionDropdownAllData([]);
      setVideoRetentionDropdownAllLabel([]);
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(false);
      setVideoRetentionAnalysisError(error.code);
    }
  };

  let videoRetentionAnalysisSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  // IF DATA IS LOADED
  if (isVideoRetentionAnalysisLoaded && !isVideoRetentionAnalysisLoading) {
    if (NoDataAvailableChecker(videoRetentionAnalysis) || NoDataAvailableChecker(videoRetentionAnalysisLabel)) {
      videoRetentionAnalysisSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
    } else {
      videoRetentionAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={videoRetentionAnalysis}
              chartId="videoRetentionAnalysis"
              chartLabels={videoRetentionAnalysisLabel}
              toolTipTitle="Video Duration Percentage"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRetentionAnalysiserror) {
    videoRetentionAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={videoRetentionAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoRetentionAnalysisLoading) {
    videoRetentionAnalysisSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  var maleFemaleDataCSV = {
    "Audience Total Count": NoDataAvailableChecker(femaleCount) ? [] : [{ "Male Viewers": totalMaleCount, "Female Viewers": totalFemaleCount }],
    "AgeBarChart_Audience Distribution - Male": maleCount,
    "AgeBarChart_Audience Distribution - Female": femaleCount,
  };

  let companyWiseExportData = {};

  if (companyID === COKE_COMPANY_ID) {
    if (API_URL === "https://dev.icogz.com/api/") {
      companyWiseExportData = {
        ...topCardData,
        "VideoSummaryCard_Summary Cards": metricCards,
        "View Trend Analysis": viewTrendAnalysis,
        "Heatmap_Audience Activity Trend": audienceActivityTrend,
        [`VideoRetentionYoutube_Video Retention Analysis_${videoRetentionDropdownValue.value.replace("_", "-")}`]: videoRetentionAnalysis,
        "Viral Grade Trend Analysis": viralGradeTrendAnalysisData,
        [`VideoTraffic_Traffic Analysis_${trafficAnalysisFirstDropdownValue.label + "&" + trafficAnalysisSecondDropdownValue.label}`]: trafficAnalysis,
        [`DevicePieofPieActiveDropdown_Device - OS_${deviceDropdownValue.value.replaceAll("_", "-")}`]: deviceTraffic,
        "Subscriber Gained - Lost": subscriberGainedLostAnalysisData,
        "VideoCountry_Audience Distribution - Country": NoDataAvailableChecker(countryData) ? [] : countryData,
        "Video Engagement Trend": videoEngagementTrendData,
        ...maleFemaleDataCSV,
        "VideoYTCountry_Audience Distribution - Country": NoDataAvailableChecker(countryData) ? [] : countryData,
      }
    } else {
      companyWiseExportData = {
        ...topCardData,
        "VideoSummaryCard_Summary Cards": metricCards,
        "View Trend Analysis": viewTrendAnalysis,
        "Heatmap_Audience Activity Trend": audienceActivityTrend,
        [`VideoRetentionYoutube_Video Retention Analysis_${videoRetentionDropdownValue.value.replace("_", "-")}`]: videoRetentionAnalysis,
        [`VideoTraffic_Traffic Analysis_${trafficAnalysisFirstDropdownValue.label + "&" + trafficAnalysisSecondDropdownValue.label}`]: trafficAnalysis,
        "CommentSentiment_Comment Sentiment": commentSentiment,
        "VideoCountry_Audience Distribution - Country": NoDataAvailableChecker(countryData) ? [] : countryData,
        [`DevicePieofPieActiveDropdown_Device - OS_${deviceDropdownValue.value.replaceAll("_", "-")}`]: deviceTraffic,
        ...maleFemaleDataCSV,
      }
    }
  } else {
    companyWiseExportData = {
      ...topCardData,
      "VideoSummaryCard_Summary Cards": metricCards,
      "View Trend Analysis": viewTrendAnalysis,
      "Heatmap_Audience Activity Trend": audienceActivityTrend,
      [`VideoRetentionYoutube_Video Retention Analysis_${videoRetentionDropdownValue.value.replace("_", "-")}`]: videoRetentionAnalysis,
      [`VideoTraffic_Traffic Analysis_${trafficAnalysisFirstDropdownValue.label + "&" + trafficAnalysisSecondDropdownValue.label}`]: trafficAnalysis,
      "CommentSentiment_Comment Sentiment": commentSentiment,
      "VideoCountry_Audience Distribution - Country": NoDataAvailableChecker(countryData) ? [] : countryData,
      [`DevicePieofPieActiveDropdown_Device - OS_${deviceDropdownValue.value.replaceAll("_", "-")}`]: deviceTraffic,
      ...maleFemaleDataCSV,
    }
  }

  var allData = companyWiseExportData;

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    viewTrendAnalysis,
    audienceActivityTrend,
    videoRetentionAnalysis,
    trafficAnalysis,
    companyID !== COKE_COMPANY_ID && commentSentiment,
    deviceTraffic,
    maleCount,
    femaleCount,
    countryData,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (viewTrendAnalysisError !== null ? true : isViewTrendAnalysisLoaded && (isViewTrendAnalysisLoading === false)) &&
      (audienceActivityTrendError !== null ? true : isAudienceActivityTrendLoaded && (isAudienceActivityTrendLoading === false)) &&
      (videoRetentionAnalysiserror !== null ? true : isVideoRetentionAnalysisLoaded && (isVideoRetentionAnalysisLoading === false)) &&
      (trafficAnalysiserror !== null ? true : isTrafficAnalysisLoaded && (isTrafficAnalysisLoading === false)) &&
      (deviceTrafficerror !== null ? true : isDeviceTrafficLoaded && (isDeviceTrafficLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (companyID !== COKE_COMPANY_ID ? (commentSentimenterror !== null ? true : isCommentSentimentLoaded && (isCommentSentimentLoading === false)) : true)
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    viewTrendAnalysisError, isViewTrendAnalysisLoaded, isViewTrendAnalysisLoading,
    audienceActivityTrendError, isAudienceActivityTrendLoaded, isAudienceActivityTrendLoading,
    videoRetentionAnalysiserror, isVideoRetentionAnalysisLoaded, isVideoRetentionAnalysisLoading,
    trafficAnalysiserror, isTrafficAnalysisLoaded, isTrafficAnalysisLoading,
    deviceTrafficerror, isDeviceTrafficLoaded, isDeviceTrafficLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    companyID !== COKE_COMPANY_ID && (isCommentSentimentLoaded, commentSentimenterror, isCommentSentimentLoading)
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">     <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="youtube"
              section="overall single"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div id="video_trend_analysis" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="grid grid_cols">
              <div className="grid cols_span_1">
                <div className="section_info">    <h2 className="section_card_title">
                  View Trend Analysis
                  {selectedHashtag ? " : " + selectedHashtag : ""}
                </h2>
                  <SectionDefinationsBadge
                    title={"View Trend Analysis"}
                    module="video"
                    platform="youtube"
                    section="overall single"
                  />
                </div>
              </div>
              <Modal
                open={isViewTrendAnalysisOpen}
                setOpen={setIsViewTrendAnalysisOpen}
                exportDropdown={
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"viewTrendAnalysis_2"}
                    sectionName={"View Trend Analysis"}
                    sectionData={viewTrendAnalysis}
                  />}
                dropdown={
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={viewTrendFirstOptions}
                    value={viewTrendFirstDropdownValue}
                    selectedOptions={viewTrendFirstDropdownValue}
                    setStatedropdown={viewTrendFirstDropdownHandler}
                    className="form_dropdown section_dropdown mr_20 ml_20"
                  />
                }
                parentId={"video_trend_analysis"}
                title={`View Trend Analysis ${selectedHashtag ? ":" + selectedHashtag : ""}`}
                chart={viewTrendAnalysisSection2}
              />
              <div className="grid cols_span_1">
                <div data-html2canvas-ignore={true}>
                  {isMultiLineCluster && <button onClick={() => setIsViewTrendAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                    <ExpandIcon />
                  </button>}
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={viewTrendFirstOptions}
                    value={viewTrendFirstDropdownValue}
                    selectedOptions={viewTrendFirstDropdownValue}
                    setStatedropdown={viewTrendFirstDropdownHandler}
                    className="form_dropdown section_dropdown mr_20 ml_20"
                  />
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"video_trend_analysis"}
                    sectionName={"View Trend Analysis"}
                    sectionData={viewTrendAnalysis}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewTrendAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div id="video_retention" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card" >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Retention Analysis
              {selectedHashtag && " : " + selectedHashtag}
              {" : " + videoRetentionDropdownValue.label}
            </h2> <SectionDefinationsBadge
                title={"Video Retention Analysis"}
                module="video"
                platform="youtube"
                section="overall single"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={videoRetentionOptions}
                value={videoRetentionDropdownValue}
                selectedOptions={videoRetentionDropdownValue}
                setStatedropdown={videoRetentionDropdownHandler}
                className="form_dropdown section_dropdown"
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_retention"}
                sectionName={`VideoRetentionYoutube_Video Retention Analysis_${videoRetentionDropdownValue.value.replace("_", "-")}`}
                sectionData={videoRetentionAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRetentionAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      {companyID === COKE_COMPANY_ID &&
        <div id="viral_grade_trend_analysis" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Viral Grade Trend Analysis
                {selectedHashtag && " : " + selectedHashtag}
              </h2> <SectionDefinationsBadge
                  title={"Viral Grade Trend Analysis"}
                  module="video"
                  platform="youtube"
                  section="organic"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"viral_grade_trend_analysis"}
                  sectionName={"Viral Grade Trend Analysis"}
                  sectionData={viralGradeTrendAnalysisData}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {ViralGradeTrendAnalysisSection}
              </div>
            </div>
          </div>
        </div>
      }

      <div id="trafic_analysis" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header long_header">

            <div className="section_info long_section_info">    <h2 className="section_card_title">
              Traffic Analysis
              {selectedHashtag && " : " + selectedHashtag}
              {" : " + trafficAnalysisFirstDropdownValue.label}
              {" : " + trafficAnalysisSecondDropdownValue.label}
              <SectionDefinationsBadge
                title={"Traffic Analysis"}
                module="video"
                platform="youtube"
                section="overall single"
              /></h2>
            </div>


            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={trafficAnalysisFirstOptions}
                value={trafficAnalysisFirstDropdownValue}
                selectedOptions={trafficAnalysisFirstDropdownValue}
                setStatedropdown={trafficAnalysisFirstDropdownHandler}
                className="form_dropdown section_dropdown mr_20"
              />
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={trafficAnalysisSecondOptions}
                value={trafficAnalysisSecondDropdownValue}
                selectedOptions={trafficAnalysisSecondDropdownValue}
                setStatedropdown={trafficAnalysisSecondDropdownHandler}
                className="form_dropdown section_dropdown"
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"traffic_analysis"}
                sectionName={`VideoTraffic_Traffic Analysis_${trafficAnalysisFirstDropdownValue.label + "&" + trafficAnalysisSecondDropdownValue.label}`}
                sectionData={trafficAnalysis}
              />


            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {trafficAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div id="audience_dist_gender" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Audience Distribution
              {selectedHashtag && " : " + selectedHashtag}
            </h2>  <SectionDefinationsBadge
                title={"Audience Distribution By Gender"}
                module="video"
                platform="youtube"
                section="overall single"
              />
              {/* {" : " + audienceDistributionDropdownValue.label} */}
            </div>
            <div data-html2canvas-ignore={true}>
              {/* <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={audienceDistributionOptions}
                value={audienceDistributionDropdownValue}
                selectedOptions={audienceDistributionDropdownValue}
                setStatedropdown={audienceDistributionHandler}
                className="form_dropdown section_dropdown"
              /> */}

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_dist_gender"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="audience_dist"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid col_span_1">
              <div className="section_info">   <h2 className="section_card_title">
                  Audience Distribution By Country
                  {selectedHashtag && " : " + selectedHashtag}
                </h2>  <SectionDefinationsBadge
                    title={"Audience Distribution By Country"}
                    module="video"
                    platform="youtube"
                    section="overall single"
                  />
                  {/* {" : " + countryDistributionFirstDropdownData.label}
                  {" : " + countryDistributionSecondDropdownValue.label} */}
      {/* </h2>
              </div>
              <div className="grid col_span_1">
                <div data-html2canvas-ignore={true}> */}
      {/* <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={countryDistributionFirstOptions}
                    value={countryDistributionFirstDropdownData}
                    selectedOptions={countryDistributionFirstDropdownData}
                    setStatedropdown={countryDistributionFirstHandler}
                    className="form_dropdown section_dropdown mr_20"
                  />
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={countryDistributionSecondOptions}
                    value={countryDistributionSecondDropdownValue}
                    selectedOptions={countryDistributionSecondDropdownValue}
                    setStatedropdown={countryDistributionSecondHandler}
                    className="form_dropdown section_dropdown"
                  /> */}
      {/* </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body"> */}
      <div className="grid_margin_bottom">
        {countryMap}
      </div>
      {/* </div>
        </div>
      </div> */}

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        {companyID !== COKE_COMPANY_ID &&
          <Wrapper>
            <div id="comment_sentiment" className="grid col_span_2 section_card">
              <div className="grid col_span_4 ">
                <div className="section_card_header">
                  <div className="section_info">   <h2 className="section_card_title">
                    Comment Sentiment
                    {selectedHashtag && " : " + selectedHashtag}
                  </h2> <SectionDefinationsBadge
                      title={"Comment Sentiment"}
                      module="video"
                      platform="youtube"
                      section="overall single"
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}
                      platform={pagePlatform}
                      tab={pagePrimaryTab}
                      sectionId={"comment_sentiment"}
                      sectionName={"CommentSentiment_Comment Sentiment"}
                      sectionData={commentSentiment}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid_cols_4">
                <div className="grid col_span_4">
                  <div className="grid col_span_1 section_card_body">
                    <div className="section_card_chart_wrapper grid col_span_2">
                      {commentSentimentSection}
                    </div>
                    <div className="section_card_table_wrapper grid col_span_2">
                      <div className="table_wrapper section_card_table_wrapper">
                        {/* <table className="table table_striped table_bordered">
                          <tbody> */}
                        {commentSentimentTable}
                        {/* </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        }
        <div id="device_os" className={companyID !== COKE_COMPANY_ID ? "grid col_span_2 section_card" : "grid col_span_4 section_card"}>
          <div className="grid col_span_4 ">
            <div className="section_card_header">
              <div className="section_info">  <h2 className="section_card_title">
                Device / OS
                {selectedHashtag && " : " + selectedHashtag}
                {" : " + deviceDropdownValue.label}
              </h2> <SectionDefinationsBadge
                  title={"Device Distribution"}
                  module="video"
                  platform="youtube"
                  section="overall single"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={deviceOptions}
                  value={deviceDropdownValue}
                  selectedOptions={deviceDropdownValue}
                  setStatedropdown={deviceDropdownHandler}
                  className="form_dropdown section_dropdown"
                />
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"device_os"}
                  sectionName={`DevicePieofPieActiveDropdown_Device - OS_${deviceDropdownValue.value.replaceAll("_", "-")}`}
                  sectionData={deviceTraffic}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper p_t_0 p_b_0">
                {deviceTrafficSection}
              </div>
            </div>
          </div>
        </div>

      </div>

      {companyID === COKE_COMPANY_ID &&
        <div id="subscriber_gained_lost_analysis" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">    <h2 className="section_card_title">
                Subscriber Gained / Lost Analysis
                {selectedHashtag && " : " + selectedHashtag}
              </h2> <SectionDefinationsBadge
                  title={"Subscriber Gained / Lost Analysis"}
                  module="video"
                  platform="youtube"
                  section="organic"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"subscriber_gained_lost_analysis"}
                  sectionName={"Subscriber Gained - Lost"}
                  sectionData={subscriberGainedLostAnalysisData}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {SubscriberGainedLostAnalysisSection}
              </div>
            </div>
          </div>
        </div>
      }

      {companyID === COKE_COMPANY_ID &&
        <div id="video_engagement_trend" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Video Engagement Trend
                {selectedHashtag && " : " + selectedHashtag}
              </h2>  <SectionDefinationsBadge
                  title={"Video Engagement Trend"}
                  module="video"
                  platform="youtube"
                  section="organic"
                />
              </div>
              <Modal
                open={isVideoEngagementOpen}
                setOpen={setIsVideoEngagementOpen}
                exportDropdown={
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"VideoEngagementTrendSection2"}
                    sectionName={"Video Engagement Trend"}
                    sectionData={videoEngagementTrendData}
                  />}
                parentId={"video_engagement_trend"}
                title={`Video Engagement Trend ${selectedHashtag ? ":" + selectedHashtag : ""}`}
                chart={VideoEngagementTrendSection2}
              />
              <div data-html2canvas-ignore={true}>
                {isMultiLineCluster && <button onClick={() => setIsVideoEngagementOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                  <ExpandIcon />
                </button>}
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"video_engagement_trend"}
                  sectionName={"Video Engagement Trend"}
                  sectionData={videoEngagementTrendData}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {VideoEngagementTrendSection}
              </div>
            </div>
          </div>
        </div>
      }

      <div id="audience_act" className="grid grid_cols_4 fullpage_section_card section_card" >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Audience Activity Trend
              {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Audience Activity Trend"}
                module="video"
                platform="youtube"
                section="overall single"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_act"}
                sectionName={"Heatmap_Audience Activity Trend"}
                sectionData={audienceActivityTrend}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
