import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { useSelector } from "react-redux";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { DonutChartColorSet, PieOfPieSeriesColorSet, PieOfPieSeriesLegendColorSet, USER_COUNTRY } from "../../../constants/constants";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const SimpleTreeMap = ({
  chartId,
  chartClass,
  chartData,
  levelHandler,
  colorChangeParameter,
  chartLabels,
  positiveNegativeNeutral
}) => {

  if (positiveNegativeNeutral === undefined) {
    positiveNegativeNeutral = false
  }

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    let colorSet = [0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8, 0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8];

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Generate and set data
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data

    var data = {
      name: "Root",
      children: chartData
    };

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    function createSeries(label, index) {
      // Create series
      // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
      var series = container.children.push(
        am5hierarchy.Treemap.new(root, {
          singleBranchOnly: false,
          downDepth: 0,
          upDepth: -1,
          initialDepth: 2,
          valueField: label.value,
          categoryField: label.name,
          childDataField: "children",
          nodePaddingOuter: 0,
          nodePaddingInner: 0,
          tooltipValue1: label.toolTipvalue1,
          tooltipValue1Label: label.toolTipvalue1Label,
          tooltipValue2: label.toolTipvalue2,
          tooltipValue2Label: label.toolTipvalue2Label,

          tooltipValue3: label.toolTipvalue3,
          tooltipValue3Label: label.toolTipvalue3Label,

        })
      );

      // TO GET THE VALUE OF RECTANGLE ONCLICK
      series.rectangles.template.events.on("click", function (ev) {
        levelHandler(ev.target.dataItem.dataContext);
      })

      {
        colorChangeParameter === undefined &&

          // TO CHANGE THE COLOR OF THE TREEMAP
          series.rectangles.template.states.create("default", {
            // fill: PieOfPieSeriesLegendColorSet,
            fillOpacity: 1

          });
      }

      {
        (colorChangeParameter && positiveNegativeNeutral === true) && (
          series.rectangles.template.adapters.add("fill", function (fill, target) {
            if (target.dataItem.dataContext[colorChangeParameter] > 0) {
              return am5.color(0x06a57d); // --secondary-green600
            } else if (target.dataItem.dataContext[colorChangeParameter] === 0) {
              return am5.color(0xdb7823); // --secondary-yellow600
            }
            else {
              return am5.color(0xb51c2a); // --secondary-red600
            }
          })
        )
      }

      {
        (colorChangeParameter && positiveNegativeNeutral === false) && (
          series.rectangles.template.adapters.add("fill", function (fill, target) {
            if (target.dataItem.dataContext[colorChangeParameter] >= 0) {
              return am5.color(0x06a57d); // --secondary-green600
            }
            else {
              return am5.color(0xb51c2a); // --secondary-red600
            }
          })
        )
      }

      series.rectangles.template.states.create("hover", {
        fill: am5.color(0x4cc3fd),
        fillOpacity: 1
      });

      series.rectangles.template.setAll({
        // strokeWidth: 2
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4
      });

      series.labels.template.setAll({
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#ffffff",
        fontWeight: "400",
        textAlign: "center",
        text: "[bold]{category}\n{sum}[/]",
      });

      var tooltip = series.set(
        "tooltip", am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
          labelText: "[bold]{name}[/]\n",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {


        series.get("tooltipValue2") === undefined &&
          (
            text += "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue1Label"), target?.dataItem?.dataContext[series?.get("tooltipValue1")], country) + "[/]"
          );

        series.get("tooltipValue2") !== undefined && series.get("tooltipValue3") !== undefined &&
          (
            text += "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue1Label"), target?.dataItem?.dataContext[series?.get("tooltipValue1")], country) + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue2Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue2Label"), target?.dataItem?.dataContext[series?.get("tooltipValue2")], country) + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue3Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue3Label"), target?.dataItem?.dataContext[series?.get("tooltipValue3")], country) + "[/]"
          )


        series.get("tooltipValue2") !== undefined && series.get("tooltipValue3") === undefined &&
          (

            text += "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue1Label"), target?.dataItem?.dataContext[series?.get("tooltipValue1")], country) + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue2Label") + ":[/]" + " " + "[bold  width:100px]" + tooltipNumberFormatting(series.get("tooltipValue2Label"), target?.dataItem?.dataContext[series?.get("tooltipValue2")], country) + "[/]"
          )

        return text;

      });

      series.data.setAll([data]);
      series.set("selectedDataItem", series.dataItems[0]);
      // Make stuff animate on load
      series.appear(1000, 100);
    }

    chartLabels.map((label, index) => {
      createSeries(label, index);
    });


    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

SimpleTreeMap.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
  levelHandler: PropTypes.func,
  colorChangeParameter: PropTypes.string,
  chartLabels: PropTypes.array,
  positiveNegativeNeutral: PropTypes.bool
};

export default SimpleTreeMap;
