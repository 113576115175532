import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../constants/countryCurrency";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const FunnelChart = ({
    chartId,
    chartClass,
    chartData
}) => {

    const userData = useSelector((state) => state.userData.user);
    let userCountryCode = userData?.country_code ?? "IN"

    let country = {}

    CountryCode.map((data) => {
        if (data.countryCode === userCountryCode) {
            country = data
        }
    })

    const formatDataValues = (data) => {
        return data.map(item => ({
            ...item,
            formattedValue: tooltipNumberFormatting(item.category, item.value, country),
        }));
    }

    useLayoutEffect(() => {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new(chartId);

        // to format numbers
        root.numberFormatter.setAll({
            numberFormat: "#.##a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
                { number: 1e3, suffix: "K" },
                { number: 1e6, suffix: "M" },
                { number: 1e9, suffix: "B" },
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: [],
        });

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        var data = formatDataValues(chartData);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/sliced-chart/
        var chart = root.container.children.push(
            am5percent.SlicedChart.new(root, {
                layout: root.verticalLayout
            })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/sliced-chart/#Series
        var series = chart.series.push(
            am5percent.FunnelSeries.new(root, {
                alignLabels: false,
                orientation: "horizontal",
                valueField: "value",
                categoryField: "label",
                bottomRatio: 1
            })
        );

        series.slices.template.setAll({
            strokeWidth: 4
        });

        series.links.template.setAll({
            height: 30
        });

        series.get("colors").set("colors", [
            am5.color(0x11A1FD),
            am5.color(0x5A75F9),
            am5.color(0x07C180),
            am5.color(0xFF9931),
            am5.color(0x7D9EB5),
            am5.color(0x085DB6),
            am5.color(0x3247B5),
            am5.color(0x038673),
            am5.color(0xB45917),
            am5.color(0x486F88),
            am5.color(0x0C7DD9),
            am5.color(0x4259D4),
            am5.color(0x06A57D),
            am5.color(0xDB7823),
            am5.color(0xA7C1D2),
            am5.color(0x4CC3FD),
            am5.color(0x8298FB),
            am5.color(0x3CD795),
            am5.color(0xFFB866),
            am5.color(0xCEDFE8),
        ]);

        // Disabling labels
        series.labels.template.set("forceHidden", true);

        series.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
            text: "{category}"
        });

        var tooltip = series.set(
            "tooltip",
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                getStrokeFromSprite: false,
                autoTextColor: false,
                pointerOrientation: "horizontal",
                position: "pointer",
            })
        );

        tooltip.get("background").setAll({
            fill: am5.color(0x213345),
            oversizedBehavior: "truncate",
            cornerRadius: 5,
        });

        tooltip.label.setAll({
            fill: am5.color(0xffffff),
            fontSize: 13,
        });

        // series.slices.template.set("tooltipText", "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})");
        series.slices.template.set("tooltipText", "[bold]{category}:[/] {formattedValue}");

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/sliced-chart/#Setting_data
        series.data.setAll(data);


        // Play initial series animation
        // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
        series.appear();

        // Create legend
        // Add legend
        var legend = chart.children.push(
            am5.Legend.new(root, {
                position: "relative", // options: "absolute" / "relative"
                x: am5.percent(50),
                centerX: am5.percent(50),
                height: chartData.length >= 6 ? am5.percent(30) : null,
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical",
                }),
            })
        );

        // TO HIDE LABEL VALUES FROM LEGEND 
        legend.valueLabels.template.set("forceHidden", true);

        legend.markers.template.setAll({
            width: 13,
            height: 13,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
        });

        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
        });

        legend.labels.template.setAll({
            // maxWidth: 150,
            //centerY: 0, // if we want labels to be top-aligned
            oversizedBehavior: "wrap",
        });

        legend.data.setAll(series.dataItems);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    );
};

FunnelChart.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    chartData: PropTypes.array
};

export default FunnelChart;
