import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import SocialLinkedinAudience from "./SocialLinkedinAudience";
import SocialLinkedinPage from "./SocialLinkedinPage";
import SocialLinkedinPost from "./SocialLinkedinPost";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";

const SocialLinkedin = ({ platformData }) => {
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData?.company?.id ?? '';

  let tabData = [];

  if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
    tabData = ["Page", "Post"];
  } else {
    tabData = ["Page", "Audience", "Post"];
  }

  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Linkedin", event)
  };

  useEffect(() => {
    setExportPageName("Social Linkedin " + selectedTab)
  }, [selectedTab])


  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === "page" && <SocialLinkedinPage topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
      {selectedTab.toLowerCase() === "audience" && <SocialLinkedinAudience topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
      {selectedTab.toLowerCase() === "post" && <SocialLinkedinPost topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
    </Wrapper>
  );
};

export default SocialLinkedin;
