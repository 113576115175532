import { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
  EmailRegex,
  InvalidEmailMsg,
  RequiredField
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";
import { useDispatch } from "react-redux";
import { setUserID, setUserQR } from "../../store/userSlice";
import useApi from "../../hooks/useApi";
import { encryptPassword } from "../../utils/Utils";


export default function LoginForm() {
  const API_URL = process.env.REACT_APP_API_AUTH_URL;

  const loginAPI = useApi();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [formErros, setFormErrors] = useState();
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(true);

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailError, setEmailError] = useState();

  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passdwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState();

  const [passwordType, setPasswordType] = useState("password");

  // EMAIL ID
  const emailChangeHandler = () => {
    setEmailIsTouched(true);

    if (!EmailRegex.test(emailInputRef.current.value)) {
      setEmailError(InvalidEmailMsg);
      setEmailIsValid(false);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    } else {
      setEmailError();
      setEmailIsValid(true);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    }
  };
  const validateEmailHandler = () => {
    setEmailIsTouched(true);
    if (emailInputRef.current.value === "") {
      setEmailIsValid(false);
      setEmailError(RequiredField);
    }
  };

  // PASSWORD
  const passwordChangeHandler = () => {
    setPasswordIsTouched(true);
    setPasswordIsValid(true);
    setPasswordError();
    setLoginFormData({
      ...loginFormData,
      password: passwordInputRef.current.value,
    });
  };
  const validatePasswordHandler = () => {
    setPasswordIsTouched(true);
    if (passwordInputRef.current.value === "") {
      setPasswordIsValid(false);
      setPasswordError(RequiredField);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // CALL LOGIN API ON SUBMIT FORM CLICK
  const formHandler = () => {
    const inputData = {
      email: loginFormData.email,
      password: encryptPassword(loginFormData.password)
    };

    loginAPI.postData(`${API_URL}auth/login/`, inputData);
  };

  useEffect(() => {
    if (!loginAPI.apiStatus.isLoading && loginAPI.apiStatus.isLoaded && loginAPI.apiStatus.error) {
      setFormErrors(loginAPI.apiStatus.error[0].message);
    } else {

      if (!loginAPI.apiStatus.isLoading && loginAPI.apiStatus.isLoaded && !loginAPI.apiStatus.error) {
        setLoginFormData({
          ...loginFormData,
          email: "",
          password: "",
        });
        dispatch(setUserID(loginAPI.data.user_id)); // SET USER ID
        dispatch(setUserQR(loginAPI.data.qr_code)); // SET USER QR
        navigate("/verify-auth");
      } else {
        setFormErrors(null);
      }
    }
  }, [loginAPI.apiStatus])

  const emailInputIsInvalid = !emailIsValid && emailIsTouched;
  const emailInputClasses = emailInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const passwordInputIsInvalid = !passdwordIsValid && passwordIsTouched;
  const passwordInputClasses = passwordInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  useEffect(() => {
    loginFormData.email !== "" && loginFormData.password !== ""
      ? setCurrentFieldsValidated(false)
      : setCurrentFieldsValidated(true);
  }, [loginFormData]);

  const onSSOLoginBtnClickHandler = () => {
    navigate("/enterprise/login")
  }

  return (
    <Wrapper>
      <div className="form_wrapper">
        <div className="form_header flex column align_center p_10">
          <div className="form_header_logo">
            <img className="logo" src={IcogzLogo} alt="logo" />
          </div>
        </div>
        <div className="p_10 flex justify_center">
          <h6 className="error_text">{formErros}</h6>
        </div>
        <form className="form" action="">
          <div className="form_group">
            <input
              className={emailInputClasses}
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              placeholder="Please Enter Your Email"
              value={loginFormData.email}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
              ref={emailInputRef}
            />
            <div className="error_section">
              {emailInputIsInvalid && (
                <h6 className="error_text">{emailError}</h6>
              )}
            </div>
          </div>
          <div className="form_group">
            <input
              className={passwordInputClasses}
              type={passwordType}
              name="password"
              id="password"
              placeholder="Please Enter Your Password"
              value={loginFormData.password}
              onChange={passwordChangeHandler}
              onBlur={validatePasswordHandler}
              ref={passwordInputRef}
            />
            <div className="form_group_btn_wrapper">
              <button
                className="show_password_btn"
                type="button"
                onClick={() => togglePassword()}
              >
                {passwordType === "password" ? (
                  <IconHideEye className={"icon"} />
                ) : (
                  <IconEye className={"icon"} />
                )}
              </button>
            </div>
            <div className="error_section">
              {passwordInputIsInvalid && (
                <h6 className="error_text">{passwordError}</h6>
              )}
            </div>
          </div>
          <div className="flex justify_between p_10">
            <Link to={"/forgot-password"}>
              <div className="help_text">Forgot Password</div>
            </Link>
            <Link to={"/register"}>
              <div className="help_text">Create new account</div>
            </Link>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={formHandler}
                disabled={isCurrentFieldsValidated}
                className="primary_btn"
                type="button"
              >
                {!loginAPI.apiStatus.isLoading ? "Login" : "Checking your login details"}
              </Button>
            </div>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={onSSOLoginBtnClickHandler}
                className="secondary_btn"
                type="button"
              >
                Sign In with SSO
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}
