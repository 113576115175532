import { useSelector } from "react-redux";
import { HALEON_COMPANY_ID } from "../../constants/constants";
import { DefaultSectionDefinations, HaleonSectionDefinations } from "../../constants/definations";
import Badge from "./Badge";
import Wrapper from "../helpers/Wrapper";

const SectionDefinationsBadge = ({ title, module, platform, section }) => {
    // USER DATA
    const userData = useSelector((state) => state.userData.user);
    let companyID = userData === undefined || userData === [] ? "" :
        userData.company === undefined || userData.company === [] ? "" :
            userData.company.id === undefined || userData.company.id === null ? "" :
                userData.company.id;

    let SectionDefinations = [];

    if (companyID === HALEON_COMPANY_ID) {
        SectionDefinations = HaleonSectionDefinations
    } else {
        SectionDefinations = DefaultSectionDefinations
    }

    let hoverDetails = ""
    SectionDefinations.find((element) => {
        if (element.title.toLowerCase() === title.toLowerCase() && element.module.toLowerCase() === module.toLowerCase() && element.platform.toLowerCase() === platform.toLowerCase() && element.section.toLowerCase() === section.toLowerCase()) {
            return hoverDetails = element.data;
        } else {
            return hoverDetails = ""
        }
    });

    return (
        <Wrapper>
            {hoverDetails !== "" &&
                <div className="hover_badge_wrapper section_badge">
                    <Badge hoverDetails={hoverDetails} />
                </div>
            }
        </Wrapper>
    )
}
export default SectionDefinationsBadge;