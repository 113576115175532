import { useEffect, useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
    API_URL,
    EmailRegex,
    InvalidEmailMsg,
    RequiredField,
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";
import useApi from "../../hooks/useApi";

export default function ForgotPasswordForm() {
    const API_URL = process.env.REACT_APP_API_AUTH_URL;

    const forgotPasswordEmailAPI = useApi();

    const emailInputRef = useRef();

    const [formErrors, setFormErrors] = useState();
    const [formData, setFormData] = useState({
        email: ""
    });

    const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(false);

    const [emailIsTouched, setEmailIsTouched] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailError, setEmailError] = useState();

    const emailInputIsInvalid = !emailIsValid && emailIsTouched;
    const emailInputClasses = emailInputIsInvalid
        ? "form_control invalid"
        : "form_control";

    const emailChangeHandler = () => {
        setEmailIsTouched(true);

        if (!EmailRegex.test(emailInputRef.current.value)) {
            setEmailError(InvalidEmailMsg);
            setEmailIsValid(false);
            setFormData({
                ...formData,
                email: emailInputRef.current.value,
            });
        } else {
            setEmailError();
            setEmailIsValid(true);
            setFormData({
                ...formData,
                email: emailInputRef.current.value,
            });
        }
    };
    const validateEmailHandler = () => {
        setEmailIsTouched(true);
        if (emailInputRef.current.value === "") {
            setEmailIsValid(false);
            setEmailError(RequiredField);
        }
    };

    const formHandler = () => {
        const inputData = {
            email: formData.email
        };

        forgotPasswordEmailAPI.postData(`${API_URL}auth/forgot-password-mail/`, inputData);
    };

    useEffect(() => {
        if (!forgotPasswordEmailAPI.apiStatus.isLoading && forgotPasswordEmailAPI.apiStatus.isLoaded && forgotPasswordEmailAPI.apiStatus.error) {
            setFormErrors(forgotPasswordEmailAPI.apiStatus.error.message);
        } else {
            if(!forgotPasswordEmailAPI.apiStatus.isLoading && forgotPasswordEmailAPI.apiStatus.isLoaded && !forgotPasswordEmailAPI.apiStatus.error){
                setFormData({
                    ...formData,
                    email: "",
                });
            } else{
                setFormErrors(null);
            }
        }
    }, [forgotPasswordEmailAPI.apiStatus])

    useEffect(() => {
        (formData.email !== "" && emailIsValid)
          ? setCurrentFieldsValidated(false)
          : setCurrentFieldsValidated(true);
      }, [formData]);

    return (
        <Wrapper>
            <div className="form_wrapper">
                <div className="form_header flex column align_center p_10">
                    <div className="form_header_logo">
                        <img className="logo" src={IcogzLogo} alt="logo" />
                    </div>
                </div>
                <div className="p_10 flex justify_center">
                    <h4 className="form_subtitle">Forgot Password</h4>
                </div>
                <div className="p_10 flex justify_center">
                    <h6 className="error_text">{formErrors}</h6>
                </div>
                <form className="form" action="">
                    <div className="form_group">
                        <input
                            className={emailInputClasses}
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="off"
                            placeholder="Please Enter Your Email"
                            value={formData.email}
                            onChange={emailChangeHandler}
                            onBlur={validateEmailHandler}
                            ref={emailInputRef}
                        />
                        <div className="error_section">
                            {!emailIsValid && (
                                <h6 className="error_text">{emailError}</h6>
                            )}
                        </div>
                    </div>
                    <div className="flex justify_between p_10">
                        <Link to={"/login"}>
                            <div className="help_text">Login with password</div>
                        </Link>
                        <Link to={"/register"}>
                            <div className="help_text">Create new account</div>
                        </Link>
                    </div>
                    <div className="flex justify_between">
                        <div className="btn_wrapper btn_group_wrapper">
                            <Button
                                handleClick={formHandler}
                                disabled={isCurrentFieldsValidated}
                                className="primary_btn"
                                type="button"
                            >
                                {!forgotPasswordEmailAPI.apiStatus.isLoading ? "Send Email" : "Sending Email"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Wrapper>
    );
}
