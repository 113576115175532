import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";
am5.addLicense("AM5C258896422");

const CategoryCombinedBarLineMultipleAxesChart = ({
	chartId,
	chartClass,
	showLabels,
	showBullets,
	chartData,
	chartLabels,
	showSecondY,
	disabledLegendArray,
	setDisabledValues,
}) => {
	// USER DATA
	const userData = useSelector((state) => state.userData.user);

	let userCountryCode = userData?.country_code ?? "IN"

	let country = {}

	CountryCode.map((data) => {
		if (data.countryCode === userCountryCode) {
			country = data
		}
	})

	useLayoutEffect(() => {

		var root = am5.Root.new(chartId);

		let colorSet = [0x11a1fd, 0xff9931, 0x5a75f9, 0x07c180,];

		root.setThemes([am5themes_Animated.new(root)]);
		root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines


		var chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				layout: root.verticalLayout,
				paddingRight: 0,
				paddingTop: 5,
				paddingBottom: 0,
				paddingLeft: 0,
				maxTooltipDistance: -1,
			})
		);
		var cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "zoomX",
			})
		);
		cursor.lineY.set("visible", false);
		cursor.lineX.set("visible", false);

		var data = chartData

		var xRenderer = am5xy.AxisRendererX.new(root, {
			//minGridDistance: minimumGridDistance,
			stroke: am5.color(0x757575),
			strokeWidth: 0.3,
			strokeOpacity: 1,
		});
		var xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: "label",
				renderer: xRenderer,
			})
		);
		xRenderer.grid.template.setAll({
			location: 1
		})

		xAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
			maxWidth: 150,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400"
		});

		xAxis.data.setAll(data);

		var yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {

				renderer: am5xy.AxisRendererY.new(root, {
					strokeOpacity: 0.1,
					stroke: am5.color(0x757575),
					strokeWidth: 0.3,
					strokeOpacity: 1,
				}),
				min: 0,
				extraMax: 0.04,
				strictMinMaxSelection: true,
			})
		);

		yAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
			maxWidth: 150,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400",
			fill: am5.color(0x757575),
		});


		/* 	Commented the below code as we dont need double axis anyways and made it single axis */
		/* 	var yAxis1 = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
	
					syncWithAxis: yAxis,
					renderer: am5xy.AxisRendererY.new(root, {
	
						opposite: true,
						strokeOpacity: 0.1,
						stroke: am5.color(0x757575),
						strokeWidth: 0.3,
						strokeOpacity: 1,
					}),
					min: 0,
					extraMax: 0.04,
					strictMinMaxSelection: true,
				})
			);
	
			yAxis1.get("renderer").labels.template.setAll({
				oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
				maxWidth: 150,
				textAlign: "center",
				fontSize: 12,
				fontFamily: "Ubuntu",
				fill: "#757575",
				fontWeight: "400",
				fill: am5.color(colorSet[1]),
			});
	 */
		// Add legend
		var legend = chart.children.push(
			am5.Legend.new(root, {
				// background: am5.Rectangle.new(root, {
				//     fill: am5.color(0xff5599),
				//     fillOpacity: 0.2
				// }), // to add the backgrounD to the legends
				position: "relative", // options: "absolute" / "relative"
				// width: am5.percent(100),
				height: chartLabels.length >= 6 ? am5.percent(30) : null,
				x: am5.percent(50),
				centerX: am5.percent(50),
				// clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
				marginTop: 15,
				// marginBottom: 15,
				useDefaultMarker: true,
				layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
				layout: am5.GridLayout.new(root, {
					...(disabledLegendArray === undefined || disabledLegendArray === null
						? {
							maxColumns: 10,
							fixedWidthGrid: true,
						}
						: {
							maxColumns: 15,
						}),
				}),
				verticalScrollbar: am5.Scrollbar.new(root, {
					...(disabledLegendArray === undefined || disabledLegendArray === null
						? {
							orientation: "vertical",
						}
						: {}),
				}),
			})
		);


		if (disabledLegendArray === undefined || disabledLegendArray === null) {
		} else {
			legend.markers.template.setup = function (marker) {
				var check = am5.Graphics.new(root, {
					width: 10,
					height: 10,
					layer: 50,
					fill: am5.color(0xffffff),
					draw: function (display) {
						display.drawRect(4, 4, 6, 6);
					},
				});

				check.states.create("disabled", {
					fillOpacity: 1,
				});

				marker.children.push(check);
			};

			legend.markers.template.setAll({
				width: 14,
				height: 14,
			});
			legend.markerRectangles.template.setAll({
				cornerRadiusTL: 0,
				cornerRadiusTR: 0,
				cornerRadiusBL: 0,
				cornerRadiusBR: 0,
			});
		}

		if (disabledLegendArray === undefined || disabledLegendArray === null) {
			/*   if (flatLegends === true) {
		  legend.markers.template.setAll({
			width: 13,
			height: 1,
		  });
		  legend.markerRectangles.template.setAll({
			cornerRadiusTL: 0,
			cornerRadiusTR: 0,
			cornerRadiusBL: 0,
			cornerRadiusBR: 0,
		  });
  	
		}
		else { */
			legend.markers.template.setAll({
				width: 13,
				height: 13,
			});
			legend.markerRectangles.template.setAll({
				cornerRadiusTL: 10,
				cornerRadiusTR: 10,
				cornerRadiusBL: 10,
				cornerRadiusBR: 10,
			});
			/* } */
		}

		legend.labels.template.setAll({
			fontSize: 12,
			fontWeight: "400",
			fill: "#213345",
		});

		legend.labels.template.setAll({
			// maxWidth: 150,
			//centerY: 0, // if we want labels to be top-aligned
			oversizedBehavior: "wrap",
		});

		if (disabledLegendArray) {
			legend.itemContainers.template.events.on("click", function (ev) {
				var temp = ev.target.dataItem.dataContext;

				chart.series.values.filter((i, index) => {
					if (temp.get("name") === i._settings.name) {
						if (i.get("visible")) {
							setDisabledValues((prev) => {
								var tempArr = [...prev, temp.get("name")];
								var fooBar = [...new Set(tempArr)];
								return fooBar;
							});
						} else {
							setDisabledValues((prev) => {
								var tempArrRmv = prev.filter((i) => i !== temp.get("name"));
								return tempArrRmv;
							});
						}
					}
				});
				root.dateFormatter.setAll({
					dateFormat: "yyyy-MM-dd",
					dateFields: ["label"],
				});
				// chart.series.data.processor = am5.DataProcessor.new(root, {
				//     dateFields: ["label"],
				//     dateFormat: "yyyy-MM-dd",
				// });
			});
		}



		let variabledBaseName = 'series';

		const createSeries = (label, index) => {
			// TO GET DATA FROM THE DATALABELS OBJECT
			Object.keys(label).forEach(function (key, index) {
				if (key === "linechartLabels") {
					// LINECHART
					label.linechartLabels.map((lineChartData, lineChartIndex) => {
						window[variabledBaseName + key + lineChartIndex] = chart.series.push(
							am5xy.SmoothedXLineSeries.new(root, {
								name: lineChartData.label,
								xAxis: xAxis,
								yAxis: yAxis,
								valueYField: lineChartData.value,
								categoryXField: "label",
								fill: am5.color(colorSet[lineChartIndex + index + 1]),
								stroke: am5.color(colorSet[lineChartIndex + index + 1]),
							})
						);

						// for adding gradient to the line series
						window[variabledBaseName + key + lineChartIndex].fills.template.set(
							"fillGradient",
							am5.LinearGradient.new(root, {
								stops: [
									{
										opacity: 0.3,
									},
									{
										opacity: 0,
									},
								],
								rotation: 90,
							})
						);



						window[variabledBaseName + key + lineChartIndex].strokes.template.setAll({
							strokeWidth: 2,
						});

						window[variabledBaseName + key + lineChartIndex].fills.template.setAll({
							fillOpacity: 0.5,
							visible: true,
						});

						// Add bullets


						var tooltip = window[variabledBaseName + key + (0)].set(
							"tooltip",
							am5.Tooltip.new(root, {
								getFillFromSprite: false,
								getStrokeFromSprite: false,
								autoTextColor: false,
								pointerOrientation: "horizontal",
								position: "pointer",
							})
						);

						tooltip.get("background").setAll({
							fill: am5.color(0x213345),
							maxWidth: "auto",
							oversizedBehavior: "truncate",
							cornerRadius: 5,
						});

						tooltip.label.setAll({
							text: "[bold]{categoryX}[/]",
							fill: am5.color(0xffffff),
							fontSize: 13,
						});

						tooltip.label.adapters.add("text", function (text, target) {
							chart.series.each(function (series) {
								var tooltipDataItem = series?.get("tooltipDataItem");
								text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

								if (series.get("childLabel") !== undefined) {
									text += "\n[" + series.get("stroke2").toString() + "]●[/] " + "[bold]" + series.get("childLabel2") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel2"), tooltipDataItem?.dataContext[series.get('childValue2')], country) + "[/]" +
										"\n[]●[/] " + " [bold width:100px]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
								}
							});
							return text;
						});



						window[variabledBaseName + key + lineChartIndex].data.setAll(data);
						legend.data.push(window[variabledBaseName + key + lineChartIndex]);
						window[variabledBaseName + key + lineChartIndex].appear();
					})
				} else {
					// BARCHART
					label.barchartLabels.map((barChartData, barChartIndex) => {
						window[variabledBaseName + key + barChartIndex] = chart.series.push(
							am5xy.ColumnSeries.new(root, {
								name: barChartData.label,
								xAxis: xAxis,
								yAxis: yAxis, /* yAxis1 */ /* 	Commented the code here as we dont need double axis anyways and made it single axis */
								valueYField: barChartData.value,
								categoryXField: "label",
								fill: am5.color(colorSet[0]),
								stroke: am5.color(colorSet[0]),
							})
						);

						window[variabledBaseName + key + barChartIndex].columns.template.setAll({
							cornerRadiusTL: 7,
							cornerRadiusTR: 7,
							width: 10,

							fillOpacity: 0.5,
							strokeOpacity: 0,


						});

						window[variabledBaseName + key + barChartIndex].data.setAll(data);
						legend.data.push(window[variabledBaseName + key + barChartIndex]);
						window[variabledBaseName + key + barChartIndex].appear();
					})
				}

			});
		}

		chartLabels.map((label, index) => {
			createSeries(
				label,
				index
			);
		});
		chart.zoomOutButton.set("forceHidden", true);

		if (
			disabledLegendArray !== undefined ||
			disabledLegendArray !== null ||
			disabledLegendArray.length !== 0
		) {
			function toggle(item) {
				if (item === undefined || item === null) return;
				var storedItem = item;
				chart.series.values.filter((i, index) => {
					if (storedItem.includes(i._settings.name)) {
						var series = chart.series.getIndex(index);
						toggleSeries(series);
					}
				});
			}

			function toggleSeries(series) {
				if (series.get("visible")) {
					root.dateFormatter.setAll({
						dateFormat: "yyyy-MM-dd",
						dateFields: ["valueX"],
					});
					series.data.processor = am5.DataProcessor.new(root, {
						dateFields: ["label"],
						dateFormat: "yyyy-MM-dd",
					});
					series.hide();
				} else {
					root.dateFormatter.setAll({
						dateFormat: "yyyy-MM-dd",
						dateFields: ["valueX"],
					});
					series.data.processor = am5.DataProcessor.new(root, {
						dateFields: ["label"],
						dateFormat: "yyyy-MM-dd",
					});
					series.show();
				}
			}

			setTimeout(() => {
				toggle(disabledLegendArray);
			}, 1);
		}
		return () => {
			root.dispose();
		};
	}, [chartData, chartLabels, disabledLegendArray]);

	return (
		<Wrapper>
			<div id={chartId} className={chartClass}></div>
		</Wrapper>
	);
};

CategoryCombinedBarLineMultipleAxesChart.propTypes = {
	chartId: PropTypes.string,
	chartClass: PropTypes.string,
	showLabels: PropTypes.bool,
	chartData: PropTypes.array,
	chartLabels: PropTypes.array,
	showSecondY: PropTypes.bool,
	showBullets: PropTypes.bool,
	disabledLegendArray: PropTypes.array,
};

export default CategoryCombinedBarLineMultipleAxesChart;