import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { USER_COUNTRY } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const MultiAxisMultiLine = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showBullets,
  onBulletsClick,
  tooltipWidth,
  removeMinTooltipLabelWidth,
  cardTitle,
  flatLegends,
}) => {
  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })


  const convertPrefixes = (name) => {
    const tonnesMetrics = ["Monthly Sales Vol."];
    const percentMetrics = ["Monthly Sales Vol. % Change", "TV Spends % Change", "Market Share", "Grp Percent", "Benchmark", "Meta Organic ER", "Meta Paid ER"];


    if (percentMetrics.includes(name)) {
      return "%"
    } else if (tonnesMetrics.includes(name)) {
      return " T"
    } else {
      return ""
    }
  }

  const addCurrencySymbols = (data) => {
    const currencyMetrics = ["Spends", "Spend", "Digital Spends", 'TV Spends', "TV and Digital Spends", "Cost Per Video View"];
    if (currencyMetrics.includes(data)) {
      // root.numberFormatter.set("bigNumberPrefixes", [
      //   { number: 1e3, suffix: "K" },
      //   { number: 1e5, suffix: "L" },
      //   { number: 1e7, suffix: "Cr" },
      // ])
      return country.currencySymbol;
    } else {
      return "";
    }
  };

  useLayoutEffect(() => {

    var root = am5.Root.new(chartId);
    // root.autoResize = false;

    let colorSet = [
      0x11a1fd, 0x5a75f9, 0x07c180, 0xff9931, 0x7d9eb5, 0x085db6, 0x3247b5,
      0x038673, 0xb45917, 0x486f88, 0x0c7dd9, 0x4259d4, 0x06a57d, 0xdb7823,
      0xa7c1d2, 0x4cc3fd, 0x8298fb, 0x3cd795, 0xffb866, 0xcedfe8, 0x11a1fd,
      0x5a75f9, 0x07c180, 0xff9931, 0x7d9eb5, 0x085db6, 0x3247b5, 0x038673,
      0xb45917, 0x486f88, 0x0c7dd9, 0x4259d4, 0x06a57d, 0xdb7823, 0xa7c1d2,
      0x4cc3fd, 0x8298fb, 0x3cd795, 0xffb866, 0xcedfe8,
    ];

    if (showLegend === undefined) {
      showLegend = true;
    }

    if (tooltipWidth === undefined) {
      tooltipWidth = 200;
    }

    // TO SHOW BULLET IF THE DATA IS SINGLE
    if (chartData.length === 1) {
      showBullets = true;
    }

    let tooltipLabelWidth = "[bold width: 100px]";
    if (removeMinTooltipLabelWidth === undefined) {
      tooltipLabelWidth = "[bold width: 100px]";
    } else if (removeMinTooltipLabelWidth === true) {
      tooltipLabelWidth = "[bold]";
    } else {
      tooltipLabelWidth = "[bold width: 100px]";
    }

    // CURRENCY METRICS ARRAY
    const liquidMetrics = ["Sales Volume"];
    const currencyMetrics = ["Spends"];

    let numberFormatterPrefixes;
    if (liquidMetrics.includes(cardTitle)) {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K-L" },
        { number: 1e6, suffix: "M-L" },
        { number: 1e9, suffix: "B-L" },
      ];
    } else if (currencyMetrics.includes(cardTitle)) {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e5, suffix: "L" },
        { number: 1e7, suffix: "Cr" },
      ];
    } else {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ];
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: numberFormatterPrefixes,

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        pinchZoomX: "none",
        pinchZoomY: "none",
        panX: false,
        panY: false,
        panXY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout, // to add chart and legends in vertical
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("visible", true);
    cursor.lineX.set("visible", true);

    // The data
    var data = chartData;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.2,
      stroke: am5.color(showLegend ? 0x707070 : 0xffffff),
      strokeWidth: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",

        // to make line curve
        maxDeviation: 0.1,

        // to start and end the line from the start and end
        startLocation: 0.2,
        endLocation: 0.8,

        renderer: xRenderer,
      })
    );

    // to show and hide x-axis labels
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
      });
    }

    xAxis.data.setAll(data);

    // var yAxis = chart.yAxes.push(
    //   am5xy.ValueAxis.new(root, {
    //     renderer: am5xy.AxisRendererY.new(root, {
    //       strokeOpacity: 0.2,
    //       stroke: am5.color(showLegend ? 0x707070 : 0xffffff),
    //       strokeWidth: 1,
    //     }),
    //     maxDeviation: 0.1,
    //     extraTooltipPrecision: 1,
    //   })
    // );

    // to show and hide y-axis labels
    // if (showLabels === false) {
    //   yAxis.get("renderer").labels.template.setAll({
    //     visible: false,
    //   });
    // } else {
    //   yAxis.get("renderer").labels.template.setAll({
    //     oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
    //     maxWidth: 150,
    //     textAlign: "center",
    //     fontSize: 12,
    //     fontFamily: "Ubuntu",
    //     fill: "#757575",
    //     fontWeight: "400",
    //   });
    // }

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // height: am5.percent(20),
        x: am5.percent(50),
        // width: am5.percent(100),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        centerX: am5.percent(50),
        marginTop: 15,
        // marginBottom: 15,
        useDefaultMarker: true,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );
    if (flatLegends === true) {
      legend.markers.template.setAll({
        width: 13,
        height: 1,
      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
      });
    } else {
      legend.markers.template.setAll({
        width: 13,
        height: 13,
      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });
    }
    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(
      name,
      field,
      // opposite,
      childLabel,
      childValue,
      index
    ) {
      var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: index % 2 !== 0 ? true : false,
      });
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
          min: 0,
          extraMax: 0.04,
          strictMinMaxSelection: true,
        })
      );
      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      }

      var series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "label",
          childLabel: childLabel,
          childValue: childValue,
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
        })
      );
      /*  series.events.on("datavalidated", function() {
         yAxis.setAll({
           min: yAxis.getPrivate("min"),
           max: yAxis.getPrivate("max"),
         
           start: 0,
           end:1
         });
       }); */
      // to show and hide y-axis labels
      if (showLabels === false) {
        yAxis.get("renderer").labels.template.setAll({
          visible: false,
        });
      } else {
        yAxis.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
          maxWidth: 150,
          textAlign: "center",
          fontSize: 12,
          fontFamily: "Ubuntu",
          fill: "#757575",
          fontWeight: "400",
        });
      }

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.labels.template.set("fill", series.get("fill"));
      yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 1,
        opacity: 1,
      });

      {
        showBullets &&
          series.bullets.push(function () {
            var circle = am5.Circle.new(root, {
              radius: 5,
              stroke: series.get("fill"),
              strokeWidth: 2,
              fill:
                chartData.length === 1
                  ? series.get("fill")
                  : root.interfaceColors.get("background"),
            });

            {
              chartData.length > 1 &&
                circle.events.on("click", function (ev) {
                  onBulletsClick(ev.target.dataItem.dataContext.label);
                });
            }
            circle.states.create("hover", {
              scale: 2,
              cursorOverStyle: "pointer",
            });
            return am5.Bullet.new(root, {
              sprite: circle,
            });
          });
      }

      // for adding gradient to the line series
      series.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.3,
            },
            {
              opacity: 0,
            },
          ],
          rotation: 90,
        })
      );
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });
      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });


      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: tooltipWidth,
        oversizedBehavior: "wrap",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          // let CurrencySymbol = addCurrencySymbols(series.get("name"));
          var tooltipDataItem = series?.get("tooltipDataItem");
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
          }

        });
        return text;
      });

      // tooltip.label.set(
      //     "html", "<span style='font-size: 13px; color: #fff; font-weight: 400'>{categoryX}</span>"
      // )

      // tooltip.label.adapters.add("html", function (html, target) {
      //     chart.series.each(function (series, index) {
      //         html += "<div className='flex justify row'><span style='font-size: 12px; color: #fff; font-weight: 300; margin-right: 10px'>" + series.get("name") + ":</span><span style='font-size: 12px; color: #7D9EB5; font-weight: 400'>" + series.get("valueY") + "</span></div>"
      //     })
      //     return html
      // })

      series.data.setAll(data);
      series.appear(1000);

      // Add legends
      if (showLegend === true) {
        legend.data.push(series);
      }
    }

    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        // label.opposite,
        label.childLabel,
        label.childValue,
        index
      );
    });

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    // }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.zoomOutButton.set("forceHidden", true);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

MultiAxisMultiLine.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showBullets: PropTypes.bool,
  onBulletsClick: PropTypes.func,
  tooltipWidth: PropTypes.any,
  removeMinTooltipLabelWidth: PropTypes.bool,
  cardTitle: PropTypes.string,
  flatLegends: PropTypes.bool,
};

export default MultiAxisMultiLine;