import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../../components/ui/tables/Datatable";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import BubbleChart from "../../../../components/ui/charts/BubbleChart";
import LineChart from "../../../../components/ui/charts/LineChart";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import { IMPRIVATA_COMPANY_ID } from "../../../../constants/constants";



const SocialLinkedinAudience = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Linkedin"
  let pagePrimaryTab = "Audience"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData?.company?.id ?? "";

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // NEW FOLLOWERS
  const [newFollowers, setNewFollowers] = useState([]);
  const [newFollowersLabels, setNewFollowersLabels] = useState([]);
  const [isNewFollowersLoading, setNewFollowersIsLoading] = useState(false);
  const [isNewFollowersLoaded, setNewFollowersIsLoaded] = useState(false);
  const [newFollowerserror, setNewFollowersError] = useState(null);

  // // MALE COUNT
  // const [maleCount, setMaleCount] = useState([]);
  // const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState([]);
  // const [maleCountLabels, setMaleCountLabels] = useState([]);
  // const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] = useState([]);
  // const [totalMaleCount, setTotalMaleCount] = useState(0);
  // const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
  // const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  // const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  // const [maleCounterror, setMaleCountError] = useState(null);

  // // FEMALE COUNT
  // const [femaleCount, setFemaleCount] = useState([]);
  // const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] = useState([]);
  // const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  // const [femaleCountAllDropdownsDataLabels, setFemaleCountAllDropdownsDataLabels] = useState([]);
  // const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  // const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData,] = useState([]);
  // const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  // const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  // const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Followers", field: "follower" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Followers", field: "follower" },
    { name: "Change", field: "percentage_growth" },
  ];

  // INDUSTRY
  const [industry, setIndustry] = useState([]);
  const [industryLabels, setIndustryLabels] = useState([]);
  const [isIndustryLoading, setIndustryIsLoading] = useState(false);
  const [isIndustryLoaded, setIndustryIsLoaded] = useState(false);
  const [industryError, setIndustryError] = useState(null);

  // DESIGNATION
  const [designation, setDesignation] = useState([]);
  const [isDesignationLoading, setDesignationIsLoading] = useState(false);
  const [isDesignationLoaded, setDesignationIsLoaded] = useState(false);
  const [designationError, setDesignationError] = useState(null);

  // COMPANY SIZE WISE DISTRIBUTION
  const [companySizeWiseDistribution, setCompanySizeWiseDistribution] =
    useState([]);
  const [
    isCompanySizeDistributionLoading,
    setCompanySizeDistributionIsLoading,
  ] = useState(false);
  const [isCompanySizeDistributionLoaded, setCompanySizeDistributionIsLoaded] =
    useState(false);
  const [companySizeDistributionError, setCompanySizeDistributionError] =
    useState(null);

  // PAGE GROWTH DROPDOWN
  // // DROPDOWN STATE
  // const [selectedDropdownOption, setSelectedDropdownOption] = useState({
  //   value: "likes",
  //   label: "Likes",
  // });

  // DROPDOWN OPTIONS
  // const audienceDistributionOptions = [
  //   { value: "likes", label: "Likes" },
  //   { value: "followers", label: "Followers" },
  // ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  // const dropdownSelectionHandler = (e) => {
  //   if (e === null) {
  //     setSelectedDropdownOption("");
  //   } else {
  //     setSelectedDropdownOption(e);
  //   }
  //   IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Linkedin", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  // };

  // // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  // const fetchAudienceDistributionMaleFemaleDropdownHandler = async () => {
  //   if (selectedDropdownOption.value === "likes") {
  //     setMaleCount(maleCountAllDropdownsData.likes);
  //     setMaleCountLabels(maleCountAllDropdownsDataLabels.likes);
  //     setTotalMaleCount(totalMaleCountAllDropdownsData.likes);
  //     setFemaleCount(femaleCountAllDropdownsData.likes);
  //     setFemaleCountLabels(femaleCountAllDropdownsDataLabels.likes);
  //     setTotalFemaleCount(totalFemaleCountAllDropdownsData.likes);
  //   } else if (selectedDropdownOption.value === "followers") {
  //     setMaleCount(maleCountAllDropdownsData.followers);
  //     setMaleCountLabels(maleCountAllDropdownsDataLabels.followers);
  //     setTotalMaleCount(totalMaleCountAllDropdownsData.followers);
  //     setFemaleCount(femaleCountAllDropdownsData.followers);
  //     setFemaleCountLabels(femaleCountAllDropdownsDataLabels.followers);
  //     setTotalFemaleCount(totalFemaleCountAllDropdownsData.followers);
  //   } else {
  //     setMaleCount([]);
  //     setMaleCountLabels([]);
  //     setTotalMaleCount([]);
  //     setFemaleCount([]);
  //     setFemaleCountLabels([]);
  //     setTotalFemaleCount([]);
  //   }
  // };

  // // CHANGE  DATA AS PER DROPWON VALUE
  // useEffect(() => {
  //   fetchAudienceDistributionMaleFemaleDropdownHandler();
  // }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchNewFollowersHandler();
    fetchCountryHandler();
    fetchCityHandler();
    // fetchAudienceDistributionHandler();
    fetchIndustryHandler();
    fetchDesignationHandler();
    fetchCompanySizeWiseDistributionHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/metric-card/",
        config
      );
      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (metricCards.length === 0) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="linkedin"
                    section="audience"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // NEW FOLLOWERS

  // TO FETCH NEW FOLLOWERS DATA FROM API
  const fetchNewFollowersHandler = async () => {
    setNewFollowersIsLoading(true);
    setNewFollowersIsLoaded(false);
    setNewFollowersError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/new-follower-trend/",
        config
      );
      setNewFollowers(res?.data?.metric_data ?? []);
      setNewFollowersLabels(res?.data?.dimension ?? []);

      setNewFollowersIsLoading(false);
      setNewFollowersIsLoaded(true);
      setNewFollowersError(null);
    } catch (error) {
      setNewFollowers([]);
      setNewFollowersLabels([]);
      setNewFollowersIsLoading(false);
      setNewFollowersIsLoaded(false);
      setNewFollowersError(error.code);
    }
  };

  let newFollowersLineChart = (
    <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isNewFollowersLoaded && !isNewFollowersLoading) {
    if (
      newFollowers === undefined ||
      newFollowers.length === 0
    ) {
      newFollowersLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      newFollowersLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="new_followers"
              chartClass="section_card_chart"
              chartData={newFollowers}
              chartLabels={newFollowersLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (newFollowerserror) {
    newFollowersLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={newFollowerserror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isNewFollowersLoading) {
    newFollowersLineChart = <Wrapper>  <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // // TO FETCH MALE / FEMALE DATA FROM API
  // const fetchAudienceDistributionHandler = async () => {
  //   setMaleCountIsLoading(true);
  //   setMaleCountIsLoaded(false);
  //   setMaleCountError(null);

  //   setFemaleCountIsLoading(true);
  //   setFemaleCountIsLoaded(false);
  //   setFemaleCountError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "social/linkedin/audience/male-female/",
  //       config
  //     );

  //     setMaleCount(res.data);
  //     setMaleCountLabels(res.data);
  //     setTotalMaleCount(res.data);
  //     setFemaleCount(res.data);
  //     setFemaleCountLabels(res.data);
  //     setTotalFemaleCount(res.data);

  //     setMaleCountAllDropdownsData(res.data);
  //     setMaleCountAllDropdownsDataLabels(res.data);
  //     setTotalMaleCountAllDropdownsData(res.data);
  //     setMaleCountIsLoading(false);
  //     setMaleCountIsLoaded(true);
  //     setMaleCountError(null);

  //     setFemaleCountAllDropdownsData(res.data);
  //     setFemaleCountAllDropdownsDataLabels(res.data);
  //     setTotalFemaleCountAllDropdownsData(res.data);
  //     setFemaleCountIsLoading(false);
  //     setFemaleCountIsLoaded(true);
  //     setFemaleCountError(null);
  //   } catch (error) {
  //     setMaleCountAllDropdownsData([]);
  //     setMaleCountAllDropdownsDataLabels([]);
  //     setTotalMaleCountAllDropdownsData([]);
  //     setMaleCountIsLoading(false);
  //     setMaleCountIsLoaded(false);
  //     setMaleCountError(error.code);

  //     setFemaleCountAllDropdownsData([]);
  //     setFemaleCountAllDropdownsDataLabels([]);
  //     setTotalFemaleCountAllDropdownsData([]);
  //     setFemaleCountIsLoading(false);
  //     setFemaleCountIsLoaded(false);
  //     setFemaleCountError(error.code);
  //   }
  // };

  // // AUDIENCE DISTRIBUTION: MALE
  // let maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  // let maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;

  // // IF DATA IS AVAILABLE
  // if (isMaleCountLoaded && !isMaleCountLoading) {
  //   if (
  //     NoDataAvailableChecker(maleCount) ||
  //     NoDataAvailableChecker(maleCountLabels)
  //   ) {
  //     maleAudienceDistribution = (
  //       <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
  //     );
  //   } else {
  //     maleAudienceDistribution = (
  //       <Wrapper>  <ErrorBoundary chartType="barChartType">
  //         <BarChart
  //           chartId="audience_male"
  //           chartClass="section_card_chart"
  //           chartData={maleCount}
  //           chartLabels={maleCountLabels}
  //           showLegend={false}
  //           /*  showLabels={true}
  //           showHalfVerticalLabels={true}  */
  //           showVerticalLabels={true}
  //           minimumGridDistance={0}
  //         />
  //       </ErrorBoundary>
  //       </Wrapper>
  //     );

  //     let totalMaleCountPercentage = 0;
  //     if (
  //       isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true
  //     ) {
  //       totalMaleCountPercentage = 0;
  //     } else {
  //       totalMaleCountPercentage =
  //         totalMaleCount / (totalMaleCount + totalFemaleCount);
  //     }

  //     maleAudienceDistributionIcon = (
  //       <Wrapper>
  //         <div className="section_icon flex column">
  //           <ErrorBoundary chartType="genderChartType">
  //             <MaleIcon percentage={totalMaleCountPercentage * 100} />
  //           </ErrorBoundary>
  //         </div>
  //         <div className="p_20">
  //           <h3>
  //             <ErrorBoundary>
  //               {+Math.abs(totalMaleCountPercentage * 100)
  //                 .toFixed(2)
  //                 .replace(/\.0$/, "") + "%"}
  //             </ErrorBoundary>
  //           </h3>
  //         </div>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (maleCounterror) {
  //   maleAudienceDistribution = (
  //     <Wrapper>  <ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
  //   );
  //   maleAudienceDistributionIcon = (
  //     <Wrapper> <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isMaleCountLoading) {
  //   maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  //   maleAudienceDistributionIcon = <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>;
  // }

  // // AUDIENCE DISTRIBUTION: FEMALE
  // let femaleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  // let femaleAudienceDistributionIocn = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;

  // // IF DATA IS AVAILABLE
  // if (isFemaleCountLoaded && !isFemaleCountLoading) {
  //   if (
  //     NoDataAvailableChecker(femaleCount) ||
  //     NoDataAvailableChecker(femaleCountLabels)
  //   ) {
  //     femaleAudienceDistribution = (
  //       <Wrapper>   <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
  //     );
  //   } else {
  //     femaleAudienceDistribution = (
  //       <Wrapper> <ErrorBoundary chartType="barChartType">
  //         <BarChart
  //           chartId="audience_female"
  //           chartClass="section_card_chart"
  //           chartData={femaleCount}
  //           chartLabels={femaleCountLabels}
  //           showLegend={false}
  //           /*  showLabels={true}
  //            showHalfVerticalLabels={true}  */
  //           showVerticalLabels={true}
  //           minimumGridDistance={0}
  //         />
  //       </ErrorBoundary>
  //       </Wrapper>
  //     );

  //     let totalFemaleCountPercentage = 0;
  //     if (
  //       isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true
  //     ) {
  //       totalFemaleCountPercentage = 0;
  //     } else {
  //       totalFemaleCountPercentage =
  //         totalFemaleCount / (totalMaleCount + totalFemaleCount);
  //     }

  //     femaleAudienceDistributionIocn = (
  //       <Wrapper>
  //         <div className="section_icon flex column">
  //           <ErrorBoundary chartType="genderChartType">
  //             <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
  //           </ErrorBoundary>
  //         </div>
  //         <div className="p_20">
  //           <h3>
  //             <ErrorBoundary>
  //               {+Math.abs(totalFemaleCountPercentage * 100)
  //                 .toFixed(2)
  //                 .replace(/\.0$/, "") + "%"}
  //             </ErrorBoundary>
  //           </h3>
  //         </div>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (femaleCounterror) {
  //   femaleAudienceDistribution = (
  //     <Wrapper>  <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} /></Wrapper>
  //   );
  //   femaleAudienceDistributionIocn = (
  //     <Wrapper>   <ServerErrorsLoader
  //       chartType="genderChartType"
  //       error={femaleCounterror}
  //     /></Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isFemaleCountLoading) {
  //   femaleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  //   femaleAudienceDistributionIocn = <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/country-wise-follower/",
        config
      );
      setCountryData(res?.data ?? []);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country
              <SectionDefinationsBadge
                title="By Country"
                module="social"
                platform={pagePlatform}
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  )

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData) || NoDataAvailableChecker(CountryTableDataHeaders)) {
      countryMap =
        (<Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country
                  <SectionDefinationsBadge
                    title="By Country"
                    module="social"
                    platform={pagePlatform}
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>)

    } else {
      countryMap = (
        <Wrapper> <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={"By Country"}
            isHalfSection={true}
            tablePagePlatform={pagePlatform}
            tablePagePrimaryTab={pagePrimaryTab}
            tablePageSubModule={pageSubModule}
            isCountryFlagVisible={true}
            tableHeader={CountryTableDataHeaders}
            tableData={countryData}
            tableLength={5}
            isFooterShow={true}
            searchPlaceHolder={"Search Country"}
          />
        </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/city-wise-follower/",
        config
      );
      setCityData(res?.data ?? []);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City
              <SectionDefinationsBadge
                title="By City"
                module="social"
                platform={pagePlatform}
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>);

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (NoDataAvailableChecker(cityData) || NoDataAvailableChecker(CityTableDataHeaders)) {
      cityMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City
                  <SectionDefinationsBadge
                    title="By City"
                    module="social"
                    platform={pagePlatform}
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>);
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isHalfSection={true}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tablePageSubModule={pageSubModule}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INDUSTRY

  // TO FETCH INDUSTRY DATA FROM API
  const fetchIndustryHandler = async () => {
    setIndustryIsLoading(true);
    setIndustryIsLoaded(false);
    setIndustryError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/followers-by-industry/",
        config
      );
      setIndustry(res?.data?.data ?? []);
      setIndustryLabels(res?.data?.dimension ?? []);

      setIndustryIsLoading(false);
      setIndustryIsLoaded(true);
      setIndustryError(null);
    } catch (error) {
      setIndustry([]);
      setIndustryLabels([]);
      setIndustryIsLoading(false);
      setIndustryIsLoaded(false);
      setIndustryError(error.code);
    }
  };

  let industryBarChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isIndustryLoaded && !isIndustryLoading) {
    if (NoDataAvailableChecker(industry) || NoDataAvailableChecker(industryLabels)) {
      industryBarChart = <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>;
    } else {
      industryBarChart = (
        <Wrapper> <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_distribution_industry"
            chartClass="section_card_chart"
            chartData={industry}
            chartLabels={industryLabels}
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (industryError) {
    industryBarChart = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={industryError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isIndustryLoading) {
    industryBarChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchDesignationHandler = async () => {
    setDesignationIsLoading(true);
    setDesignationIsLoaded(false);
    setDesignationError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/linkedin/audience/followers-by-designation/", config);

      setDesignation(res?.data?.metric ?? []);

      setDesignationIsLoading(false);
      setDesignationIsLoaded(true);
      setDesignationError(null);
    } catch (error) {
      setDesignation([]);
      setDesignationIsLoading(false);
      setDesignationIsLoaded(false);
      setDesignationError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let designationChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let designationDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isDesignationLoaded && !isDesignationLoading) {
    if (NoDataAvailableChecker(designation)) {
      designationChart = <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>;
      designationDetails = <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      designationChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"audience_distribution_designtaion"}
              chartClass={"section_card_chart"}
              chartData={designation}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      designationDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {designation && designation.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (designationError) {
    designationChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="donutChartType" error={designationError} />
      </Wrapper>
    );
    designationDetails = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={designationError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDesignationLoading) {
    designationChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    designationDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchCompanySizeWiseDistributionHandler = async () => {
    setCompanySizeDistributionIsLoading(true);
    setCompanySizeDistributionIsLoaded(false);
    setCompanySizeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/company-size-distribution/",
        config
      );
      setCompanySizeWiseDistribution(res?.data ?? []);

      setCompanySizeDistributionIsLoading(false);
      setCompanySizeDistributionIsLoaded(true);
      setCompanySizeDistributionError(null);
    } catch (error) {
      setCompanySizeWiseDistribution([]);
      setCompanySizeDistributionIsLoading(false);
      setCompanySizeDistributionIsLoaded(false);
      setCompanySizeDistributionError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let companySizeDistributionChart = <Wrapper> <Loader loaderType="bubbleChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isCompanySizeDistributionLoaded && !isCompanySizeDistributionLoading) {
    if (NoDataAvailableChecker(companySizeWiseDistribution)) {
      companySizeDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="bubbleChartType" />
        </Wrapper>
      );
    } else {
      companySizeDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <BubbleChart
              chartId={"company_size_wise_distribution"}
              chartClass={"section_card_chart"}
              chartData={companySizeWiseDistribution}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (companySizeDistributionError) {
    companySizeDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="bubbleChartType"
          error={companySizeDistributionError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCompanySizeDistributionLoading) {
    companySizeDistributionChart = <Wrapper> <Loader loaderType="bubbleChartLoader" /></Wrapper>;
  }

  var cityCountryData = {
    "SocialIGCity_City": NoDataAvailableChecker(cityData) ? [] : cityData,
    "SocialIGCountry_Country": NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var industryDesignationCSV = { "SocialLKDIndustry_Industry": industry, "SocialLKDDesignation_Designation": designation };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "New Followers Trend line": newFollowers,
    ...cityCountryData,
    ...industryDesignationCSV,
    "BubbleChartSocial_Company Sizewise Distribution": companySizeWiseDistribution,
  };

  // companyID === IMPRIVATA_COMPANY_ID ? {
  //   ...topCardData,
  //   "SummaryCard_Summary Card": metricCards,
  //   "New Followers Trend line": newFollowers,
  //   ...industryDesignationCSV,
  //   "BubbleChartSocial_Company Sizewise Distribution": companySizeWiseDistribution,
  // } :


  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    newFollowers,
    // maleCount,
    // femaleCount,
    cityData,
    countryData,
    industry,
    designation,
    companySizeWiseDistribution,
  ]);

  useEffect(() => {

    // if (companyID === IMPRIVATA_COMPANY_ID) {
    //   if (
    //     (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
    //     (newFollowerserror !== null ? true : isNewFollowersLoaded && (isNewFollowersLoading === false)) &&
    //     (industryError !== null ? true : isIndustryLoaded && (isIndustryLoading === false)) &&
    //     (designationError !== null ? true : isDesignationLoaded && (isDesignationLoading === false)) &&
    //     (companySizeDistributionError !== null ? true : isCompanySizeDistributionLoaded && (isCompanySizeDistributionLoading === false))
    //   ) {
    //     setIsExportDisabled(false)
    //   } else {
    //     setIsExportDisabled(true)
    //   }

    // } else {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (newFollowerserror !== null ? true : isNewFollowersLoaded && (isNewFollowersLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (industryError !== null ? true : isIndustryLoaded && (isIndustryLoading === false)) &&
      (designationError !== null ? true : isDesignationLoaded && (isDesignationLoading === false)) &&
      (companySizeDistributionError !== null ? true : isCompanySizeDistributionLoaded && (isCompanySizeDistributionLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
    // }

  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    newFollowerserror, isNewFollowersLoaded, isNewFollowersLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    industryError, isIndustryLoaded, isIndustryLoading,
    designationError, isDesignationLoaded, isDesignationLoading,
    companySizeDistributionError, isCompanySizeDistributionLoaded, isCompanySizeDistributionLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="new_followers_trendline_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">New Followers Trend line
            </h2>  <SectionDefinationsBadge
                title={"New Followers Trend line"}
                module="social"
                platform="linkedin"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"new_followers_trendline_section"}
                sectionName={"New Followers"}
                sectionData={newFollowers}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {newFollowersLineChart}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                className={"form_dropdown section_dropdown"}
                placeholder={"Select"}
                isClearable={false}
                options={audienceDistributionOptions}
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />

              <ExportDropdown
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Age", "Value"],
                  ["Age", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* {companyID !== IMPRIVATA_COMPANY_ID && ( */}

      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            {countryMap}
          </div>
          <div className="grid col_span_2 flex column">
            {cityMap}
          </div>
        </div>
      </div>
      {/* )} */}
      <div
        id="industry_and_designation_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Industry and Designation
            </h2> <SectionDefinationsBadge
                title={"Industry and Designation"}
                module="social"
                platform="linkedin"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"industry_and_designation_section"}
                sectionName={"Industry and Designation"}
                sectionData={industryDesignationCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Industry

              </h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {industryBarChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Designation
              </h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {designationChart}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                {designationDetails}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="company_sizewise_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Company Sizewise Distribution
            </h2> <SectionDefinationsBadge
                title={"Company Sizewise Distribution"}
                module="social"
                platform="linkedin"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"company_sizewise_section"}
                sectionName={"BubbleChartSocial_Company Sizewise Distribution"}
                sectionData={companySizeWiseDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {companySizeDistributionChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialLinkedinAudience;
