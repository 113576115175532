import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import SocialTwitterAudience from "./SocialTwitterAudience";
import SocialTwitterPage from "./SocialTwitterPage";
import SocialTwitterPost from "./SocialTwitterPost";

//STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";

const SocialTwitter = ({ platformData }) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

    // USER DATA
    const userData = useSelector((state) => state.userData.user);
    let companyID = userData?.company?.id ?? '';

    let tabData = [];

    if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
        tabData = ["Page", "Post"];
    } else {
        tabData = ["Page", "Audience", "Post"];
    }

    const [selectedTab, setSelectedTab] = useState(tabData[0]);

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Twitter", event)
    }

    useEffect(() => {
        setExportPageName("Social Twitter " + selectedTab)
    }, [selectedTab])

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab.toLowerCase() === "page" && <SocialTwitterPage topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
            {selectedTab.toLowerCase() === "audience" && <SocialTwitterAudience topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
            {selectedTab.toLowerCase() === "post" && <SocialTwitterPost topCardData={{ Summary: exportSummary, 'PlatformCards_Platform Cards': platformData }} />}
        </Wrapper>
    );
};

export default SocialTwitter;
