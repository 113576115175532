import PropTypes, { element } from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { defaultMediaTypeColors } from "../../../constants/constants";
import { tooltipNumberFormatting } from "../../../utils/Utils";
import { CountryCode } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";

am5.addLicense("AM5C258896422");

const ZoomableBubbleChart = ({
  chartId,
  chartClass,
  chartData,
  chartLabels
}) => {
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN"
  let country = {}
  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  useLayoutEffect(() => {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // let colorSet = [0x11a1fd, 0xff9931, 0x5a75f9, 0x86a873];

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
      paddingRight: 0,
      paddingTop: 5,
      paddingBottom: 0,
      paddingLeft: 0,
      maxTooltipDistance: -1,
    }));



    // The data
    var data = chartData;


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 50,
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
    //   renderer: am5xy.AxisRendererX.new(root, {}),
    //   tooltip: am5.Tooltip.new(root, {})
    // }));

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // logarithmic: true,
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        tooltipNumberFormat: "#.00",
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0x757575),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });


    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    // var series = chart.series.push(am5xy.LineSeries.new(root, {
    //   calculateAggregates: true,
    //   xAxis: xAxis,
    //   yAxis: yAxis,
    //   valueYField: "value1",
    //   // valueYFieldLabel: "value1Label",
    //   valueXField: "value2",
    //   // valueXFieldLabel: "value2Label",
    //   valueField: "value",
    //   seriesTooltipTarget: "bullet",
    //   // tooltip: am5.Tooltip.new(root, {
    //   //   pointerOrientation: "horizontal",
    //   //   labelText: "[bold]{title}[/]\nLife expectancy: {valueY.formatNumber('#.0')}\nGDP: {valueX.formatNumber('#,###.')}\nPopulation: {value.formatNumber('#,###.')}"
    //   // })
    // }));

    // series.strokes.template.set("visible", false);

    // var tooltip = series.set(
    //   "tooltip",
    //   am5.Tooltip.new(root, {
    //     getFillFromSprite: false,
    //     getStrokeFromSprite: false,
    //     autoTextColor: false,
    //     pointerOrientation: "horizontal",
    //     position: "pointer",
    //   })
    // );

    // tooltip.get("background").setAll({
    //   fill: am5.color(0x213345),
    //   maxWidth: 200,
    //   oversizedBehavior: "truncate",
    //   cornerRadius: 5,
    // });

    // tooltip.label.setAll({
    //   text: "[bold]{categoryX}[/]",
    //   fill: am5.color(0xffffff),
    //   fontSize: 13,
    // });

    // tooltip.label.adapters.add("text", function (text, target) {
    //   chart.series.each(function (series) {
    //     text +=
    //       "\n[" +
    //       series.get("stroke").toString() +
    //       "]●[/] [bold width:80px]" +
    //       series.get("valueYField") +
    //       ":[/] [bold width:100px]{" +
    //       series.get("valueYField") +
    //       "}[/]";
    //   });
    //   return text;
    // });


    // // Add bullet
    // // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    // var circleTemplate = am5.Template.new({});
    // circleTemplate.adapters.add("fill", function (fill, target) {
    //   var dataItem = target.dataItem;
    //   if (dataItem) {
    //     return am5.Color.fromString(dataItem.dataContext.color);
    //   }
    //   return fill
    // });
    // series.bullets.push(function () {
    //   var bulletCircle = am5.Circle.new(root, {
    //     radius: 5,
    //     fill: series.get("fill"),
    //     fillOpacity: 0.8
    //   }, circleTemplate);
    //   return am5.Bullet.new(root, {
    //     sprite: bulletCircle
    //   });
    // });

    function createSeries(value1, value1Label, value2, value2Label, childValue, childLabel, value) {
      var series = chart.series.push(am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: value1,
        valueYFieldLabel: value1Label,
        valueXField: value2,
        valueXFieldLabel: value2Label,
        valueField: value,
        childValue: childValue,
        childLabel: childLabel,
        seriesTooltipTarget: "bullet",
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: "horizontal",
        //   labelText: "[bold]{title}[/]\n{valueYFieldLabel}: {valueY.formatNumber('#.0')}\nGDP: {valueX.formatNumber('#,###.')}\nPopulation: {value.formatNumber('#,###.')}"
        // })
      }));

      series.strokes.template.set("visible", false);

      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: "auto",
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{valueYFieldLabel} vs {valueXFieldLabel}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          var tooltipDataItem = series?.get("tooltipDataItem");

          text +=
            "\n[]●[/] [bold width:80px]" + series.get("valueYFieldLabel") + ":[/] [bold width:120px]{" + tooltipNumberFormatting(series?.get("valueYFieldLabel"), tooltipDataItem?.get("valueY"), country) + "}[/]\n" +
            "[]●[/] [bold width:80px]" + series.get("valueXFieldLabel") + ":[/] [bold width:120px]{" + tooltipNumberFormatting(series?.get("valueXFieldLabel"), tooltipDataItem?.get('valueX'), country) + "}[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[]●[/] [bold width:80px] " + series.get("childLabel") + ":[/] [bold width:120px]{" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get("childValue")], country) + "}[/]";
          }
        });
        return text;
      });


      // Add bullet
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
      var circleTemplate = am5.Template.new({});

      circleTemplate.adapters.add("fill", function (fill, target) {
        var dataItem = target.dataItem;
        if (dataItem) {
          return am5.Color.fromString(dataItem.dataContext.color);
        }
        return fill
      });

      series.bullets.push(function () {
        var bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
          fillOpacity: 0.8
        }, circleTemplate);
        return am5.Bullet.new(root, {
          sprite: bulletCircle
        });
      });

      // Add heat rule
      // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
      series.set("heatRules", [{
        target: circleTemplate,
        min: 3,
        max: 60,
        dataField: "value",
        key: "radius"
      }]);

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        snapToSeries: [series]
      }));

      // Add legend
      var legend = chart.children.push(
        am5.Legend.new(root, {
          position: "relative",
          x: am5.percent(50),
          centerX: am5.percent(50),
          marginTop: 15,
          marginBottom: 15,
          useDefaultMarker: true,
          layout: root.horizontalLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
          layout: am5.GridLayout.new(root, {
            // maxColumns: 4,
            fixedWidthGrid: true,
          }),
          verticalScrollbar: am5.Scrollbar.new(root, {
            orientation: "vertical",
          }),
        })
      );

      legend.markers.template.setAll({
        width: 13,
        height: 13,
      });

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: "400",
        fill: "#213345",
      });

      legend.labels.template.setAll({
        // maxWidth: 150,
        //centerY: 0, // if we want labels to be top-aligned
        oversizedBehavior: "wrap",
      });


      series.data.setAll(data)
      series.appear(1000);
    }



    // Add scrollbars
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

    chartLabels.map((label, index) => {
      createSeries(
        label.value1,
        label.value1Label,
        label.value2,
        label.value2Label,
        label.childValue,
        label.childLabel,
        label.value,
        index
      );
    });


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

ZoomableBubbleChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  showVerticalLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
};

export default ZoomableBubbleChart;
