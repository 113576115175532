const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    user: null,
    userId: null,
    userQR: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData(state, action) {
            state.user = action.payload;
        },
        setUserID(state, action) {
            state.userId = action.payload;
        },
        setUserQR(state, action) {
            state.userQR = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const { setUserData, resetUserData, setUserID, setUserQR } = userSlice.actions;

export default userSlice.reducer;
