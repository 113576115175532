
import Verify2FAForm from "../components/forms/Verify2FAForm";
import Wrapper from "../components/helpers/Wrapper";

const Verify2FAPage = () => {
    return (
        <Wrapper>
            <div className="grid grid_cols_4 grid_center">
                <div className="grid col_span_2 col_start_2 section_card grid_center h_auto">
                    <Verify2FAForm />
                </div>
            </div>
        </Wrapper >

    )
}
export default Verify2FAPage;