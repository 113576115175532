import { useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import Wrapper from "./Wrapper";
import AuthContext from "../../store/authContext";
import getBuildDate from "../../utils/BuildDate";
import packageJson from "../../../package.json";

// PAGES
import Dashboard from "../../pages/Dashboard";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";
import Campaign from "../../pages/mi/campaign/Campaign";
import Social from "../../pages/mi/social/Social";
import Hashtag from "../../pages/mi/Hashtag";
import Video from "../../pages/mi/video/Video";
import Website from "../../pages/mi/website/Website";
import Compare from "../../pages/mi/compare/Compare";
import DashboardTest from "../../pages/DashboardTest";
import Influencers from "../../pages/mi/Influencers";
import CampaignAdmin from "../../pages/admin/campaign/CampaignAdmin";
import CreateBucket from "../../pages/admin/campaign/bucketLists/CreateBucket";
import Orm from "../../pages/mi/socialListening/orm";
import SSOLoginPage from "../../pages/SSOLoginPage";
import Verify2FAPage from "../../pages/Verify2FAPage";


export default function RouteUrls() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  // PERSISTS STATES
  let store = useSelector((state) => state);

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  console.log('userData', userData);

  // SIDEBAR DATA
  const sidebarData = useSelector((state) => state.sidebar.sidebarData);

  // TO CHECK WHICH MODULE IS DEFAULT
  let defaultModule = sidebarData[0]?.code ?? "";

  var filterMI = sidebarData.filter((x) => x.code.toLowerCase() === 'mi')
  let miDefaultSubModule = filterMI[0]?.sub_module[0]?.code ?? '';



  if (
    store.campaignAdmin === undefined ||
    store.campaignModuleFilter === undefined ||
    store.date === undefined ||
    store.globalSelectedBrandIds === undefined ||
    store.riSalesBrandsCategory === undefined ||
    store.sidebar === undefined ||
    store.tvBrandsCamapaigns === undefined ||
    store.userData === undefined
  ) {
    authCtx.isLoggedIn = false
  }

  return (
    <Wrapper>
      <Routes>
        <Route path={"/login"} element={authCtx.isLoggedIn !== true ? <LoginPage /> : <Navigate to="/" />} />

        <Route path={"/verify-auth"} element={authCtx.isLoggedIn !== true ? <Verify2FAPage /> : <Navigate to="/" />} />

        <Route path={"/enterprise/login"} element={authCtx.isLoggedIn !== true ? <SSOLoginPage /> : <Navigate to="/" />} />

        <Route path={"/register"} element={authCtx.isLoggedIn !== true ? (<RegistrationPage />) : (<Navigate to="/" />)} />

        <Route path={"/forgot-password"} element={authCtx.isLoggedIn !== true ? (<ForgotPasswordPage />) : (<Navigate to="/" />)} />

        <Route path={"/"} element={
          authCtx.isLoggedIn === true ?
            defaultModule.toLowerCase() === "home" ? <Dashboard /> :
              defaultModule.toLowerCase() === "mi" ? <Navigate to="/mi" replace state={{ from: location }} /> :
                defaultModule.toLowerCase() === "ri" ? <Navigate to="/ri" replace state={{ from: location }} /> :
                  <Dashboard /> :
            <Navigate to="/login" />
        } />

        <Route path={"/mi"} >

          <Route path={"/mi"} exact index={true} element={authCtx.isLoggedIn === true ? <Navigate to={`/mi/${miDefaultSubModule}`} replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"campaigns"} index={true} element={authCtx.isLoggedIn === true ? (<Campaign />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"social"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
            <Route path={":platform"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          </Route>

          <Route path={"video"} index={true} element={authCtx.isLoggedIn === true ? (<Video />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"hashtag"} index={true} element={authCtx.isLoggedIn === true ? (<Hashtag />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"compare"} index={true} element={authCtx.isLoggedIn === true ? (<Compare />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"website"} index={true} element={authCtx.isLoggedIn === true ? (<Website />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"influencers"} index={true} element={authCtx.isLoggedIn === true ? (<Influencers />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"social-listening"} index={true} element={authCtx.isLoggedIn === true ? <Orm /> : <Navigate to="/login" />} />
        </Route>


        <Route path={"/admin"} >
          <Route path={"/admin"} index={true} element={authCtx.isLoggedIn === true ? (<Navigate to="/admin/campaign" replace state={{ from: location }} />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign"} index={true} element={authCtx.isLoggedIn === true ? (<CampaignAdmin />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/create-bucket"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/edit-bucket/:bucketId"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/test"} element={authCtx.isLoggedIn === true ? (<DashboardTest />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path={"/dev"} element={authCtx.isLoggedIn === true ? 'Build date: ' + getBuildDate(packageJson.buildDate) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path="*" element={<Navigate to="/" replace state={{ from: location }} />} />

      </Routes>
    </Wrapper >
  );
}
