import { useContext, useEffect, useMemo, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import LineChart from "../../../../components/ui/charts/LineChart";

// LOADER
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { COKE_COMPANY_ID } from "../../../../constants/constants";
import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";


// CONSTANTS
// import { DefaultPostImage } from "../../../../constants/constants";


const SocialFacebookPostPaid = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Post"
  let pageSecondaryTab = "Paid"
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, setIsExportDisabled } = useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const userData = useSelector((state) => state.userData.user);

  let companyID = userData?.company?.id ?? [];

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Paid";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // POST IMPRESSIONS DETAILS
  // const [postImpressionsDetails, setPostImpressionsDetails] = useState([]);
  // const [postImpressionsTopPImgUrl, setPostImpressionsTopPImgUrl] = useState(
  //   []
  // );
  // const [isPostImpressionsDetailsLoading, setPostImpressionsDetailsIsLoading] =
  //   useState(false);
  // const [isPostImpressionsDetailsLoaded, setPostImpressionsDetailsIsLoaded] =
  //   useState(false);
  // const [postImpressionsDetailserror, setPostImpressionsDetailsError] =
  //   useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // POST ENGAGEMENTS
  const [postEnagagements, setPostEnagagements] = useState([]);
  const [postEnagagementsDataLabels, setPostEnagagementsDataLabels] = useState(
    []
  );
  const [isPostEnagagementsLoading, setPostEnagagementsIsLoading] =
    useState(false);
  const [isPostEnagagementsLoaded, setPostEnagagementsIsLoaded] =
    useState(false);
  const [postEnagagementserror, setPostEnagagementsError] = useState(null);

  // POST ENGAGEMENTS DETAILS
  const [postEnagagementsDetails, setPostEnagagementsDetails] = useState([]);
  const [
    isPostEnagagementsDetailsLoading,
    setPostEnagagementsDetailsIsLoading,
  ] = useState(false);
  const [isPostEnagagementsDetailsLoaded, setPostEnagagementsDetailsIsLoaded] =
    useState(false);
  const [postEnagagementsDetailserror, setPostEnagagementsDetailsError] =
    useState(null);

  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // TO GET THE TOP POST FROM POST IMPRESSIONS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates]);

  // WHEN HASHTAG IS SELELCTED
  useEffect(() => {
    setTopPostSelectedDate("");
    fetchMetricCardsHandler();
    fetchPostImpressionsChartHandler();
    fetchVideoViewsChartHandler();
    fetchPostEngagementHandler();
    fetchPostEngagementDetailsHandler();
    fetchTopPostsCompareHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag]);

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    // fetchPostImpressionsDetailsHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, topPostSelectedDate]);

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/hashtag-list/",
        config
      );

      setHashtagList(res?.data?.hashtag_list ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }

    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Facebook", "Post", "Paid", badge.hashtag)
  };

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader = `: ` + selectedHashtag;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  // const bulletClicksHandler = (data) => {
  //   setTopPostSelectedDate(data);
  // };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  let topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper> <NoDataAvailableLoader chartType="hashChartType" /></Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper> <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper><Loader loaderType="hashLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/metric-card/",
        config
      );
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  let metricCardHeaderTitle = "Summary" + selectedHashtagSectionHeader;

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">{metricCardHeaderTitle}
          </h2></div>

        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">{metricCardHeaderTitle}
              </h2></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={metricCardHeaderTitle}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="facebook"
                    section="paid"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">{metricCardHeaderTitle}
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">{metricCardHeaderTitle}
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const [isPostImpressionsOpen, setIsPostImpressionsOpen] = useState(false)

  let defaultPostImpressionsDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPostImpressionsDisabledvalues = []
  } else {
    defaultPostImpressionsDisabledvalues = ["Previous"]
  }

  const [postImpressionsDisabledvalues, setPostImpressionsDisabledvalues] = useState(defaultPostImpressionsDisabledvalues)

  const fetchPostImpressionsChartHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/post-impressions/",
        config
      );

      setPostImpressions(res?.data?.post_impression_data ?? []);
      setPostImpressionsDataLabels(res?.data?.label ?? []);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  let postImpressionsChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (NoDataAvailableChecker(postImpressions)) {
      postImpressionsChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      postImpressionsChart2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        postImpressionsChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="post_impressions"
                chartClass="section_card_chart"
                chartData={postImpressions}
                chartLabels={postImpressionsDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={postImpressionsDisabledvalues}
                setDisabledValues={setPostImpressionsDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        postImpressionsChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="post_impressions_2"
                chartClass="section_card_chart"
                chartData={postImpressions}
                chartLabels={postImpressionsDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={postImpressionsDisabledvalues}
                setDisabledValues={setPostImpressionsDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );



      } else {
        postImpressionsChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="post_impressions"
                chartClass="section_card_chart"
                chartData={postImpressions}
                chartLabels={postImpressionsDataLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="lineChartType"
        error={postImpressionserror}
      /></Wrapper>
    );

    postImpressionsChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={postImpressionserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
    postImpressionsChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS DETAILS

  // TO FETCH DATA FROM API
  // const fetchPostImpressionsDetailsHandler = async () => {
  //   setPostImpressionsDetailsIsLoading(true);
  //   setPostImpressionsDetailsIsLoaded(false);
  //   setPostImpressionsDetailsError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       post_type: PostType,
  //       hashtag: selectedHashtag,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "social/facebook/post/paid/impressions-info/",
  //       config
  //     );

  //     setPostImpressionsDetails(
  //       res.data.length === 0 || res.data === undefined ? [] :
  //        res.data.top_post_overall_impressions_list.length === 0 ||  res.data.top_post_overall_impressions_list === undefined ? [] :
  //          res.data.top_post_overall_impressions_list
  //     );
  //     setPostImpressionsTopPImgUrl(res.data.length === 0 ? [] : res.data.url);
  //     setPostImpressionsDetailsIsLoading(false);
  //     setPostImpressionsDetailsIsLoaded(true);
  //     setPostImpressionsDetailsError(null);
  //   } catch (error) {
  //     setPostImpressionsDetails([]);
  //     setPostImpressionsTopPImgUrl([]);
  //     setPostImpressionsDetailsIsLoading(false);
  //     setPostImpressionsDetailsIsLoaded(false);
  //     setPostImpressionsDetailsError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let postImpressionsDetailsData = <Loader loaderType="smallTableLoader" />;
  // let postImpressionsTopPostImg = <Loader loaderType="imageLoader" />;

  // // IF DATA IS LOADED
  // if (isPostImpressionsDetailsLoaded && !isPostImpressionsDetailsLoading) {
  //   if (NoDataAvailableChecker(postImpressionsDetails)) {
  //     postImpressionsDetailsData = (
  //       <NoDataAvailableLoader chartType="tableChartType" />
  //     );
  //     postImpressionsTopPostImg = (
  //       <NoDataAvailableLoader chartType="imageChartType" />
  //     );
  //   } else {
  //     postImpressionsDetailsData = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="tableChartType">
  //           <table className="table table_striped table_bordered">
  //             <tbody>
  //               {postImpressionsDetails.map((details, postImpressionIndex) => {
  //                 return (
  //                   <Wrapper key={postImpressionIndex}>
  //                     <tr>
  //                       <td>{details.label}</td>
  //                       <td className="bold">
  //                         <NumberFormatter number={details.value} />
  //                       </td>
  //                     </tr>
  //                   </Wrapper>
  //                 );
  //               })}
  //             </tbody>
  //           </table>
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //     if (NoDataAvailableChecker(postImpressionsTopPImgUrl)) {
  //       postImpressionsTopPostImg = (
  //         <NoDataAvailableLoader chartType="imageChartType" />
  //       );
  //       setPostImpressionsTopPImgUrl(DefaultPostImage);
  //     } else {
  //       postImpressionsTopPostImg = (
  //         <Wrapper>
  //           <ErrorBoundary chartType="imageChartType">
  //             <div className="img_wrapper">
  //               <img
  //                 src={postImpressionsTopPImgUrl}
  //                 alt="img"
  //                 className="section_img"
  //                 onError={(e) => (e.target.src = DefaultPostImage)}
  //               />
  //             </div>
  //           </ErrorBoundary>
  //         </Wrapper>
  //       );
  //     }
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (postImpressionsDetailserror) {
  //   postImpressionsDetailsData = (
  //     <ServerErrorsLoader
  //       chartType="tableChartType"
  //       error={postImpressionsDetailserror}
  //     />
  //   );
  //   postImpressionsTopPostImg = (
  //     <ServerErrorsLoader
  //       chartType="imageChartType"
  //       error={postImpressionsDetailserror}
  //     />
  //   );
  // }

  // // IF DATA LOADING
  // if (isPostImpressionsDetailsLoading) {
  //   postImpressionsDetailsData = <Loader loaderType="smallTableLoader" />;
  //   postImpressionsTopPostImg = <Loader loaderType="imageLoader" />;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS
  const [isVideoViewsOpen, setIsVideoViewsOpen] =
    useState(false);

  let defaultVideoViewsOpenDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoViewsOpenDisabledValues = [];
  } else {
    defaultVideoViewsOpenDisabledValues = ["Sales Volume"];
  }

  const [
    videoViewsOpenDisabledValues,
    setVideoViewsOpenDisabledValues,
  ] = useState(defaultVideoViewsOpenDisabledValues);

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/videos-views/",
        config
      );

      setVideoViews(res?.data?.data ?? []);
      setVideoViewsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
              res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                res.data.Dimensions
      );
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  let videoViewsChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>
  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
      videoViewsChart2 = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        videoViewsChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_facebook_current_period_1"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        videoViewsChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_facebook_current_period_2"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else {
        videoViewsChart = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartId="social_facebook_current_period"
                chartClass="section_card_chart"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      /></Wrapper>
    );
    videoViewsChart2 = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
    videoViewsChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST ENGAGEMENTS
  const [isPostEngagementOpen, setIsPostEngagementOpen] = useState(false)

  let defaultPostEngagementDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPostEngagementDisabledvalues = []
  } else {
    defaultPostEngagementDisabledvalues = ["Impressions", "Comments", "Reactions"]
  }

  const [postEngagementDisabledvalues, setPostEngagementDisabledvalues] = useState(defaultPostEngagementDisabledvalues)

  // TO FETCH DATA FROM API
  const fetchPostEngagementHandler = async () => {
    setPostEnagagementsIsLoading(true);
    setPostEnagagementsIsLoaded(false);
    setPostEnagagementsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/post-engagement/",
        config
      );

      setPostEnagagements(res?.data?.post_overall_engagement_data ?? []);
      setPostEnagagementsDataLabels(res?.data?.dimension ?? []);
      setPostEnagagementsIsLoading(false);
      setPostEnagagementsIsLoaded(true);
      setPostEnagagementsError(null);
    } catch (error) {
      setPostEnagagements([]);
      setPostEnagagementsDataLabels([]);
      setPostEnagagementsIsLoading(false);
      setPostEnagagementsIsLoaded(false);
      setPostEnagagementsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postEnagagementsChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  let postEnagagementsChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPostEnagagementsLoaded && !isPostEnagagementsLoading) {
    if (
      NoDataAvailableChecker(postEnagagements) ||
      NoDataAvailableChecker(postEnagagementsDataLabels)
    ) {
      postEnagagementsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      postEnagagementsChart2 = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        postEnagagementsChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="post_engagement"
                chartClass="section_card_chart"
                chartData={postEnagagements}
                chartLabels={postEnagagementsDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={postEngagementDisabledvalues}
                setDisabledValues={setPostEngagementDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        postEnagagementsChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="post_engagement_2"
                chartClass="section_card_chart"
                chartData={postEnagagements}
                chartLabels={postEnagagementsDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={postEngagementDisabledvalues}
                setDisabledValues={setPostEngagementDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        postEnagagementsChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="post_engagement"
                chartClass="section_card_chart"
                chartData={postEnagagements}
                chartLabels={postEnagagementsDataLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postEnagagementserror) {
    postEnagagementsChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="lineChartType"
        error={postEnagagementserror}
      /></Wrapper>
    );

    postEnagagementsChart2 = (
      <Wrapper> <ServerErrorsLoader
        chartType="lineChartType"
        error={postEnagagementserror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPostEnagagementsLoading) {
    postEnagagementsChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    postEnagagementsChart2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST ENGAGEMENTS DETAILS

  // TO FETCH DATA FROM API
  const fetchPostEngagementDetailsHandler = async () => {
    setPostEnagagementsDetailsIsLoading(true);
    setPostEnagagementsDetailsIsLoaded(false);
    setPostEnagagementsDetailsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/post-engagement-info/",
        config
      );

      setPostEnagagementsDetails(res?.data?.post_overall_engagement_info ?? []);
      setPostEnagagementsDetailsIsLoading(false);
      setPostEnagagementsDetailsIsLoaded(true);
      setPostEnagagementsDetailsError(null);
    } catch (error) {
      setPostEnagagementsDetails([]);
      setPostEnagagementsDetailsIsLoading(false);
      setPostEnagagementsDetailsIsLoaded(false);
      setPostEnagagementsDetailsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postEnagagementsDetailsData = <Wrapper> <Loader loaderType="smallTabletLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (isPostEnagagementsDetailsLoaded && !isPostEnagagementsDetailsLoading) {
    if (NoDataAvailableChecker(postEnagagementsDetails)) {
      postEnagagementsDetailsData = (
        <Wrapper>  <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      postEnagagementsDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {postEnagagementsDetails && postEnagagementsDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postEnagagementsDetailserror) {
    postEnagagementsDetailsData = (
      <Wrapper><ServerErrorsLoader
        chartType="tableChartType"
        error={postEnagagementsDetailserror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPostEnagagementsDetailsLoading) {
    postEnagagementsDetailsData = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/post/paid/top_five_post/",
        config
      );

      setTopPostsCompare(res?.data ?? []);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 grid_margin_bottom section_card_header">
          <div className="section_info">  <h2 className="section_card_title">Top 5 Post
          </h2>  <SectionDefinationsBadge
              title={"Top 5 Post"}
              module="social"
              platform="facebook"
              section="paid"
            /></div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  let topPostsCompareHeaderTitle = "Post" + selectedHashtagSectionHeader;

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (NoDataAvailableChecker(topPostsCompare)) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 grid_margin_bottom section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Top 5 Post
              </h2> <SectionDefinationsBadge
                  title={"Top 5 Post"}
                  module="social"
                  platform="facebook"
                  section="paid"
                />
              </div>
            </div>
            <div className="grid col_span_4">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              social_platform="Facebook"
              module_section="paid"
              products={topPostsCompare}
              isPaid={true}
              Title={topPostsCompareHeaderTitle}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">  <h2 className="section_card_title">Top 5 Post
            </h2> <SectionDefinationsBadge
                title={"Top 5 Post"}
                module="social"
                platform="facebook"
                section="paid"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topPostsCompareerror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Top 5 Post
            </h2> <SectionDefinationsBadge
                title={"Top 5 Post"}
                module="social"
                platform="facebook"
                section="paid"
              /></div>
          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  var postEngagementCSV = {
    "SocialFbPostEngage_Post Engagement": postEnagagements,
    "SocialFBEngagement_Post Engagement Detail": postEnagagementsDetails,
  };

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "SocialPageViews_Post Impressions": postImpressions,
    "Video Views": combineCSV(videoViews),
    ...postEngagementCSV,
    "Compare_Top 5 Post": topPostsCompare,
  };

  useEffect(() => {
    setExportPageName("Social Facebook Post Paid");
    setFullPageExport(allData);

  }, [
    metricCards,
    postImpressions,
    videoViews,
    postEnagagements,
    postEnagagementsDetails,
    topPostsCompare,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (postImpressionserror !== null ? true : isPostImpressionsLoaded && (isPostImpressionsLoading === false)) &&
      (videoViewserror !== null ? true : isVideoViewsLoaded && (isVideoViewsLoading === false)) &&
      (postEnagagementserror !== null ? true : isPostEnagagementsLoaded && (isPostEnagagementsLoading === false)) &&
      (topPostsCompareerror !== null ? true : isTopPostsCompareLoaded && (isTopPostsCompareLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    postImpressionserror, isPostImpressionsLoaded, isPostImpressionsLoading,
    videoViewserror, isVideoViewsLoaded, isVideoViewsLoading,
    postEnagagementserror, isPostEnagagementsLoaded, isPostEnagagementsLoading,
    topPostsCompareerror, isTopPostsCompareLoaded, isTopPostsCompareLoading,
  ])

  // UPDATE IA WHEN PAGE LOAD OR HASHTAG CLICKS
  useEffect(() => {
    IAEvent_Hashtag_Visit(
      "Marketing Intelligence",
      "Social",
      "Facebook",
      "Post",
      "Paid",
      selectedHashtag
    );
  }, [selectedHashtag]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">  <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title="Trending Hashtags"
              module="social"
              platform="facebook"
              section="paid"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="post_impression_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Post Impressions {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Post Impression"
                module="social"
                platform="facebook"
                section="paid"
              />
            </div>
            <Modal
              open={isPostImpressionsOpen}
              setOpen={setIsPostImpressionsOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  subTab={pageSecondaryTab}
                  sectionId={"post_impressions_2"}
                  sectionName={"SocialPageViews_Post Impressions"}
                  sectionData={postImpressions}
                />}
              parentId={"post_impression_section"}
              title={`Post Impressions ${selectedHashtagSectionHeader}`}
              chart={postImpressionsChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPostImpressionsOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"post_impression_section"}
                sectionName={"SocialPageViews_Post Impressions"}
                sectionData={postImpressions}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_2 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postImpressionsChart}
              </div>
            </div>
          </div>
          {/* <div className="grid col_span_2">
            <div className="grid col_span_1 section_card_body flex column">
              <div className="grid grid_cols_1">
                <div className="date_badge_section">
                  <div className="date_badge_wrapper">
                    <div className="date_badge_title">
                      Top post from:{" "}
                      {topPostSelectedDate === "" &&
                        `${selectedDates.startDate} to ${selectedDates.endDate}`}
                    </div>
                    {topPostSelectedDate !== "" && (
                      <div className="badge">
                        {topPostSelectedDate}
                        <div
                          onClick={() => setTopPostSelectedDate("")}
                          className="close_icon"
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1">
                <div className="col_span_2 section_card_img_wrapper">
                  {postImpressionsTopPostImg}
                </div>
                <div className="col_span_2">
                  <div className="grid col_span_2">
                    <div className="table_wrapper section_card_table_wrapper">
                      {postImpressionsDetailsData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div
        id="video_views_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Views {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Video Views"
                module="social"
                platform="facebook"
                section="paid"
              />
            </div>
            <Modal
              open={isVideoViewsOpen}
              setOpen={setIsVideoViewsOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={
                    "social_facebook_current_period_2" //chart2 id
                  }
                  sectionName={"DateFirstNonActiveDropdownLabels_Video Views"}
                  sectionData={combineCSV(videoViews)}
                />
              }
              parentId={"video_views_section"} // div section id
              title={`Video Views  ${selectedHashtagSectionHeader ? ` : ${selectedHashtagSectionHeader}` : ''}`}
              chart={videoViewsChart2} //chart2 data
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && (
                <button
                  onClick={() => setIsVideoViewsOpen(true)}
                  className="icon_btn btn-small-secondary expand_btn"
                >
                  <ExpandIcon />
                </button>
              )}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views_section"}
                sectionName={"Video Views"}
                sectionData={combineCSV(videoViews)}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
      </div>
      <div
        id="post_engagement_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Post Engagement {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Post Engagement"
                module="social"
                platform="facebook"
                section="paid"
              />
            </div>
            <Modal
              open={isPostEngagementOpen}
              setOpen={setIsPostEngagementOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  subTab={pageSecondaryTab}
                  sectionId={"post_engagement_2"}
                  sectionName={"Post Engagement"}
                  sectionData={postEngagementCSV}
                />}
              parentId={"post_engagement_section"}
              title={` Post Engagement ${selectedHashtagSectionHeader}`}
              chart={postEnagagementsChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPostEngagementOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"post_engagement_section"}
                sectionName={"Post Engagement"}
                sectionData={postEngagementCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postEnagagementsChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              {postEnagagementsDetailsData}
            </div>
          </div>
        </div>
      </div>
      {topPostsCompareFeat}
    </Wrapper>
  );
};

export default SocialFacebookPostPaid;
