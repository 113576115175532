import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import {
  DonutChartColorSet,
  PieOfPieSeriesColorSet,
} from "../../../constants/constants";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../constants/countryCurrency";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const DonutPieChart = ({
  chartId,
  chartClass,
  varient,
  chartData,
  onSliceClick,
  defaultValue,
  chartColorSet,
}) => {
  const userData = useSelector((state) => state.userData.user);
  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}
  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })


  let seriesParams = {};

  if ("childLabel" in chartData[0]) {
    seriesParams =
      "[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {value}\n[bold]{childLabel}[/]: {formattedValue}";
  } else {
    seriesParams =
      `[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {formattedValue}`;
  }

  const formatDataValues = (data) => {
    return data.map(item => ({
      ...item,
      formattedValue: tooltipNumberFormatting(item.label, item.value, country),
    }));
  }

  useLayoutEffect(() => {
    let root = am5.Root.new(chartId);

    // to format numbers
    // root.numberFormatter.setAll({
    //   numberFormat: "#.##a",

    //   // Group only into M (millions), and B (billions)
    //   bigNumberPrefixes: [
    //     { number: 1e3, suffix: "K" },
    //     { number: 1e6, suffix: "M" },
    //     { number: 1e9, suffix: "B" },
    //   ],

    //   // Do not use small number prefixes at all
    //   smallNumberPrefixes: [],
    // });

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    if (chartColorSet === undefined) {
      var colorSet = DonutChartColorSet;
    } else {
      var colorSet = chartColorSet;
    }

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: varient ? 180 : -90,
        endAngle: varient ? 360 : 270,
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // The data
    // var data = chartData
    var data = formatDataValues(chartData);

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        startAngle: varient ? 180 : -90,
        endAngle: varient ? 360 : 270,
        name: "Reaction",
        valueField: "value",
        categoryField: "label",
        childLabel: "childLabel",
        childValue: "childValue",
        alignLabels: true,
      })
    );


    if (onSliceClick) {
      series.slices.template._settings.cursorOverStyle = "pointer"
    }

    series.slices.template.states.create("active", {
      shiftRadius: 20,
      stroke: am5.color(0xffffff),
      strokeWidth: 0,
    });

    {
      !onSliceClick && series.slices.template.set("toggleKey", "none");
    }
    var tempArr = [];
    var oneSlicePulled = [];
    var foobar = [];
    let id = "";

    {
      onSliceClick &&
        series.slices.template.events.on("click", function (ev) {

          if (tempArr.includes(ev.target.dataItem.dataContext)) {
            tempArr = [];
            onSliceClick(defaultValue !== undefined ? ev.target.dataItem.dataContext : {});
          } else {
            tempArr = [];
            onSliceClick(ev.target.dataItem.dataContext);
            tempArr.push(ev.target.dataItem.dataContext);
          }


          series.slices.each(function (slice) {
            if (defaultValue) {
              setTimeout(() => {
                id = ev.target.uid;
                if (slice.uid === id) {
                  slice.set("active", true);
                  // slice.set("interactive", false);
                } else {
                  if (slice != ev.target) {
                    slice.set("active", false);
                    slice.set("interactive", true);
                  }
                }
              }, 50);
            } else {
              if (slice != ev.target) {
                slice.set("active", false);
              }
            }
          });
        });
    }

    {
      defaultValue &&
        series.slices.template.events.on("dataitemchanged", function (ev) {
          series.slices.each(function (slice) {
            if (slice.dataItem.dataContext.label == defaultValue) {
              setTimeout(() => {
                slice.set("active", true);
                // slice.set("interactive", false);
              }, 500);
            }
          });
        });
    }

    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180,
    });

    series.ticks.template.setAll({
      forceHidden: true,
    });
    series.labels.template.set("forceHidden", true);

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    series.get("colors").set("colors", colorSet);

    series.data.setAll(data);

    var tooltip = series.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      // maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    series.slices.template.set("tooltipText", seriesParams);
    // series.slices.template.set("tooltipText", "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({formattedValue})");

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        position: "relative", // options: "absolute" / "relative"
        centerX: am5.percent(50),
        x: am5.percent(50),
        height: chartData.length >= 6 ? am5.percent(30) : null,
        marginTop: 15,
        // marginBottom: 15,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      oversizedBehavior: "wrap",
    });

    // TO HIDE LABEL VALUES FROM LEGEND
    legend.valueLabels.template.set("forceHidden", true);

    legend.data.setAll(series.dataItems);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <ErrorBoundary>
        <div id={chartId} className={chartClass}></div>
      </ErrorBoundary>
    </Wrapper>
  );
};

DonutPieChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  varient: PropTypes.string,
  chartData: PropTypes.array,
  onSliceClick: PropTypes.func,
  defaultValue: PropTypes.object,
  chartColorSet: PropTypes.array,
};

export default DonutPieChart;
