import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Wrapper from "../../helpers/Wrapper";
import NumberFormatter from "../../../utils/NumberFormatter";
import { PieOfPieSeriesColorSet, PieOfPieSeriesLegendColorSet, PieOfPieSubSeriesColorSet, PieOfPieSubSeriesLegendColorSet, Primary400, Primary500, Primary600, Primary700, SecondaryGreen400, SecondaryGreen500, SecondaryGreen600, SecondaryGreen700, SecondaryPurple400, SecondaryPurple500, SecondaryPurple600, SecondaryPurple700, SecondaryYellow400, SecondaryYellow500, SecondaryYellow600, SecondaryYellow700 } from "../../../constants/constants";
import { tooltipNumberFormatting } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../constants/countryCurrency";

am5.addLicense("AM5C258896422");

const PieofaPieChart = ({
    chartId,
    chartClass,
    chartData,
    chartLabels,
    chartSeriesColorSet,
    chartSubSeriesColorSet
}) => {
    // USER DATA
    const userData = useSelector((state) => state.userData.user);
    let userCountryCode = userData?.country_code ?? "IN"

    let country = {}

    CountryCode.map((data) => {
        if (data.countryCode === userCountryCode) {
            country = data
        }
    })

    const [activeSubData, setActiveSubData] = useState(chartData[0].label)

    const onClickHandler = (data) => {
        setActiveSubData(data.dataItem.dataContext.label)
    }

    const formatDataValues = (data) => {
        return data.map(item => {
            const formattedSubData = item.subData.map(subItem => ({
                ...subItem,
                formattedValue: tooltipNumberFormatting(subItem.label, subItem.value, country),
            }));

            return {
                ...item,
                subData: formattedSubData,
                formattedValue: tooltipNumberFormatting(item.label, item.value, country),
            };
        });
    };


    useLayoutEffect(() => {

        var root = am5.Root.new(chartId);

        // to format numbers
        // root.numberFormatter.setAll({
        //     numberFormat: "#.##a",

        //     // Group only into M (millions), and B (billions)
        //     bigNumberPrefixes: [
        //         { number: 1e3, suffix: "K" },
        //         { number: 1e6, suffix: "M" },
        //         { number: 1e9, suffix: "B" },
        //     ],

        //     // Do not use small number prefixes at all
        //     smallNumberPrefixes: [],
        // });

        let seriesColorSet;
        let subSeriesColorSet;

        if (chartSeriesColorSet === undefined || chartSubSeriesColorSet === undefined) {
            seriesColorSet = PieOfPieSeriesColorSet;
            subSeriesColorSet = PieOfPieSubSeriesColorSet
        } else {
            seriesColorSet = chartSeriesColorSet
            subSeriesColorSet = chartSubSeriesColorSet
        }

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        var container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                layout: root.horizontalLayout
            })
        );

        // Create main chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
                tooltip: am5.Tooltip.new(root, {})
            })
        );
        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "label",
                alignLabels: false
            })
        );

        // Disabling labels and ticks
        series.labels.template.setAll({
            forceHidden: true
        });


        // Disabling labels and ticks
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        // The data
        // var data = chartData;
        var data = formatDataValues(chartData);

        series.get("colors").set("colors", [
            am5.color(SecondaryPurple400),
            am5.color(SecondaryGreen400),
            am5.color(SecondaryYellow400),
            am5.color(Primary400),
            am5.color(SecondaryPurple500),
            am5.color(SecondaryGreen500),
            am5.color(SecondaryYellow500),
            am5.color(Primary500),
            am5.color(SecondaryPurple600),
            am5.color(SecondaryGreen600),
            am5.color(SecondaryYellow600),
            am5.color(Primary600),
            am5.color(SecondaryPurple700),
            am5.color(SecondaryGreen700),
            am5.color(SecondaryYellow700),
            am5.color(Primary700),
        ]);

        series.ticks.template.set("visible", false);
        series.slices.template.set("toggleKey", "none");

        // add events
        series.slices.template.events.on("click", function (e) {
            selectSlice(e.target);
            onClickHandler(e.target);
            // updateVar(e.target.dataItem.dataContext.subData);
        });

        var tooltip = series.set(
            "tooltip",
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                getStrokeFromSprite: false,
                autoTextColor: false,
                pointerOrientation: "horizontal",
                position: "pointer",
            })
        );

        tooltip.get("background").setAll({
            fill: am5.color(0x213345),
            maxWidth: "auto",
            oversizedBehavior: "truncate",
            cornerRadius: 5,
        });

        series.slices.template.set("tooltipText", "[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {formattedValue}");

        tooltip.label.setAll({
            fill: am5.color(0xffffff),
            fontSize: 13,
        });

        // Create sub chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var subChart = container.children.push(
            am5percent.PieChart.new(root, {
                radius: am5.percent(50),
                tooltip: am5.Tooltip.new(root, {}),
                layout: root.verticalLayout,
            })
        );

        // Create sub series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var subSeries = subChart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "label"
            })
        );

        // Disabling labels and ticks
        subSeries.labels.template.setAll({
            forceHidden: true
        });

        subSeries.ticks.template.setAll({
            forceHidden: true
        });

        subSeries.get("colors").set("colors", [
            am5.color(Primary400),
            am5.color(SecondaryYellow400),
            am5.color(SecondaryGreen400),
            am5.color(SecondaryPurple400),
            am5.color(Primary500),
            am5.color(SecondaryYellow500),
            am5.color(SecondaryGreen500),
            am5.color(SecondaryPurple500),
            am5.color(Primary600),
            am5.color(SecondaryYellow600),
            am5.color(SecondaryGreen600),
            am5.color(SecondaryPurple600),
            am5.color(Primary700),
            am5.color(SecondaryYellow700),
            am5.color(SecondaryGreen700),
            am5.color(SecondaryPurple700),
        ]);

        subSeries.data.setAll([
            { label: "A", value: 0 },
            { label: "B", value: 0 },
            { label: "C", value: 0 },
            { label: "D", value: 0 },
            { label: "E", value: 0 },
            { label: "F", value: 0 },
            { label: "G", value: 0 },
            { label: "H", value: 0 },
            { label: "I", value: 0 },
            { label: "J", value: 0 },
            { label: "K", value: 0 },
            { label: "L", value: 0 },
            { label: "M", value: 0 },
            { label: "N", value: 0 },
            { label: "O", value: 0 },
            { label: "P", value: 0 },
            { label: "Q", value: 0 },
            { label: "R", value: 0 },
            { label: "S", value: 0 },
            { label: "T", value: 0 },
            { label: "U", value: 0 },
            { label: "V", value: 0 },
            { label: "W", value: 0 },
            { label: "X", value: 0 },
            { label: "Y", value: 0 },
            { label: "Z", value: 0 }
        ]);

        subSeries.slices.template.set("toggleKey", "none");

        var selectedSlice;

        series.on("startAngle", function () {
            updateLines();
        });

        container.events.on("boundschanged", function () {
            root.events.on("frameended", function () {
                updateLines();
            })
        })

        function updateLines() {
            if (selectedSlice) {
                var startAngle = selectedSlice.get("startAngle");
                var arc = selectedSlice.get("arc");
                var radius = selectedSlice.get("radius");

                var x00 = radius * am5.math.cos(startAngle);
                var y00 = radius * am5.math.sin(startAngle);

                var x10 = radius * am5.math.cos(startAngle + arc);
                var y10 = radius * am5.math.sin(startAngle + arc);

                var subRadius = subSeries.slices.getIndex(0).get("radius");
                var x01 = 0;
                var y01 = -subRadius;

                var x11 = 0;
                var y11 = subRadius;

                var point00 = series.toGlobal({ x: x00, y: y00 });
                var point10 = series.toGlobal({ x: x10, y: y10 });

                var point01 = subSeries.toGlobal({ x: x01, y: y01 });
                var point11 = subSeries.toGlobal({ x: x11, y: y11 });

                line0.set("points", [point00, point01]);
                line1.set("points", [point10, point11]);
            }
        }

        // lines
        var line0 = container.children.push(
            am5.Line.new(root, {
                position: "absolute",
                stroke: root.interfaceColors.set("grid", am5.color(0xbacad6)),
                strokeDasharray: [2, 2]
            })
        );

        var line1 = container.children.push(
            am5.Line.new(root, {
                position: "absolute",
                stroke: root.interfaceColors.set("grid", am5.color(0xbacad6)),
                strokeDasharray: [2, 2]
            })
        );

        var tooltip = subSeries.set(
            "tooltip",
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                getStrokeFromSprite: false,
                autoTextColor: false,
                pointerOrientation: "horizontal",
                position: "pointer",
            })
        );

        tooltip.get("background").setAll({
            fill: am5.color(0x213345),
            maxWidth: 200,
            oversizedBehavior: "truncate",
            cornerRadius: 5,
        });

        subSeries.slices.template.set("tooltipText", "[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {formattedValue}");

        tooltip.label.setAll({
            fill: am5.color(0xffffff),
            fontSize: 13,
        });

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.data.setAll(data);

        function selectSlice(slice) {
            selectedSlice = slice;
            var dataItem = slice.dataItem;
            var dataContext = dataItem.dataContext;

            if (dataContext) {
                var i = 0;
                subSeries.data.each(function (dataObject) {
                    var dataObj = dataContext.subData[i];
                    if (dataObj) {
                        subSeries.data.setIndex(i, dataObj);
                        if (!subSeries.dataItems[i].get("visible")) {
                            subSeries.dataItems[i].show();
                        }
                    }
                    else {
                        subSeries.dataItems[i].hide();
                    }
                    i++;
                });
            }

            var middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
            var firstAngle = series.dataItems[0].get("slice").get("startAngle");

            series.animate({
                key: "startAngle",
                to: firstAngle - middleAngle,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic)
            });
            series.animate({
                key: "endAngle",
                to: firstAngle - middleAngle + 360,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic)
            });
        }

        container.appear(1000, 10);

        series.events.on("datavalidated", function () {
            selectSlice(series.slices.getIndex(0));
            // updateVar(series.slices.getIndex(0).dataItem.dataContext.subData)
            // subSeries.data.setAll(series.slices.getIndex(0).dataItem.dataContext.subData);
        });

        // PARENT PIE LEGENDS
        var legend = chart.children.push(
            am5.Legend.new(root, {
                nameField: "categoryX",
                position: "relative", // options: "absolute" / "relative"
                centerX: am5.percent(50),
                x: am5.percent(50),
                // height: chartLabels.length >= 5 ? am5.percent(30) : null,
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical"
                })
            })
        );

        legend.markers.template.setAll({
            width: 13,
            height: 13,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
        });

        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
        });

        legend.labels.template.setAll({
            maxWidth: 150,
            //centerY: 0, // if we want labels to be top-aligned
            oversizedBehavior: "wrap",
        });

        // TO HIDE LABEL VALUES FROM LEGEND 
        legend.valueLabels.template.set("forceHidden", true);

        // legend.data.setAll(series.dataItems);

        // CHILD PIE LEGENDS
        var subLegend = subChart.children.push(
            am5.Legend.new(root, {
                position: "relative", // options: "absolute" / "relative"
                centerX: am5.percent(50),
                x: am5.percent(50),
                height: am5.percent(30),
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical"
                })
            })
        );

        subLegend.markers.template.setAll({
            width: 13,
            height: 13,
        });

        subLegend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
        });

        subLegend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
        });

        subLegend.labels.template.setAll({
            // maxWidth: 150,
            //centerY: 0, // if we want labels to be top-aligned
            oversizedBehavior: "wrap",
        });

        // TO HIDE LABEL VALUES FROM LEGEND 
        // subLegend.valueLabels.template.set("forceHidden", true);
        // subLegend.data.setAll(subSeries.dataItems);


        return () => {
            root.dispose();
        };
    }, [chartData]);

    const ConvertToPercentage = (allData, value) => {

        let temp = 0;
        let allCount = 0


        allData.map(data => (
            allCount = allCount + data.value
        ))

        temp = +Math.abs((value / allCount) * 100).toFixed(2)

        return isNaN(temp) === true ? 0 : temp;
    }

    return (
        <Wrapper>
            <div id={chartId} className={"max_chart_height " + chartClass}></div>
            <div className="grid grid-col-2">
                <div className="grid grid-span-1">
                    <div className="legends_wrapper">
                        {chartData.map((data, dataIndex) => {
                            return (
                                <div key={dataIndex} className="legend">
                                    <div className="legend_circle" style={{ backgroundColor: PieOfPieSeriesLegendColorSet[dataIndex] }}></div>
                                    <div className="legend_title">{data.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="grid grid-span-1">
                    {chartData.map((data, dataIndex) => {
                        if (data.label === activeSubData) {
                            return (
                                <div key={dataIndex} className="legends_wrapper">
                                    {data.subData.map((sub, subIndex) => (
                                        <Wrapper key={subIndex}>
                                            <div className="legend">
                                                <div className="legend_circle" style={{ backgroundColor: PieOfPieSubSeriesLegendColorSet[subIndex] }}></div>
                                                <div className="legend_title">{sub.label}</div>
                                                <div className="legend_value"><NumberFormatter number={sub.value} /></div>
                                                {/* <div className="legend_value">{ConvertToPercentage(data.subData, sub.value)}</div> */}
                                            </div>
                                        </Wrapper>
                                    ))}
                                </div>

                            )
                        }
                    })}
                </div>
            </div>
        </Wrapper>
    );
};

PieofaPieChart.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    showLegend: PropTypes.bool,
    showLabels: PropTypes.bool,
    showVerticalLabels: PropTypes.bool,
    chartData: PropTypes.array,
    // chartLabels: PropTypes.array,
    chartSeriesColorSet: PropTypes.array,
    chartSubSeriesColorSet: PropTypes.array
};

export default PieofaPieChart;
