import { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
    OTPRegex,
    InvalidOTP,
    EmailRegex,
    InvalidEmailMsg,
    RequiredField,
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";
import useApi from "../../hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/userSlice";
import { setSidebarData } from "../../store/sidebarSlice";
import { setMultiLineCluster, setShowAllLegendsInMultiLineCluster } from "../../store/multiLineClusteredSlice";

const Verify2FAForm = () => {
    const API_URL = process.env.REACT_APP_API_AUTH_URL;

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const authCtx = useContext(AuthContext);

    const userID = useSelector((state) => state.userData.userId);
    const userQR = useSelector((state) => state.userData.userQR);

    const verifyOTPAPI = useApi();

    const otpInputRef = useRef();

    const [formErrors, setFormErrors] = useState();
    const [formData, setFormData] = useState({
        otp: ""
    });

    const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(false);

    const [otpIsTouched, setOTPIsTouched] = useState(false);
    const [otpIsValid, setOTPIsValid] = useState(false);
    const [otpError, setOTPError] = useState();

    const otpInputIsInvalid = !otpIsValid && otpIsTouched;
    const otpInputClasses = otpInputIsInvalid
        ? "form_control invalid"
        : "form_control";

    const otpChangeHandler = () => {
        setOTPIsTouched(true);

        if (!OTPRegex.test(otpInputRef.current.value)) {
            setOTPError(InvalidOTP);
            setOTPIsValid(false);
            setFormData({
                ...formData,
                otp: otpInputRef.current.value,
            });
        } else {
            setOTPError();
            setOTPIsValid(true);
            setFormData({
                ...formData,
                otp: otpInputRef.current.value,
            });
        }
    };

    const validateOTPHandler = () => {
        setOTPIsTouched(true);
        if (otpInputRef.current.value === "") {
            setOTPIsValid(false);
            setOTPError(RequiredField);
        }
    };

    const formHandler = () => {
        const inputData = {
            user_id: userID,
            token: formData.otp
        };

        verifyOTPAPI.postData(`${API_URL}auth/login/verify-mfa/`, inputData, "Login Successfull !", "Something Went Wrong Please Try Again");
    };

    useEffect(() => {
        if (!verifyOTPAPI.apiStatus.isLoading && verifyOTPAPI.apiStatus.isLoaded && verifyOTPAPI.apiStatus.error) {
            setFormErrors(verifyOTPAPI.apiStatus.error.message);
        } else {
            if (!verifyOTPAPI.apiStatus.isLoading && verifyOTPAPI.apiStatus.isLoaded && !verifyOTPAPI.apiStatus.error) {
                setFormData({
                    ...formData,
                    otp: "",
                });

                dispatch(setUserData(verifyOTPAPI.data.user_data)); // USER DATA
                dispatch(setSidebarData(verifyOTPAPI.data.side_bar)); // SIDEBAR DATA

                dispatch(setMultiLineCluster(true))

                // SHOW ALL MULTILINE CLUSTER CHART LEGENDS OR NOT
                dispatch(setShowAllLegendsInMultiLineCluster(true))

                // TO ADD AUTO LOGOUT FROM API RESPONSE
                const expirationTime = new Date(new Date().getTime() + 14400 * 1000); // TO ADD AUTO LOGOUT IN SECONDS 1 SECOND = 1000
                authCtx.login(verifyOTPAPI.data.access, expirationTime.toISOString());

                if (location.state?.from) {
                    navigate(location.state.from);
                } else {
                    navigate("/");
                }

            } else {
                setFormErrors(null);
            }
        }
    }, [verifyOTPAPI.apiStatus])

    useEffect(() => {
        (formData.otp !== "" && otpIsValid)
            ? setCurrentFieldsValidated(false)
            : setCurrentFieldsValidated(true);
    }, [formData]);

    return (
        <Wrapper>
            <div className="form_wrapper">
                <div className="form_header flex column align_center p_10">
                    <div className="form_header_logo">
                        <img className="logo" src={IcogzLogo} alt="logo" />
                    </div>
                </div>
                <div className="flex row justify_center align_center grid grid_cols_4 grid_center">
                    <div className="grid col_span_2 flex column justify_center align_center" style={{ "borderRight": "1px solid #333" }}>
                        <div className="p_10 flex justify_center">
                            <h4 className="form_subtitle">Scan the QR code</h4>
                        </div>
                        <div className="">
                            <img src={userQR} style={{ "width": "200px" }} />
                        </div>
                    </div>
                    <div className="grid col_span_2 flex column">
                        <div className="p_10 flex justify_center">
                            <h4 className="form_subtitle">We have sent an OTP on your registered email</h4>
                        </div>
                        <div className="p_10 flex justify_center">
                            <h6 className="error_text">{formErrors}</h6>
                        </div>
                        <form className="form" action="">
                            <div className="form_group">
                                <input
                                    className={otpInputClasses}
                                    type="number"
                                    name="otp"
                                    id="otp"
                                    autoComplete="off"
                                    placeholder="Please Enter OTP"
                                    value={formData.otp}
                                    onChange={otpChangeHandler}
                                    onBlur={validateOTPHandler}
                                    ref={otpInputRef}
                                />
                                <div className="error_section">
                                    {!otpIsValid && (
                                        <h6 className="error_text">{otpError}</h6>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify_between p_10">
                                <Link to={"/login"}>
                                    <div className="help_text">Login with password</div>
                                </Link>
                                <Link to={"/register"}>
                                    <div className="help_text">Create new account</div>
                                </Link>
                            </div>
                            <div className="flex justify_between">
                                <div className="btn_wrapper btn_group_wrapper">
                                    <Button
                                        handleClick={formHandler}
                                        disabled={isCurrentFieldsValidated}
                                        className="primary_btn"
                                        type="button"
                                    >
                                        {!verifyOTPAPI.apiStatus.isLoading ? "Verify OTP" : "Verifying OTP"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Verify2FAForm;