import PropTypes from "prop-types";
import CommentsIcon from "../../assets/icons/CommentsIcon";
import LikeIcon from "../../assets/icons/LikeIcon";
import RetweetIcon from "../../assets/icons/RetweetIcon";
import {
  defaultSocialIconsUrls,
  DefaultVerifiedIcon,
  defaultWhiteSocialIconsUrls,
  DefaultPostImage,
  DefaultTwitterHeaderImg,
  HALEON_COMPANY_ID,
} from "../../constants/constants";

import GrowthConverter from "../../utils/GrowthConverter";

import TextFormatter from "../../utils/TextFormatter";
import Wrapper from "../helpers/Wrapper";
import Button from "./Button";

import LineChart from "./charts/LineChart";
import HorizontalDivider from "./HorizontalDivider";
import ProgressBar from "./ProgressBar";

import FacebookIcon from "../../assets/images/social/facebook.png";
import InstagramIcon from "../../assets/images/social/instagram.png";
import TwitterIcon from "../../assets/images/social/twitter.png";
import LinkedinIcon from "../../assets/images/social/linkedin.png";
import GoogleIcon from "../../assets/images/social/google.png";
import DV360Icon from "../../assets/images/social/dv360.png";
import BINGIcon from "../../assets/images/social/bing.png";
import Badge from "./Badge";
import { DefaultCardDefinations, HaleonCardDefinations } from "../../constants/definations";
import { useSelector } from "react-redux";

import NumberFormatter from "../../utils/NumberFormatter";
import PrivateIcon from "../../assets/icons/PrivateIcon";
import PublicIcon from "../../assets/icons/PublicIcon";
import UnlistedIcon from "../../assets/icons/UnlistedIcon";
function Card({
  cardClass,
  variant,
  showPreviousCompare,
  showSecondRowCompare,
  cardTitle,
  cardIconLink,
  currentValue,
  previousValue,
  rowOneTitle,
  second_currentValue,
  second_previousValue,
  rowTwoTitle,
  numberVariant,
  isReversed,
  targetedValue,
  achievedValue,
  chartType,
  chartData,
  chartDataLabels,
  cardData,
  module,
  platform,
  section,
  camapaignBuckeData, // CAMPAIGN ADMIN BUCKET CARD
  onEditButtonClick, // CAMPAIGN ADMIN BUCKET CARD
  onDeleteButtonClick, // CAMPAIGN ADMIN BUCKET CARD
}) {

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData?.company?.id ?? "";

  let CardDefinations = [];

  if (companyID === HALEON_COMPANY_ID) {
    CardDefinations = HaleonCardDefinations
  } else {
    CardDefinations = DefaultCardDefinations
  }

  let CardPlatform;
  if (platform === undefined) {
    CardPlatform = cardTitle;
  } else {
    CardPlatform = platform;
  }


  if (module === undefined) {
    module = "overall"
  }

  if (platform === undefined) {
    platform = "overall"
  }

  if (section === undefined) {
    section = "overall"
  }

  // TO CREATE DYANAMIC CHART ID
  let dynamicChartId = "";
  if (chartType) {
    dynamicChartId = cardTitle.replace(/ /g, "_") + "_" + chartType;
  }
  const CardClasses = "card " + cardClass;

  // IF CARD TITLE IS NO AVAILABLE
  const cardTitleErros = [null, undefined, "", 0];
  if (cardTitleErros.includes(cardTitle)) {
    cardTitle = "Default Title";
  }

  // PERCENTAGE METRICS ARRAY
  const percentageMetrics = [
    "% New Sessions",
    "Bounce Rate",
    "Goal Conversion Rate",
    "CTR",
    "Engagement Rate",
  ];
  if (percentageMetrics.includes(cardTitle)) {
    numberVariant = "percentage";
  }

  // LIQUID METRICS ARRAY
  const liquidMetrics = ["Sales Volume"];
  if (liquidMetrics.includes(cardTitle)) {
    numberVariant = "liquid";
  }

  // CURRENCY METRICS ARRAY
  const currencyMetrics = ["Spends", "Total Spends", "Total Spend", "Spend"];
  if (currencyMetrics.includes(cardTitle)) {
    numberVariant = "money";
  }

  // TO CHANGE THE GROWTH RATE INVERSED
  const isReversedMetrics = ["bounce rate", "bounce rate (%)", "bounce rate(%)", "cpc", "cpm", "cost per video view", "skips video", "cpv"];
  if (isReversedMetrics.includes(cardTitle.toLowerCase())) {
    isReversed = true;
  }

  // SHOW INFO ON TOOLTIP HOVER
  let hoverDetails = "";
  CardDefinations.find((element) => {
    if (element.title.toLowerCase() === cardTitle.toLowerCase() && element.module.toLowerCase() === module.toLowerCase() && element.platform.toLowerCase() === platform.toLowerCase() && element.section.toLowerCase() === section.toLowerCase()) {
      return (hoverDetails = element.data);
    } else {
      return hoverDetails = ""
    }
  });

  // SHOW DEFAULT SOCIAL ICONS
  const socialIconUrls = defaultSocialIconsUrls;
  let cardIcon;
  if (cardIconLink !== undefined) {
    if (
      typeof cardIconLink !== "string" ||
      cardIconLink === "" ||
      cardIconLink === " " ||
      cardIconLink === undefined
    ) {
      cardIcon = DefaultPostImage;
    } else {
      cardIcon = cardIconLink;
    }
  } else {
    socialIconUrls.find((element) => {
      if (element.title.toLowerCase() === cardTitle.toLowerCase()) {
        return (cardIcon = element.iconUrl);
      }
    });
  }

  // SHOW WHITE SOCIAL ICONS ON VIDEO THUMBNAILS
  const whiteSocialIconUrls = defaultWhiteSocialIconsUrls;
  let videoPlatformIcon;
  if (variant === "video_thumbnail") {
    cardData.videoPlatform &&
      whiteSocialIconUrls.find((element) => {
        if (element.title.toLowerCase() === cardData.videoPlatform.toLowerCase()) {
          return (videoPlatformIcon = element.iconUrl);
        }
      });
  }

  if (showPreviousCompare === undefined) {
    showPreviousCompare = true;
  }

  if (showSecondRowCompare === undefined) {
    showSecondRowCompare = true;
  }


  // SKELETON LOADER FOR NO VARIENT CARD
  let noVariantSingleSkeletonLoaderCard;
  if (currentValue === "loading" && previousValue === "loading") {
    noVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} alt="icon" />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper skeleton_card_body_wrapper">
          <div className="card_body">
            <div className="card_body_row">
              <div className="curr_value title_skeleton_loader_wrapper">
                <div className="title_skeleton_loader"></div>
              </div>
              <HorizontalDivider />
              <div className="prev_value title_skeleton_loader_wrapper">
                <div className="title_skeleton_loader"></div>
              </div>
              <div className="value_title title_skeleton_loader_wrapper">
                <div className="title_skeleton_loader"></div>
              </div>
            </div>
          </div>
          <div className="card_footer">
            <div className="growth_row">
              <div className="subtitle_skeleton_loader_wrapper">
                <div className="subtitle_skeleton_loader"></div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
  } else if (currentValue === "error" && previousValue === "error") {
    noVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} alt="icon" />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper error_card_body_wrapper">
          <div className="info_wrapper">
            <p className="info1">Data Unavailable</p>
            <p>We are unable to retrieve all of your data at this time</p>
          </div>
        </div>
      </Wrapper>
  } else {
    noVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} alt="icon" />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper">
          <div className="card_body">
            <div className="card_body_row">
              <div className="curr_value">
                <NumberFormatter
                  platform={CardPlatform}
                  title={cardTitle}
                  variant={numberVariant}
                  // forCountry={forCountry}
                  number={currentValue}
                />
              </div>
              <HorizontalDivider />
              <div className="prev_value">
                <NumberFormatter
                  platform={CardPlatform}
                  title={cardTitle}
                  variant={numberVariant}
                  // forCountry={forCountry}
                  number={previousValue}
                />
              </div>
              <div className="value_title">{rowOneTitle}</div>
            </div>
          </div>
          <div className="card_footer">
            <div className="growth_row">
              <GrowthConverter
                currentValue={currentValue}
                previousValue={previousValue}
                isReversed={isReversed}
              />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // SKELETON LOADER FOR NO VARIENT CARD
  let compareVariantSingleSkeletonLoaderCard;

  // WHEN ALL VALUES ARE IN LOADING STATE
  if (currentValue === "loading" && previousValue === "loading" && second_currentValue === "loading" && second_previousValue === "loading") {
    compareVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} alt="icon" />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper skeleton_card_body_wrapper compare">
          <div className="card_body">
            <div className="card_body_row">
              <div className="value_row skeleton">
              </div>
              <div className="title_row skeleton">
              </div>
              <Wrapper>
                <HorizontalDivider />
                <div className="value_row skeleton">
                </div>
                <div className="title_row skeleton">
                </div>
              </Wrapper>
            </div>
          </div>
          <div className="card_footer"></div>
        </div>
      </Wrapper>
  }
  // WHEN ALL VALUES ARE IN ERROR STATE
  else if (currentValue === "error" && previousValue === "error" && second_currentValue === "error" && second_previousValue === "error") {
    compareVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} alt="icon" />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper error_card_body_wrapper">
          <div className="info_wrapper">
            <p className="info1">Data Unavailable</p>
            <p>We are unable to retrieve all of your data at this time</p>
          </div>
        </div>
      </Wrapper>
  }
  // WHEN DATA IS LOADED
  else {
    compareVariantSingleSkeletonLoaderCard =
      <Wrapper>
        <div className="card_header">
          <div className="card_title_wrapper">
            {cardIcon && (
              <div className="card_icon">
                <img src={cardIcon} />
              </div>
            )}
            <div className="card_header_title">
              <TextFormatter string={cardTitle} />
            </div>
          </div>
          {hoverDetails !== "" && (
            <div className="hover_badge_wrapper">
              <Badge hoverDetails={hoverDetails} />
            </div>
          )}
        </div>
        <div className="card_body_wrapper skeleton_card_body_wrapper compare">
          <div className="card_body">
            <div className="card_body_row">
              {currentValue === "loading" || previousValue === "loading" ?
                <Wrapper>
                  {/* WHEN ONLY CURRENT & PREVIOUS VALUES ARE LOADING */}
                  <div className="value_row skeleton"></div>
                  <div className="title_row skeleton"></div>
                </Wrapper>
                :
                currentValue === "error" || previousValue === "error" ?
                  <Wrapper>
                    {/* WHEN ONLY CURRENT & PREVIOUS VALUES HAVE ERROR */}
                    <div className="info_wrapper">
                      <p className="info1">Data Unavailable</p>
                      {/* <p>We are unable to retrieve all of your data at this time</p> */}
                    </div>
                  </Wrapper>
                  :
                  <Wrapper>
                    {/* WHEN ONLY CURRENT & PREVIOUS VALUES ARE LOADED */}
                    <div className="value_row">
                      <div className="curr_value">
                        <NumberFormatter
                          platform={CardPlatform}
                          title={rowOneTitle}
                          variant={numberVariant}
                          // forCountry={forCountry}
                          number={currentValue}
                        />
                      </div>
                      {showPreviousCompare === true && (
                        <div className="prev_value">
                          /
                          <NumberFormatter
                            platform={CardPlatform}
                            title={rowOneTitle}
                            variant={numberVariant}
                            // forCountry={forCountry}
                            number={previousValue}
                          />
                        </div>
                      )}
                    </div>
                    <div className="title_row">
                      <div className="value_title">
                        <TextFormatter string={rowOneTitle} />
                      </div>
                    </div>
                  </Wrapper>
              }
              {showSecondRowCompare !== false && (
                second_currentValue === "loading" || second_previousValue === "loading" ?
                  <Wrapper>
                    {/* WHEN ONLY SECOND CURRENT & PREVIOUS VALUES ARE LOADING */}
                    <HorizontalDivider />
                    <div className="value_row skeleton"></div>
                    <div className="title_row skeleton"></div>
                  </Wrapper>
                  :
                  second_currentValue === "error" || second_previousValue === "error" ?
                    <Wrapper>
                      {/* WHEN ONLY SECOND CURRENT & PREVIOUS VALUES HAVE ERROR */}
                      <HorizontalDivider />
                      <div className="info_wrapper">
                        <p className="info1">Data Unavailable</p>
                        {/* <p>We are unable to retrieve all of your data at this time</p> */}
                      </div>
                    </Wrapper>
                    :
                    <Wrapper >
                      {/* WHEN ONLY SECOND CURRENT & PREVIOUS VALUES ARE LOADED */}
                      <HorizontalDivider />
                      <div className="value_row">
                        <div className="curr_value">
                          <NumberFormatter
                            platform={CardPlatform}
                            title={rowTwoTitle}
                            variant={numberVariant}
                            // forCountry={forCountry}
                            number={second_currentValue}
                          />
                        </div>
                        {showPreviousCompare && (
                          <div className="prev_value">
                            /
                            <NumberFormatter
                              platform={CardPlatform}
                              title={rowTwoTitle}
                              variant={numberVariant}
                              // forCountry={forCountry}
                              number={second_previousValue}
                            />
                          </div>
                        )}
                      </div>
                      <div className="title_row">
                        <div className="value_title">
                          <TextFormatter string={rowTwoTitle} />
                        </div>
                      </div>
                    </Wrapper>
              )}
            </div>
          </div>
          <div className="card_footer"></div>
        </div>
      </Wrapper >
  }

  return (
    <Wrapper>
      <div className={CardClasses}>
        {!variant && noVariantSingleSkeletonLoaderCard}

        {variant === "compare" && compareVariantSingleSkeletonLoaderCard}

        {
          variant === "chartCard" && (
            <Wrapper>
              <div className="card_header">
                <div className="card_title_wrapper">
                  {cardIcon && (
                    <div className="card_icon">
                      <img src={cardIcon} />
                    </div>
                  )}
                  <div className="card_header_title">
                    <TextFormatter string={cardTitle} />
                  </div>
                </div>
                {hoverDetails !== "" && (
                  <div className="hover_badge_wrapper">
                    <Badge hoverDetails={hoverDetails} />
                  </div>
                )}
              </div>
              <div className="card_body_wrapper">
                <div className="card_body">
                  <div className="card_body_row">
                    <div className="value_row">
                      <div className="curr_value">
                        <NumberFormatter
                          platform={CardPlatform}
                          title={cardTitle}
                          variant={numberVariant}
                          number={currentValue}
                        />
                      </div>
                      <div className="prev_value">
                        /
                        <NumberFormatter
                          platform={CardPlatform}
                          title={cardTitle}
                          variant={numberVariant}
                          number={previousValue}
                        />
                      </div>
                    </div>
                    {rowOneTitle && (
                      <div className="title_row">
                        <div className="value_title">
                          <TextFormatter string={rowOneTitle} />
                        </div>
                      </div>
                    )}
                    <div className="growth_row">
                      <GrowthConverter
                        currentValue={currentValue}
                        previousValue={previousValue}
                        isReversed={isReversed}
                      />
                    </div>
                    <HorizontalDivider />
                  </div>
                </div>
                <div className="card_new_footer">
                  <div className="metrics_card_chart_new_wrapper">
                    {chartType === "Line" && (
                      <LineChart
                        chartId={dynamicChartId}
                        cardTitle={cardTitle}
                        chartClass="metrics_card_chart"
                        chartData={chartData}
                        chartLabels={chartDataLabels}
                        showLegend={false}
                        showLabels={false}
                        removeMinTooltipLabelWidth={true}
                        toolTipLabelSize={10}
                        showScrollbar={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Wrapper>
          )
        }

        {
          variant === "target" && (
            <Wrapper>
              <div className="card_header">
                <div className="card_title_wrapper">
                  {cardIcon && (
                    <div className="card_icon">
                      <img src={cardIcon} />
                    </div>
                  )}
                  <div className="card_header_title">
                    <TextFormatter string={cardTitle} />
                  </div>
                </div>
                {hoverDetails !== "" && (
                  <div className="hover_badge_wrapper">
                    <Badge hoverDetails={hoverDetails} />
                  </div>
                )}
              </div>
              <div className="card_body_wrapper">
                <div className="card_progressbar">
                  <div className="progressbar_title">Target Achieved</div>
                  <div className="progressbar_wrapper">
                    <ProgressBar
                      targetedValue={targetedValue}
                      achievedValue={achievedValue}
                    />
                  </div>
                </div>
                <div className="card_body">
                  <div className="card_body_row">
                    <div className="value_row">
                      <div className="curr_value">
                        <NumberFormatter
                          platform={CardPlatform}
                          title={rowOneTitle}
                          variant={numberVariant}
                          // forCountry={forCountry}
                          number={currentValue}
                        />
                      </div>
                      <div className="prev_value">
                        /
                        <NumberFormatter
                          platform={CardPlatform}
                          title={rowOneTitle}
                          variant={numberVariant}
                          // forCountry={forCountry}
                          number={previousValue}
                        />
                      </div>
                    </div>
                    <div className="title_row">
                      <div className="value_title">
                        <TextFormatter string={rowOneTitle} />
                      </div>
                    </div>
                    <div className="growth_row">
                      <GrowthConverter
                        currentValue={currentValue}
                        previousValue={previousValue}
                        isReversed={isReversed}
                      />
                    </div>
                  </div>
                </div>
                <div className="card_footer">
                  <div className="metrics_card_chart_wrapper">
                    {chartType === "Line" && (
                      <LineChart
                        chartId={dynamicChartId}
                        chartClass="metrics_card_chart"
                        chartData={chartData}
                        chartLabels={chartDataLabels}
                        showLegend={false}
                        showLabels={false}
                        tooltipWidth={150}
                        removeMinTooltipLabelWidth={true}
                        showScrollbar={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Wrapper>
          )
        }

        {
          variant === "twitter_top_mentions" && (
            <Wrapper>
              <div className="header">
                <div className="profileImgWrapper">
                  <img
                    className="profileImg"
                    src={
                      cardData.profile_image_url === undefined || ""
                        ? DefaultPostImage
                        : cardData.profile_image_url
                    }
                    alt=""
                    onError={(e) => (e.target.src = DefaultPostImage)}
                  />
                </div>
                <div className="titleWrapper">
                  <div className="name">
                    {cardData.name}
                    {cardData.isVerified === true && (
                      <div className="verifiedIconWrapper">
                        <img className="verifiedIcon" src={DefaultVerifiedIcon} />
                      </div>
                    )}
                  </div>
                  <div className="username">{cardData.username}</div>
                </div>
              </div>
              <div className="body">
                {cardData.tweet.length > 140
                  ? cardData.tweet.substring(0, 140) + "..."
                  : cardData.tweet}
              </div>
              <div className="footer">
                <div className="timestamp">{cardData.timestamp}</div>
                <div className="details">
                  <div className="detailsSection">
                    <CommentsIcon className={"icon"} />
                    <div className="title">{cardData.replies}</div>
                  </div>
                  <div className="detailsSection">
                    <RetweetIcon className={"icon"} />
                    <div className="title">{cardData.retweets}</div>
                  </div>
                  <div className="detailsSection">
                    <LikeIcon className={"icon"} />
                    <div className="title">{cardData.likes}</div>
                  </div>
                </div>
                <div className="footerBtnSection">
                  <div className="btn_wrapper full_width">
                    {cardData.post_link.length !== 0 ?
                      <a
                        href={cardData.post_link}
                        className="theme_button tertiary_btn"
                        target="_blank"
                      >
                        View Tweet
                      </a>
                      :
                      <div
                        className="theme_button disabled_btn"
                      >
                        View Tweet
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Wrapper >
          )
        }

        {
          variant === "twitter_top_followers" && (
            <Wrapper>
              <div className="headerWrapper">
                <div className="header">
                  <img
                    className="headerImg"
                    src={
                      cardData.image_url === undefined || ""
                        ? DefaultTwitterHeaderImg
                        : cardData.image_url
                    }
                    alt=""
                    onError={(e) => (e.target.src = DefaultPostImage)}
                  />
                </div>
                <div className="profileImgWrapper">
                  <img
                    className="profileImm"
                    src={
                      cardData.profile_image_url === undefined || ""
                        ? DefaultPostImage
                        : cardData.profile_image_url
                    }
                    alt=""
                    onError={(e) => (e.target.src = DefaultPostImage)}
                  />
                </div>
              </div>
              <div className="body">
                <div className="titleWrapper">
                  <div className="name">
                    {cardData.name}
                    {cardData.isVerified === true && (
                      <div className="verifiedIconWrapper">
                        <img className="verifiedIcon" src={DefaultVerifiedIcon} />
                      </div>
                    )}
                  </div>
                  <div className="username">{cardData.username}</div>
                </div>
              </div>
              <div className="footer">
                <div className="details">
                  <div className="detailsSection">
                    <div className="title">
                      <NumberFormatter
                        variant="number"
                        number={cardData.tweets}
                      />
                    </div>
                    <div className="subtitle">Tweets</div>
                  </div>
                  <div className="detailsSection">
                    <div className="title">
                      <NumberFormatter
                        variant="number"
                        number={cardData.followers}
                      />
                    </div>
                    <div className="subtitle">Followers</div>
                  </div>
                  <div className="detailsSection">
                    <div className="title">
                      <NumberFormatter
                        variant="number"
                        number={cardData.following}
                      />
                    </div>
                    <div className="subtitle">Following</div>
                  </div>
                  <div className="detailsSection">
                    <div className="title">
                      <NumberFormatter
                        title="Engagement Rate"
                        number={cardData.ratio}
                      />
                    </div>
                    <div className="subtitle">Engagement Rate</div>
                  </div>
                </div>
                <div className="footerBtnSection">
                  <div className="btn_wrapper full_width">
                    {cardData.post_link.length !== 0 ?
                      <a
                        href={cardData.post_link}
                        className="theme_button tertiary_btn"
                        target="_blank"
                      >
                        View Profile
                      </a>
                      :
                      <div
                        className="theme_button disabled_btn"
                      >
                        View Profile
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Wrapper>
          )
        }

        {
          variant === "video_thumbnail" && (
            <Wrapper>
              <img
                className="thumbnailImg"
                src={cardData.thumbnailUrl}
                alt=""
                onError={(e) => (e.target.src = DefaultPostImage)}
              />
              <div className="overlay">
                <div className="header">
                  {cardData.media_product_type === "REELS" && (
                    <div className="header_wrapper">
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          <TextFormatter string={cardData.media_product_type} />
                        </div>
                      </div>
                    </div>
                  )}
                  {cardData.video_status === 'private' &&
                    <span className="videoThumbnailCard-badge">
                      <PrivateIcon />
                      <span className="videoThumbnailCard-badge-text">Private</span>
                    </span>
                  }
                  {cardData.video_status === 'public' &&
                    <span className="videoThumbnailCard-badge">
                      <PublicIcon />
                      <span className="videoThumbnailCard-badge-text">Public</span>
                    </span>
                  }
                  {cardData.video_status === 'unlisted' &&
                    <span className="videoThumbnailCard-badge">
                      <UnlistedIcon />
                      <span className="videoThumbnailCard-badge-text">Unlisted</span>
                    </span>
                  }


                </div>
                <div className="overlayDetailsWrapper">
                  <div className="overlayDetails">
                    <div className="detailsWrapper">
                      {videoPlatformIcon && (
                        <div className="iconWrapper">
                          <img className="icon" src={videoPlatformIcon} />
                        </div>
                      )}
                      <div className="numberDetails">
                        <div className="title">
                          <NumberFormatter number={cardData.views} />
                        </div>
                        <div className="subtitle">Views</div>
                      </div>
                    </div>
                    <div className="videoLengthWrapper">
                      <div className="videoLength">
                        {typeof cardData.videoLength === "string" ? (
                          <span>{cardData.videoLength}</span>
                        ) : (
                          <NumberFormatter number={cardData.videoLength} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          )
        }

        {
          variant === "AdminBucketList" && (
            <Wrapper>
              <div className="card_header">
                <div className="card_title_wrapper">
                  <div className="card_header_title">
                    <TextFormatter string={camapaignBuckeData.name} />
                    {
                      camapaignBuckeData.description === undefined ||
                        camapaignBuckeData.description === null ||
                        camapaignBuckeData.description === '' ? "" :
                        <div className="hover_badge_wrapper section_badge  position-relative">
                          <Badge hoverDetails={camapaignBuckeData.description} />
                        </div>
                    }
                  </div>
                </div>
              </div>
              <div className="card_body">
                <div className="row campaign_count_wrapper">
                  <div className="title">{camapaignBuckeData.count === null ? "0" : camapaignBuckeData.count}</div>
                  <div className="subtitle">Total Campaigns</div>
                </div>
                <div className="row">
                  <div className="subtitle">Selected Date Range</div>
                  <div className="title">
                    {camapaignBuckeData.start_date} <span>to</span>{" "}
                    {camapaignBuckeData.end_date}
                  </div>
                </div>
                <div className="row">
                  <div className="subtitle">Selected Platforms</div>
                  <div className="title">
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) => (
                          platform.toLowerCase() === "google" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={GoogleIcon} />
                            </div>
                          )
                        )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "facebook" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={FacebookIcon} />
                            </div>
                          )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "instagram" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={InstagramIcon} />
                            </div>
                          )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "twitter" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={TwitterIcon} />
                            </div>
                          )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "linkedin" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={LinkedinIcon} />
                            </div>
                          )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "dv360" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={DV360Icon} />
                            </div>
                          )
                      )}
                    {
                      camapaignBuckeData.platform !== null &&
                      camapaignBuckeData.platform.map(
                        (platform, platformIndex) =>
                          platform.toLowerCase() === "bing" && (
                            <div key={platformIndex} className="icon_wrapper">
                              <img src={BINGIcon} />
                            </div>
                          )
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="subtitle">Selected Campaigns</div>
                  <div className="list">
                    <ol>
                      {camapaignBuckeData.items !== null &&
                        camapaignBuckeData.items.map(
                          (campaign, campaignIndex) => (
                            <li key={campaignIndex}>
                              <TextFormatter
                                string={campaign.platform_campaign_name}
                              />
                            </li>
                          )
                        )}
                    </ol>
                  </div>
                </div>
              </div>
              <div className="card_footer">
                {/* <div className="btn_wrapper"> */}
                <Button
                  handleClick={() => onDeleteButtonClick(camapaignBuckeData)}
                  className="secondary_danger_btn"
                >
                  Delete
                </Button>
                {/* </div> */}
                {/* <div className="btn_wrapper"> */}
                <Button
                  handleClick={() => onEditButtonClick(camapaignBuckeData)}
                  className="secondary_btn"
                >
                  Edit
                </Button>
                {/* </div> */}
              </div>
            </Wrapper>
          )
        }

        {
          variant === "video_thumbnail_loader" && (
            <Wrapper>
              <div className="card videoThumbnailCard">
                <img className="thumbnailImg" src={DefaultPostImage} alt="" />
              </div>
            </Wrapper>
          )
        }

        <div className="active_arrow"></div>
      </div >
    </Wrapper >
  );
}

Card.propTypes = {
  cardClass: PropTypes.string,
  variant: PropTypes.string,
  showPreviousCompare: PropTypes.bool,
  showSecondRowCompare: PropTypes.bool,
  cardTitle: PropTypes.string,
  cardIconLink: PropTypes.string,
  hoverDetails: PropTypes.string,
  currentValue: PropTypes.any,
  previousValue: PropTypes.any,
  rowOneTitle: PropTypes.string,
  second_currentValue: PropTypes.any,
  second_previousValue: PropTypes.any,
  rowTwoTitle: PropTypes.string,
  // forCountry: PropTypes.string,
  numberVariant: PropTypes.string,
  isReversed: PropTypes.bool,
  targetedValue: PropTypes.any,
  achievedValue: PropTypes.any,
  chartType: PropTypes.string,
  chartData: PropTypes.array,
  chartDataLabels: PropTypes.array,
  cardData: PropTypes.object,
  module: PropTypes.string,
  platform: PropTypes.string,
  section: PropTypes.string,

  camapaignBuckeData: PropTypes.object, // CAMPAIGN ADMIN BUCKET CARD
  onEditButtonClick: PropTypes.func, // CAMPAIGN ADMIN BUCKET CARD
  onDeleteButtonClick: PropTypes.func, // CAMPAIGN ADMIN BUCKET CARD
};

export default Card;
