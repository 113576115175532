import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import Wrapper from "../components/helpers/Wrapper";

export default function ForgotPasswordPage() {
    return (
        <Wrapper>
            <div className="grid grid_cols_4 grid_center">
                <div className="grid col_span_2 col_start_2 section_card max_width grid_center h_auto">
                <ForgotPasswordForm />
                </div>
            </div>
        </Wrapper>

    )
}