import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { USER_COUNTRY } from "../../../constants/constants";
import {
  CountryCode,
  CountryCurrency,
} from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";
import zoomarrows from "../../../assets/images/zoomarrows.jpg";
am5.addLicense("AM5C258896422");

const PartitionedCategoryCombinedBarLineMultipleAxesChart = ({
  chartId,
  chartClass,
  showLabels,
  showBullets,
  chartData,

  chartLabels,
  showSecondY,
  categoryFormat,
  disabledLegendArray,
  setDisabledValues,
  onBulletsClick,
  legendsLength
}) => {
  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let userCountryCode = userData?.country_code ?? "IN";

  let country = {};

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data;
    }
  });

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);


    let colorSet = [0x11a1fd, 0xff9931, 0x5a75f9, 0x07c180,];
    // TO SHOW BULLET IF THE DATA IS SINGLE
    if (chartData.length === 1) {
      showBullets = true;
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    if (!disabledLegendArray) {
      root.setThemes([am5themes_Animated.new(root)]);
    }

    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // root.dateFormatter.setAll({
    //     dateFormat: "yyyy-MM-dd",
    //     dateFields: ["valueX"]
    // });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // Data
    var data = chartData;



    var xRenderer = am5xy.AxisRendererX.new(root, {
      /*  minGridDistance: "auto",
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1, */
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",
        renderer: xRenderer,
        /*  tooltip: am5.Tooltip.new(root, {}) */
      })
    );
    xRenderer.grid.template.setAll({
      location: 1
    })

    xAxis.data.setAll(data);


    var yAxis1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(colorSet[1]),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
      })
    );
    yAxis1.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: am5.color(colorSet[1]),
      fontWeight: "400",
    });
    var yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(colorSet[2]),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
      })
    );
    yAxis2.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: am5.color(colorSet[2]),
      fontWeight: "400",
    });
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
        // minPosition: 0.05,
        // maxPosition: 0.05,
      });
    }
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(colorSet[0]),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
      })
    );
    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: am5.color(colorSet[0]),
      fontWeight: "400",
    });
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/


    if (chartLabels[0].linechartLabels[0] !== undefined) {
      var series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: chartLabels[0].linechartLabels[0].label,
          xAxis: xAxis,
          yAxis: yAxis1,
          valueYField: chartLabels[0].linechartLabels[0].value,
          categoryXField: "label",
          fill: am5.color(colorSet[1]),
          stroke: am5.color(colorSet[1]),
          clustered: false
        })
      );



      series2.data.setAll(data);

      series2.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.3,
            },
            {
              opacity: 0,
            },
          ],
          rotation: 90,
        })
      );

      series2.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });
      series2.strokes.template.setAll({
        strokeWidth: 2,
      });

      series2.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });


      yAxis.get("renderer").grid.template.set("visible", false);
      yAxis.axisHeader.set("paddingTop", 10);
      yAxis.axisHeader.children.push(
        am5.Label.new(root, {
          text: chartLabels[0].barchartLabels[0].label,
          fontWeight: "500",
          fontSize: "12",
        })
      );
      yAxis1.get("renderer").grid.template.set("visible", false);
      yAxis1.axisHeader.set("paddingTop", 10);
      yAxis1.axisHeader.children.push(
        am5.Label.new(root, {
          text: chartLabels[0].linechartLabels[0].label,
          fontWeight: "500",
          fontSize: "12",
        })
      );



      var tooltip = series2.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {

          var tooltipDataItem = series?.get("tooltipDataItem");
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
          }

        });
        return text;
      });
    }

    if (chartLabels[0].linechartLabels[1] !== undefined) {
      var series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: chartLabels[0].linechartLabels[1].label,
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: chartLabels[0].linechartLabels[1].value,
          categoryXField: "label",
          fill: am5.color(colorSet[2]),
          stroke: am5.color(colorSet[2]),
          clustered: false
        })
      );



      series3.data.setAll(data);




      series3.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.3,
            },
            {
              opacity: 0,
            },
          ],
          rotation: 90,
        })
      );

      series3.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });
      series3.strokes.template.setAll({
        strokeWidth: 2,
      });

      series3.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      yAxis2.get("renderer").grid.template.set("visible", false);
      yAxis2.axisHeader.set("paddingTop", 10);
      yAxis2.axisHeader.children.push(
        am5.Label.new(root, {
          text: chartLabels[0].linechartLabels[1].label,
          fontWeight: "500",
          fontSize: "12",
        })
      );



      var tooltip = series3.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {

          var tooltipDataItem = series?.get("tooltipDataItem");
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

          if (series.get("childLabel") != undefined) {
            text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
          }

        });
        return text;
      });


    }


    var series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: chartLabels[0].barchartLabels[0].label,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: chartLabels[0].barchartLabels[0].value,
        categoryXField: "label",
        fill: am5.color(colorSet[0]),
        stroke: am5.color(colorSet[0]),
        clustered: false
      })
    );

    series1.columns.template.setAll({
      width: am5.percent(30),
      cornerRadiusTL: 7,
      cornerRadiusTR: 7,
      fillOpacity: 0.5,
      strokeOpacity: 0,
    });

    series1.data.setAll(data);

    var tooltip = series1.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      // maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      text: "[bold]{categoryX}[/]",
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    tooltip.label.adapters.add("text", function (text, target) {
      chart.series.each(function (series) {

        var tooltipDataItem = series?.get("tooltipDataItem");
        text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

        if (series.get("childLabel") != undefined) {
          text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
        }

      });
      return text;
    });





    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Add legend
    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // width: am5.percent(100),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        x: am5.percent(50),
        centerX: am5.percent(50),
        // clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
        marginTop: 15,
        // marginBottom: 15,
        useDefaultMarker: true,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setup = function (marker) {
      var check = am5.Graphics.new(root, {
        width: 11,
        height: 11,
        layer: 50,
        fill: am5.color(0xffffff),
        draw: function (display) {
          display.drawRect(3.5, 4, 6, 6);
        },
      });

      check.states.create("disabled", {
        fillOpacity: 1,
      });

      marker.children.push(check);
    };

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 0,
      cornerRadiusTR: 0,
      cornerRadiusBL: 0,
      cornerRadiusBR: 0,
    });


    var globalName;
    legend.itemContainers.template.events.on("click", function (ev) {
      var temp = ev.target.dataItem.dataContext;

      chart.series.values.filter((i, index) => {
        if (temp.get("name") === i._settings.name) {
          var series = chart.series.getIndex(index);

          series.chart.yAxes.values.filter((x, y) => {
            if (index === y) {
              if (x.get("visible")) {
                setTimeout(() => {
                  x.set("visible", false);
                }, 1050);

                setDisabledValues((prev) => {
                  var tempArr = [...prev, temp.get("name")];
                  var fooBar = [...new Set(tempArr)];
                  return fooBar;
                });
              } else {
                setDisabledValues((prev) => {
                  var tempArrRmv = prev.filter((i) => i !== temp.get("name"));
                  return tempArrRmv;
                });
                x.set("visible", true);
              }
            }
          });
        }
      });
    });
    /*  legend.markerRectangles.template.setAll({
       cornerRadiusTL: 10,
       cornerRadiusTR: 10,
       cornerRadiusBL: 10,
       cornerRadiusBR: 10,
     });
   */
    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });
    legend.data.setAll(chart.series.values);
    // Move the first item to the last position

    chart.leftAxesContainer.set("layout", root.verticalLayout);



    chart.zoomOutButton.set("forceHidden", true);

    const scrollbar = am5.Scrollbar.new(root, {
      marginTop: 20,
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollbar);

    chart.bottomAxesContainer.children.push(scrollbar);

    const color = "#e1e1e1";

    // this is the filled bar
    scrollbar.thumb.setAll({
      fill: "#dfdada",

      opacity: 1,
    });

    scrollbar.get("background").setAll({
      fill: "#dddddd",
      opacity: 0.5,
    });

    scrollbar.startGrip.setAll({
      crisp: true,
      strokeWidth: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      visible: true,
      stroke: 0,
      icon: am5.Picture.new(root, {
        interactive: true,
        src: zoomarrows,
        cursorOverStyle: "pointer",
        width: 20,
        height: 20,
      }),
    });

    scrollbar.endGrip.setAll({
      dx: -10,
      crisp: true,
      strokeWidth: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      visible: true,
      stroke: 0,
      icon: am5.Picture.new(root, {
        interactive: true,
        src: zoomarrows,
        cursorOverStyle: "pointer",
        width: 20,
        height: 20,
      }),
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    if (
      disabledLegendArray !== undefined ||
      disabledLegendArray !== null ||
      disabledLegendArray.length !== 0
    ) {
      function toggle(item) {
        if (item === undefined || item === null) return;
        var storedItem = item;
        chart.series.values.filter((i, index) => {
          if (storedItem.includes(i._settings.name)) {
            var series = chart.series.getIndex(index);

            series.chart.yAxes.values.filter((x, y) => {
              if (index === y) {
                x.set("visible", false);
              }
            });

            toggleSeries(series);
          }
        });
      }

      function toggleSeries(series) {
        if (series.get("visible")) {
          series.hide();
        } else {
          series.show();
        }
      }

      setTimeout(() => {
        toggle(disabledLegendArray);
      }, 10);
    }

    const div = document.getElementById(chartId); // Replace chartId with your actual div's ID
    legendsLength = legend.data.length;

    if (legend.data.length - disabledLegendArray.length === 1) {
      div.style.height = "400px";
    } else if (legend.data.length - disabledLegendArray.length <= 2) {
      div.style.height = "400px";
    } else {
      const additionalHeight =
        (legend.data.length - disabledLegendArray.length - 2) * 200; // Calculate additional height

      div.style.height = `${250 + additionalHeight}px`;
      div.style.minHeight = "350px";
    }

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels, disabledLegendArray, legendsLength]);

  return (
    <Wrapper>
      <div
        id={chartId}
        className={chartClass}
        data-length={legendsLength}
      ></div>
    </Wrapper>
  );
};

PartitionedCategoryCombinedBarLineMultipleAxesChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showSecondY: PropTypes.bool,
  showBullets: PropTypes.bool,
  categoryFormat: PropTypes.bool,
  disabledLegendArray: PropTypes.array,
  onBulletsClick: PropTypes.func,
  legendsLength: PropTypes.any,
};

export default PartitionedCategoryCombinedBarLineMultipleAxesChart;
