import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";


const SocialFacebookAudience = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Audience"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const [isBeforeMarch, setIsBeforeMarch] = useState(false);

  useEffect(() => {
    const targetDate = new Date('2024/03/31');

    if ((targetDate < new Date(selectedDates.startDate)) && (targetDate < new Date(selectedDates.prevStartDate))) {
      setIsBeforeMarch(false)
    } else {
      setIsBeforeMarch(true)
    }
    

  }, [selectedDates])

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountAllDropdownsData, setUnknownCountAllDropdownsData] =
    useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [
    unknownCountAllDropdownsDataLabels,
    setUnknownCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [totalUnknownCountAllDropdownsData, setTotalUnknownCountAllDropdownsData] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  //  COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Reach", field: "reach" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Likes", field: "likes_users" },
    { name: "Change", field: "percentage_growth" },
  ];

  // AUDIENCE ACTIVITY
  const [audienceActivity, setAudienceActivity] = useState([]);
  const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
    useState([]);
  const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
    useState([]);
  const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
    useState(false);
  const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
    useState(false);
  const [audienceActivityerror, setAudienceActivityError] = useState(null);

  // PAGE GROWTH DROPDOWN
  // DROPDOWN OPTIONS
  let audienceDistributionOptions = []

  if (isBeforeMarch) {
    audienceDistributionOptions = [
      { value: "followers", label: "Followers" },
      { value: "ptat", label: "PTAT" },
    ];
  } else {
    audienceDistributionOptions = [
      { value: "followers", label: "Followers" },
    ];
  }

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    audienceDistributionOptions[0]
  );

  useEffect(() => {
    setSelectedDropdownOption(audienceDistributionOptions[0])
  }, [isBeforeMarch])
  

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Facebook", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const audienceDistributionMaleFemaleDropdownHandler = async () => {
    if (selectedDropdownOption.value === "ptat") {
      setMaleCount(maleCountAllDropdownsData?.ptat ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.ptat ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.ptat ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.ptat ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.ptat ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.ptat ?? []);
      setUnknownCount(unknownCountAllDropdownsData?.ptat ?? []);
      setUnknownCountLabels(unknownCountAllDropdownsDataLabels?.ptat ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.ptat ?? []);
    } else if (selectedDropdownOption.value === "followers") {
      setMaleCount(maleCountAllDropdownsData?.followers ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.followers ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.follower ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.followers ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.followers ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.follower ?? []);
      setUnknownCount(unknownCountAllDropdownsData?.followers ?? []);
      setUnknownCountLabels(unknownCountAllDropdownsDataLabels?.followers ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.follower ?? []);
    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    audienceDistributionMaleFemaleDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchAudienceActivityTrendHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/audience/audience-group/", config);

      setTotalMaleCount(res?.data?.data?.male_total?.followers ?? []);
      setTotalFemaleCount(res?.data?.data?.female_total?.followers ?? []);
      setTotalUnknownCount(res?.data?.data?.unknown_total?.followers ?? []);

      setMaleCount(res?.data?.data?.male?.followers ?? []);
      setMaleCountLabels(res?.data?.label?.followers ?? []);
      setFemaleCount(res?.data?.data?.female?.followers ?? []);
      setFemaleCountLabels(res?.data?.label?.followers ?? []);
      setUnknownCount(res?.data?.data?.unknown?.followers ?? []);
      setUnknownCountLabels(res?.data?.label?.followers ?? []);

      setMaleCountAllDropdownsData(res?.data?.data?.male ?? []);
      setMaleCountAllDropdownsDataLabels(res?.data?.label ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.data?.male_total ?? []);
      setFemaleCountAllDropdownsData(res?.data?.data?.female ?? []);
      setFemaleCountAllDropdownsDataLabels(res?.data?.label ?? []);

      setTotalFemaleCountAllDropdownsData(res?.data?.data?.female_total ?? []);
      setUnknownCountAllDropdownsData(res?.data?.data?.unknown ?? []);
      setUnknownCountAllDropdownsDataLabels(res?.data?.label ?? []);
      setTotalUnknownCountAllDropdownsData(res?.data?.data?.unknown_total ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);

      setUnknownCountAllDropdownsData([]);
      setUnknownCountAllDropdownsDataLabels([]);
      setTotalUnknownCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
  let maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }




    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader error={maleCounterror} />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }



    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_unknown"
              chartClass="section_card_chart"
              chartData={unknownCount}
              chartLabels={unknownCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalUnknownCountPercentage)) {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
    unknownAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-by-country/",
        config
      );

      setCountryData(res?.data ?? []);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country
              <SectionDefinationsBadge
                title="By Country"
                module="social"
                platform="facebook"
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country
                  <SectionDefinationsBadge
                    title="By Country"
                    module="social"
                    platform="facebook"
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isHalfSection={true}
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-by-cities/",
        config
      );

      setCityData(res?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City
              <SectionDefinationsBadge
                title="By City"
                module="social"
                platform="facebook"
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City
                  <SectionDefinationsBadge
                    title="By City"
                    module="social"
                    platform="facebook"
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isHalfSection={true}
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityIsLoading(true);
    setAudienceActivityIsLoaded(false);
    setAudienceActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-activity/",
        config
      );

      setAudienceActivity(res?.data?.social_activity_trend ?? []);
      setAudienceActivityXaxisLabels(res?.data?.hours_list ?? []);
      setAudienceActivityYaxisLabels(res?.data?.label_list_for_days ?? []);
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(true);
      setAudienceActivityError(null);
    } catch (error) {
      setAudienceActivity([]);
      setAudienceActivityXaxisLabels([]);
      setAudienceActivityYaxisLabels([]);
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(false);
      setAudienceActivityError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceActivityTrend = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
    if (
      NoDataAvailableChecker(audienceActivity) ||
      NoDataAvailableChecker(audienceActivityXaxisLabels) ||
      NoDataAvailableChecker(audienceActivityYaxisLabels)
    ) {
      audienceActivityTrend = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrend = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartId="social_facebook_audience_activity_trend"
              chartClass="section_card_chart"
              chartData={audienceActivity}
              chartXaxisLabels={audienceActivityXaxisLabels}
              chartYaxisLabels={audienceActivityYaxisLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityerror) {
    audienceActivityTrend = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityLoading) {
    audienceActivityTrend = (
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
    );
  }

  var cityCountryData = {
    "SocialFBCity_City": NoDataAvailableChecker(cityData) ? [] : cityData,
    "SocialFBCountry_Country": NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var maleFemaleDataCSV = {
    "Total Count": [{ [`Male - ${selectedDropdownOption.label}`]: totalMaleCount, [`Female - ${selectedDropdownOption.label}`]: totalFemaleCount }],
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedDropdownOption.label}`]: maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedDropdownOption.label}`]: femaleCount,
  };

  var allData = {
    ...topCardData,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Heatmap_Audience Activity Trend": audienceActivity,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    maleCount,
    femaleCount,
    totalFemaleCount,
    cityData,
    countryData,
    audienceActivity,
  ]);

  useEffect(() => {
    if (
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (audienceActivityerror !== null ? true : isAudienceActivityLoaded && (isAudienceActivityLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    audienceActivityerror, isAudienceActivityLoaded, isAudienceActivityLoading,
  ])


  return (
    <Wrapper>
      <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution: {selectedDropdownOption.label}
            </h2>  <SectionDefinationsBadge
                title={"Audience Distribution: " + selectedDropdownOption.label}
                module="social"
                platform="facebook"
                section="audience"
              /></div>

            <div data-html2canvas-ignore={true}>
              <Dropdown
                className={"form_dropdown section_dropdown"}
                placeholder={"Select"}
                isClearable={false}
                options={audienceDistributionOptions}
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_3">
          <div className="grid col_span_1 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 card_border_right flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Unknown</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {unknownAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {unknownAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab=""
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            {/* <div className="section_card_header">
              <h2 className="section_card_title">By Country
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
              </h2>
            </div> */}
            {/* <div className="grid grid_cols_1 section_card_body"> */}
            {audienceDistributionCountry}
            {/* </div> */}
          </div>
          <div className="grid col_span_2 flex column">
            {/* <div className="section_card_header">
              <h2 className="section_card_title">By City
                <SectionDefinationsBadge
                  title="By City"
                  module="social"
                  platform="facebook"
                  section="audience"
                />
             </div>
            </div>
            <div className="grid grid_cols_1 section_card_body"> */}
            {audienceDistributionCity}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div
        id="audience_activity_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">Audience Activity Trend
            </h2><SectionDefinationsBadge
                title="Audience Activity Trend"
                module="social"
                platform="facebook"
                section="audience"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab=""
                sectionId={"audience_activity_section"}
                sectionName={"Heatmap_Audience Activity Trend"}
                sectionData={audienceActivity}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrend}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialFacebookAudience;
