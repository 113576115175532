import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../constants/countryCurrency";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const StackedColumnChart = ({
	chartId,
	chartClass,
	showLegend,
	showLabels,
	chartData,
	chartLabels,
	showVerticalLabels,
	showShortVerticalLabels,
	tooltipMaxWidth
}) => {

	// USER DATA
	const userData = useSelector((state) => state.userData.user);
	let userCountryCode = userData?.country_code ?? "IN"

	let country = {}

	CountryCode.map((data) => {
		if (data.countryCode === userCountryCode) {
			country = data
		}
	})


	useLayoutEffect(() => {
		var root = am5.Root.new(chartId);

		// to format numbers
		root.numberFormatter.setAll({
			numberFormat: "#.##a",

			// Group only into M (millions), and B (billions)
			bigNumberPrefixes: [
				{ number: 1e3, suffix: "K" },
				{ number: 1e6, suffix: "M" },
				{ number: 1e9, suffix: "B" },
			],

			// Do not use small number prefixes at all
			smallNumberPrefixes: [],
		});

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([am5themes_Animated.new(root)]);
		root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		var chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				// wheelX: "panX",
				// wheelY: "zoomX",
				layout: root.verticalLayout, // to add
				paddingRight: 0,
				paddingTop: 5,
				paddingBottom: 0,
				paddingLeft: 0,
				maxTooltipDistance: -1,
			})
		);

		chart.get("colors").set("colors", [
			am5.color(0x11a1fd),
			am5.color(0xff9931),
			am5.color(0x5a75f9),
			am5.color(0x86a873),
			am5.color(0xbb9f06)
		]);

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		var cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "zoomXY",
			})
		);
		cursor.lineY.set("visible", false);
		cursor.lineX.set("visible", false);

		// The data
		var data = chartData;

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

		var xRenderer = am5xy.AxisRendererX.new(root, {
			minGridDistance: 30,
			stroke: am5.color(0x757575),
			strokeWidth: 0.3,
			strokeOpacity: 1
		});
		{
			showVerticalLabels &&
				xRenderer.labels.template.setAll({
					rotation: -90,
					centerY: am5.p50,
					centerX: am5.p100,
					paddingRight: 15
				});
		}

		{
			showShortVerticalLabels &&
				xRenderer.labels.template.setAll({
					rotation: -90,
					centerY: am5.p50,
					centerX: am5.p100,
					paddingRight: 15,
					maxWidth: 50,
					maxHeight: 50
				});
		}

		var xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: "label",
				renderer: xRenderer,
			})
		);

		// to change the date format from Feb 01 to 01/02
		// xAxis.get("dateFormats")["day"] = "dd/MM";

		// to show and hide x-axis labels
		if (showLabels === false) {
			xAxis.get("renderer").labels.template.setAll({
				visible: false,
			});
		} else {
			xAxis.get("renderer").labels.template.setAll({
				oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
				maxWidth: 150,
				textAlign: "center",
				fontSize: 12,
				fontFamily: "Ubuntu",
				fill: "#757575",
				fontWeight: "400",
			});
		}

		xAxis.data.setAll(data);

		var yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {
					stroke: am5.color(0x757575),
					strokeWidth: 0.3,
					strokeOpacity: 1
				}),
				min: 0,
				strictMinMax: true,

			})
		);

		// to show and hide y-axis labels
		if (showLabels === false) {
			yAxis.get("renderer").labels.template.setAll({
				visible: false,
			});
		} else {
			yAxis.get("renderer").labels.template.setAll({
				oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
				maxWidth: 150,
				textAlign: "center",
				fontSize: 12,
				fontFamily: "Ubuntu",
				fill: "#757575",
				fontWeight: "400",
			});
		}

		// Add legend
		var legend = chart.children.push(
			am5.Legend.new(root, {
				// background: am5.Rectangle.new(root, {
				//     fill: am5.color(0xff5599),
				//     fillOpacity: 0.2
				// }), // to add the backgrounD to the legends
				position: "relative", // options: "absolute" / "relative"
				// width: am5.percent(100),
				// height: am5.percent(20),
				x: am5.percent(50),
				centerX: am5.percent(50),
				height: chartLabels.length >= 5 ? am5.percent(30) : null,
				marginTop: 15,
				// marginBottom: 15,
				layout: root.horizontalLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
				layout: am5.GridLayout.new(root, {
					// maxColumns: 4,
					fixedWidthGrid: true,
				}),
				verticalScrollbar: am5.Scrollbar.new(root, {
					orientation: "vertical",
				}),
			})
		);

		legend.markers.template.setAll({
			width: 13,
			height: 13,
		});

		legend.markerRectangles.template.setAll({
			cornerRadiusTL: 10,
			cornerRadiusTR: 10,
			cornerRadiusBL: 10,
			cornerRadiusBR: 10,
		});

		legend.labels.template.setAll({
			fontSize: 12,
			fontWeight: "400",
			fill: "#213345",
		});

		legend.labels.template.setAll({
			// maxWidth: 150,
			//centerY: 0, // if we want labels to be top-aligned
			oversizedBehavior: "wrap",
		});

		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		function createSeries(name, field, childLabel, childValue, index) {
			var series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: name,
					stacked: true,
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: field,
					categoryXField: "label",
					childLabel: childLabel,
					childValue: childValue
				})
			);

			// for adding gradient to the line series
			// series.fills.template.set(
			//     "fillGradient",
			//     am5.LinearGradient.new(root, {
			//         stops: [
			//             {
			//                 opacity: 0.5,
			//             },
			//             {
			//                 opacity: 0.1,
			//             },
			//         ],
			//         rotation: 90,
			//     })
			// );
			// series.fills.template.setAll({
			//     visible: true,
			//     fillOpacity: 1,
			// });
			// series.strokes.template.setAll({
			//     strokeWidth: 2,
			// });

			// series.fills.template.setAll({
			//     fillOpacity: 0.5,
			//     visible: true,
			// });

			var tooltip = series.set(
				"tooltip",
				am5.Tooltip.new(root, {
					getFillFromSprite: false,
					getStrokeFromSprite: false,
					autoTextColor: false,
					pointerOrientation: "horizontal",
					position: "pointer",
				})
			);

			tooltip.get("background").setAll({
				fill: am5.color(0x213345),
				maxWidth: tooltipMaxWidth ? tooltipMaxWidth : 200,
				oversizedBehavior: "truncate",
				cornerRadius: 5,
			});

			tooltip.label.setAll({
				text: "[bold]{categoryX}[/]",
				fill: am5.color(0xffffff),
				fontSize: 13,
			});

			tooltip.label.adapters.add("text", function (text, target) {
				chart.series.each(function (series) {
					// text +=
					// 	"\n[" +
					// 	series.get("stroke").toString() +
					// 	"]●[/] [bold width:100px]" +
					// 	series.get("name") +
					// 	":[/] [bold width:100px]{" +
					// 	series.get("valueYField") +
					// 	"}[/]";
					// if (series.get("childLabel") != undefined) {
					// 	text +=
					// 		"\n[" +
					// 		series.get("stroke").toString() +
					// 		"]●[/] [bold width:100px]" +
					// 		series.get("childLabel") +
					// 		":[/] {" +
					// 		series.get("childValue") +
					// 		"}";
					// }

					var tooltipDataItem = series?.get("tooltipDataItem");
					text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

					if (series.get("childLabel") != undefined) {
						text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
					}

				});
				return text;
			});

			series.data.setAll(data);
			series.appear(1000);

			// TO SHOW TEXT ON BAR
			// series.bullets.push(function () {
			//     return am5.Bullet.new(root, {
			//         sprite: am5.Label.new(root, {
			//             text: "{valueY}",
			//             fill: root.interfaceColors.get("alternativeText"),
			//             centerY: am5.p50,
			//             centerX: am5.p50,
			//             populateText: true
			//         })
			//     });
			// });

			// Add legends
			if (showLegend === true) {
				legend.data.push(series);
			}
		}

		chartLabels.map((label, index) => {
			createSeries(
				label.label,
				label.value,
				label.childLabel,
				label.childValue,
				index
			);
		});

		// Add scrollbar
		// https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
		// chart.set("scrollbarX", am5.Scrollbar.new(root, {
		//     orientation: "horizontal"
		// }));

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.zoomOutButton.set("forceHidden", true);
		chart.appear(1000, 100);

		return () => {
			root.dispose();
		};
	}, [chartData, chartLabels]);

	return (
		<Wrapper>
			<div id={chartId} className={chartClass}></div>
		</Wrapper>
	);
};

StackedColumnChart.propTypes = {
	chartId: PropTypes.string,
	chartClass: PropTypes.string,
	showLegend: PropTypes.bool,
	showLabels: PropTypes.bool,
	showVerticalLabels: PropTypes.bool,
	showShortVerticalLabels: PropTypes.bool,
	chartData: PropTypes.array,
	chartLabels: PropTypes.array,
	tooltipMaxWidth: PropTypes.string,
};

export default StackedColumnChart;
